import React, { useRef, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import {
  Checkbox,
  Grid,
  ListItemText,
  OutlinedInput,
  Select,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { createGrayLabel } from "backendServices/ApiCalls";
import { routesForAuthenticatedOnly } from "../../../routes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import setCanvasPreview from "./setCanvasPreview";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const ASSPECT_RATIO = 1;
const MIN_DIMENSION = 150;

const CreateAdmin = () => {
  const navigate = useNavigate();
  const validationSchema = yup.object({
    firstname: yup
      .string()
      .min(3, "First Name must be at least 3 characters")
      .required("First Name is required"),
    lastname: yup
      .string()
      .min(3, "Last Name must be at least 3 characters")
      .required("Last Name is required"),
    company_name: yup
      .string()
      .min(4, "Company Name must be at least 4 characters")
      .required("Company Name is required"),
    username: yup
      .string()
      .min(4, "User Name must be at least 4 characters")
      .required("User Name is required"),
    userdomain: yup
      .string()
      .min(4, "Domain Name must be at least 4 characters")
      .required("Domain Name is required"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirm_password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup
      .string()
      .min(10, "Phone number must be at least 10 digits")
      .required("Phone is required"),
    // file: yup
    //   .mixed()
    //   .test("file", "File is required", (value) => value && value.length),
    admin_transaction_password: yup
      .string()
      .min(6, "Transaction Password must be at least 6 characters")
      .required("Transaction Password is required"),
    admin_transaction_confirm_password: yup
      .string()
      .min(6, "Transaction confirm Password must be at least 6 characters")
      .required("Transaction confirm Password is required"),
  });
  const { t } = useTranslation();
  const filteredRoutes = routesForAuthenticatedOnly.filter(
    (route) => route.path !== "/logout" && route.path !== "/404"
  );
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [routenames, setRoutenames] = useState(["/logout", "/404"]);
  const [grayUserDomain, setGrayUserDomain] = useState("");

  const [showModel, setShowModel] = useState({ modelFor: "" });
  const [image, setImage] = useState({ uploadFor: "", imgRes: null });
  const [imgError, setImgError] = useState(null);
  const [crop, setCrop] = useState({ imgLoad: "", cropped: null });
  const [logoAvtar, setLogoAvtar] = useState({ showImg: "", avatar: null });
  const [profileAvtar, setProfileAvtar] = useState({
    showImg: "",
    avatar: null,
  });
  const [favIconAvtar, setFavIconAvtar] = useState({
    showImg: "",
    avatar: null,
  });
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  let userSelectedFile = {};
  const avatarUrl = useRef(null);

  const updateAvatar = (imgSrc, logoFor) => {
    avatarUrl.current = imgSrc;
    if (logoFor === "company_logo") {
      setLogoAvtar({
        showImg: true,
        avatar: avatarUrl.current,
      });
    } else if (logoFor === "company_profile_logo") {
      setProfileAvtar({
        showImg: true,
        avatar: avatarUrl.current,
      });
    } else if (logoFor === "company_fav_icon") {
      setFavIconAvtar({
        showImg: true,
        avatar: avatarUrl.current,
      });
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRoutenames(
      // On autofill we get a stringified value.
      typeof value === "string" ? value?.split(",") : value
    );
  };

  const handleSubmit = (data, setSubmitting, resetForm) => {
    setSubmitting(true);

    if (routenames.length < 1) {
      setalertData({
        show: true,
        message: "User roles are required",
        variant: "warning",
      });
      setSubmitting(false);
    } else {
      // Read the selected file and convert the base64 file into file object
      const base64String = avatarUrl.current;
      const byteCharacters = atob(base64String.split(",")[1]);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });
      const file = new File([blob], "filename.jpg", { type: "image/jpeg" });
      // const file = document.getElementById("fileInput").files[0];
      var fileBase64 = "";
      const reader = new FileReader();

      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onloadend = async () => {
        fileBase64 = reader.result; // Assigning the base64 data to the global variable

        const routesJSONString = JSON.stringify(routenames);
        let params = {
          first_name: data.firstname,
          last_name: data.lastname,
          company_name: data.company_name,
          username: data.username,
          email: data.email,
          phone: data.phone,
          password: data.password,
          company_address: data.company_address,
          image: logoAvtar.avatar ? logoAvtar.avatar : "",
          profileImage: profileAvtar.avatar ? profileAvtar.avatar : "",
          favIconImage: favIconAvtar.avatar ? favIconAvtar.avatar : "",
          // mini_admin_transaction_password: data.mini_admin_transaction_password,
          admin_transaction_password: data.admin_transaction_password,
          allowedroutes: routesJSONString,
          website: data.userdomain,
          brand_color: data.favcolor,
        };

        createGrayLabel(
          params,
          (response) => {
            if (response?.data?.status === "error") {
              setalertData({
                show: true,
                message: response?.data?.message,
                variant: "error",
              });
            } else if (response?.data?.status === "success") {
              setalertData({
                show: true,
                message: response?.data?.message,
                variant: "success",
              });
              navigate("/gray-label");
              resetForm();
            } else {
              setalertData({
                show: true,
                message: "Something went wrong, please try again later.",
                variant: "error",
              });
              setRoutenames([["/logout", "/404"]]);
            }
            setSubmitting(false);
          },
          (error) => {
            setalertData({
              show: true,
              message: error?.response?.data?.message,
              variant: "error",
            });
            navigate("/gray-label");
            setSubmitting(false);
          }
        );
      };
    }
  };

  // gray user name domain creation code Akash
  const handleUserDomainChange = (value) => {
    setGrayUserDomain(value);
  };
  // gray code ends here

  const handleUpload = (e) => {
    let selectedUpload = e.target.getAttribute("modelFor");
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      userSelectedFile.file = e.target.files[0];

      if (selectedFile.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          let imageElement = new Image();
          imageElement.src = reader.result;

          imageElement.addEventListener("load", (e) => {
            if (imgError) setImgError(null);

            const { naturalWidth, naturalHeight } = e.currentTarget;

            if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
              setImgError(
                `Image must be at least ${MIN_DIMENSION} x ${MIN_DIMENSION} pixels.`
              );
              return setImage({
                uploadFor: "",
                imgRes: null,
              });
            }
          });

          if (selectedUpload === "company_logo") {
            setImage({
              uploadFor: "company_logo",
              imgRes: reader.result,
            });
          } else if (selectedUpload === "compny_profile_logo") {
            setImage({
              uploadFor: "compny_profile_logo",
              imgRes: reader.result,
            });
          } else if (selectedUpload === "compny_fav_icon") {
            setImage({
              uploadFor: "compny_fav_icon",
              imgRes: reader.result,
            });
          }
        };
        reader.readAsDataURL(selectedFile);
        // setImage(selectedFile);
      } else {
        setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
      }
    }
  };

  const handleModel = (e) => {
    let selectedLabel = e.target.getAttribute("modelFor");

    if (selectedLabel === "compny_logo") {
      setShowModel({
        modelFor: "compny_logo",
      });
    } else if (selectedLabel === "compny_profile_logo") {
      setShowModel({
        modelFor: "compny_profile_logo",
      });
    } else if (selectedLabel === "compny_fav_icon") {
      setShowModel({
        modelFor: "compny_fav_icon",
      });
    } else {
      setShowModel({
        modelFor: "",
      });
    }
  };

  const closeModel = () => {
    setShowModel({
      modelFor: "",
    });
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    let slectedLoadImg = e.target.getAttribute("imgFor");
    let cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASSPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);

    if (slectedLoadImg === "company_logo") {
      setCrop({
        imgLoad: "company_logo",
        cropped: centeredCrop,
      });
    } else if (slectedLoadImg === "company_profile_logo") {
      setCrop({
        imgLoad: "company_profile_logo",
        cropped: centeredCrop,
      });
    } else if (slectedLoadImg === "company_fav_icon") {
      setCrop({
        imgLoad: "company_fav_icon",
        cropped: centeredCrop,
      });
    }
  };

  return (
    <Grid
      container
      spacing={2}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <Grid item sm={10}>
        <JumboDemoCard
          title="Create New Gray Label User"
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          <Formik
            initialValues={{
              firstname: "",
              lastname: "",
              company_name: "",
              username: "",
              password: "",
              confirm_password: "",
              email: "",
              phone: "",
              company_address: "",
              // mini_admin_transaction_password: "",
              // mini_admin_transaction_confirm_password: "",
              admin_transaction_password: "",
              admin_transaction_confirm_password: "",
              favcolor: "",
              userdomain: "",
              // ... other initial values ...
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
              alert("121212");
              setSubmitting(true);
              handleSubmit(data, setSubmitting, resetForm);
            }}
          >
            {({ isSubmitting }) => (
              <Form
                style={{ textAlign: "left", width: "100%" }}
                noValidate
                autoComplete="off"
              >
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="firstname"
                        label="First Name"
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="lastname"
                        label="Last Name"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="company_name"
                        label="Company Name"
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="username"
                        label="User Name"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="userdomain"
                        label="Admin Domain"
                        type="text"
                        onChange={handleUserDomainChange}
                      />
                      {grayUserDomain.length > 3 && (
                        <span className="createDomain">
                          Domain: https://admin-{grayUserDomain}.novalya.com
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <JumboTextField
                        type="color"
                        name="favcolor"
                        label="Platform color"
                        defaultValue="#3FB337"
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="email"
                        label="Email"
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="phone"
                        label="Phone"
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="confirm_password"
                        label="Confirm Password"
                        type="password"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="admin_transaction_password"
                        label="Transaction Password"
                        type="password"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="admin_transaction_confirm_password"
                        label="Confirm Transaction Password"
                        type="password"
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid item xs={12}>
                    <JumboTextField
                      fullWidth
                      name="company_address"
                      label="Company Address"
                      type="text"
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Box>

                {/* ------------------- CODE FOR COMPANY LOGO ------------------- */}
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <div
                        className="userImgSelect"
                        modelFor="compny_logo"
                        onClick={handleModel}
                      >
                        <span className="chooseImg">Choose file</span>
                        <span>Company Logo</span>
                      </div>
                    </Grid>

                    {showModel.modelFor === "compny_logo" && (
                      <div className="myModelContainer">
                        <div className="modelBox">
                          <div className="inptField">
                            <div className="actionBtn">
                              <button className="closeBtn" onClick={closeModel}>
                                X
                              </button>
                            </div>
                            <h3>Select company logo</h3>
                            <input
                              accept="image/*"
                              id="fileInput"
                              className="image_inp"
                              type="file"
                              name="file"
                              modelFor="company_logo"
                              onChange={handleUpload}
                            />
                          </div>
                          {imgError && (
                            <p className="imgErrorMsg">{imgError}</p>
                          )}
                          {image.uploadFor === "company_logo" && (
                            <Grid item xs={10}>
                              <ReactCrop
                                crop={crop.cropped}
                                onChange={(pixelCrop, percentCrop) =>
                                  setCrop({
                                    imgLoad: "company_logo",
                                    cropped: percentCrop,
                                  })
                                }
                                circularCrop
                                keepSelection
                                aspect={ASSPECT_RATIO}
                                minWidth={MIN_DIMENSION}
                              >
                                <img
                                  ref={imgRef}
                                  src={image.imgRes}
                                  alt="Selected"
                                  imgFor="company_logo"
                                  style={{ maxHeight: "50vh" }}
                                  onLoad={onImageLoad}
                                />
                              </ReactCrop>
                            </Grid>
                          )}
                          {image.uploadFor === "company_logo" && (
                            <Grid item xs={10}>
                              <button
                                className="cropImageBtn"
                                onClick={() => {
                                  setCanvasPreview(
                                    imgRef.current, // HTMLImageElement
                                    previewCanvasRef.current, // HTMLCanvasElement
                                    convertToPixelCrop(
                                      crop.cropped,
                                      imgRef.current.width,
                                      imgRef.current.height
                                    )
                                  );
                                  const cropppedImgUrl =
                                    previewCanvasRef.current.toDataURL();
                                  updateAvatar(cropppedImgUrl, "company_logo");
                                  setShowModel({ modelFor: "" });
                                }}
                              >
                                Crop Image
                              </button>
                            </Grid>
                          )}
                          {crop.imgLoad === "company_logo" && (
                            <canvas
                              ref={previewCanvasRef}
                              className="imgCanvas"
                              style={{
                                display: "none",
                                border: "1px solid black",
                                objectFit: "contain",
                                width: 150,
                                height: 150,
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {logoAvtar.showImg && (
                      <Grid item xs={2}>
                        <img
                          src={logoAvtar.avatar}
                          alt="Selected"
                          className="userCompanyLogo"
                          style={{ maxWidth: "100%" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>

                {/* ------------------- CODE FOR COMPANY PROFILE LOGO ------------------- */}
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <div
                        className="userImgSelect"
                        modelFor="compny_profile_logo"
                        onClick={handleModel}
                      >
                        <span className="chooseImg">Choose file</span>
                        <span>Company Profile Logo</span>
                      </div>
                    </Grid>

                    {showModel.modelFor === "compny_profile_logo" && (
                      <div className="myModelContainer">
                        <div className="modelBox">
                          <div className="inptField">
                            <div className="actionBtn">
                              <button className="closeBtn" onClick={closeModel}>
                                X
                              </button>
                            </div>
                            <h3>Select profile image</h3>
                            <input
                              accept="image/*"
                              id="fileInputProfile"
                              type="file"
                              className="image_inp"
                              name="cmpnyProfileIcon"
                              modelFor="compny_profile_logo"
                              onChange={handleUpload}
                            />
                          </div>
                          {imgError && (
                            <p className="imgErrorMsg">{imgError}</p>
                          )}
                          {image.uploadFor === "compny_profile_logo" && (
                            <Grid item xs={10}>
                              <ReactCrop
                                crop={crop.cropped}
                                onChange={(pixelCrop, percentCrop) =>
                                  setCrop({
                                    imgLoad: "company_profile_logo",
                                    cropped: percentCrop,
                                  })
                                }
                                circularCrop
                                keepSelection
                                aspect={ASSPECT_RATIO}
                                minWidth={MIN_DIMENSION}
                              >
                                <img
                                  ref={imgRef}
                                  src={image.imgRes}
                                  alt="Selected"
                                  imgFor="company_profile_logo"
                                  style={{ maxHeight: "50vh" }}
                                  onLoad={onImageLoad}
                                />
                              </ReactCrop>
                            </Grid>
                          )}
                          {image.uploadFor === "compny_profile_logo" && (
                            <Grid item xs={10}>
                              <button
                                className="cropImageBtn"
                                onClick={() => {
                                  setCanvasPreview(
                                    imgRef.current, // HTMLImageElement
                                    previewCanvasRef.current, // HTMLCanvasElement
                                    convertToPixelCrop(
                                      crop.cropped,
                                      imgRef.current.width,
                                      imgRef.current.height
                                    )
                                  );
                                  const cropppedImgUrl =
                                    previewCanvasRef.current.toDataURL();
                                  updateAvatar(
                                    cropppedImgUrl,
                                    "company_profile_logo"
                                  );
                                  setShowModel(false);
                                }}
                              >
                                Crop Image
                              </button>
                            </Grid>
                          )}
                          {crop.imgLoad === "company_profile_logo" && (
                            <canvas
                              ref={previewCanvasRef}
                              className="imgCanvas"
                              style={{
                                display: "none",
                                border: "1px solid black",
                                objectFit: "contain",
                                width: 150,
                                height: 150,
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {profileAvtar.showImg && (
                      <Grid item xs={2}>
                        <img
                          src={profileAvtar.avatar}
                          alt="Selected"
                          className="userCompanyLogo"
                          style={{ maxWidth: "100%" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>

                {/* ------------------- CODE FOR COMPANY fav icon ------------------- */}
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <div
                        className="userImgSelect"
                        modelFor="compny_fav_icon"
                        onClick={handleModel}
                      >
                        <span className="chooseImg">Choose file</span>
                        <span>Company fav icon</span>
                      </div>
                    </Grid>

                    {showModel.modelFor === "compny_fav_icon" && (
                      <div className="myModelContainer">
                        <div className="modelBox">
                          <div className="inptField">
                            <div className="actionBtn">
                              <button className="closeBtn" onClick={closeModel}>
                                X
                              </button>
                            </div>
                            <h3>Select fav icon</h3>
                            <input
                              accept="image/*"
                              id="fileInputProfile"
                              type="file"
                              className="image_inp"
                              name="cmpnyFavIcon"
                              modelFor="compny_fav_icon"
                              onChange={handleUpload}
                            />
                          </div>
                          {imgError && (
                            <p className="imgErrorMsg">{imgError}</p>
                          )}
                          {image.uploadFor === "compny_fav_icon" && (
                            <Grid item xs={10}>
                              <ReactCrop
                                crop={crop.cropped}
                                onChange={(pixelCrop, percentCrop) =>
                                  setCrop({
                                    imgLoad: "company_fav_icon",
                                    cropped: percentCrop,
                                  })
                                }
                                circularCrop
                                keepSelection
                                aspect={ASSPECT_RATIO}
                                minWidth={MIN_DIMENSION}
                              >
                                <img
                                  ref={imgRef}
                                  src={image.imgRes}
                                  alt="Selected"
                                  imgFor="company_fav_icon"
                                  style={{ maxHeight: "50vh" }}
                                  onLoad={onImageLoad}
                                />
                              </ReactCrop>
                            </Grid>
                          )}
                          {image.uploadFor === "compny_fav_icon" && (
                            <Grid item xs={10}>
                              <button
                                className="cropImageBtn"
                                onClick={() => {
                                  setCanvasPreview(
                                    imgRef.current, // HTMLImageElement
                                    previewCanvasRef.current, // HTMLCanvasElement
                                    convertToPixelCrop(
                                      crop.cropped,
                                      imgRef.current.width,
                                      imgRef.current.height
                                    )
                                  );
                                  const cropppedImgUrl =
                                    previewCanvasRef.current.toDataURL();
                                  updateAvatar(
                                    cropppedImgUrl,
                                    "company_fav_icon"
                                  );
                                  setShowModel(false);
                                }}
                              >
                                Crop Image
                              </button>
                            </Grid>
                          )}
                          {crop.imgLoad === "company_fav_icon" && (
                            <canvas
                              ref={previewCanvasRef}
                              className="imgCanvas"
                              style={{
                                display: "none",
                                border: "1px solid black",
                                objectFit: "contain",
                                width: 150,
                                height: 150,
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {favIconAvtar.showImg && (
                      <Grid item xs={2}>
                        <img
                          src={favIconAvtar.avatar}
                          alt="Selected"
                          className="userCompanyLogo"
                          style={{ maxWidth: "100%" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>

                {/* <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid item xs={12}>
                    <JumboTextField
                      fullWidth
                      name="admin_transaction_password"
                      label="Admin Transaction Password"
                      type="password"
                    />
                  </Grid>
                </Box> */}

                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid item xs={12}>
                    <FormControl sx={{ mb: 1, width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        {t("pages.title.selectroles")}
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        fullWidth
                        value={routenames}
                        onChange={handleChange}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {filteredRoutes.map((routes) => (
                          <MenuItem key={routes.path} value={routes.path}>
                            <Checkbox
                              checked={routenames.indexOf(routes.path) > -1}
                            />
                            <ListItemText primary={routes.path} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    {t("pages.title.submit")}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default CreateAdmin;
