import React from "react";

import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import LogoutIcon from "@mui/icons-material/Logout";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PasswordIcon from "@mui/icons-material/Password";
import GroupIcon from "@mui/icons-material/Group";
import DiscountIcon from "@mui/icons-material/Discount";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PaymentsIcon from "@mui/icons-material/Payments";
import VideocamIcon from "@mui/icons-material/Videocam";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaidIcon from "@mui/icons-material/Paid";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import FeedIcon from "@mui/icons-material/Feed";
import SettingsIcon from "@mui/icons-material/Settings";
import ReportIcon from "@mui/icons-material/Report";
import DetailsIcon from "@mui/icons-material/Details";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import BalanceIcon from "@mui/icons-material/Balance";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PendingActionsIcon from '@mui/icons-material/PendingActions';

const menus = [
  {
    label: "sidebar.menu.home",
    type: "section",
    children: [
      {
        uri: "/dashboard",
        label: "sidebar.menuItem.dashboard",
        type: "nav-item",
        icon: <GraphicEqIcon sx={{ fontSize: 20 }} />,
      },
      {
        label: "sidebar.menuItem.miniadmin",
        type: "collapsible",
        icon: <FeedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/create-admin",
            label: "sidebar.menuItem.createadmin",
            type: "nav-item",
            icon: <AdminPanelSettingsIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/manage-admin",
            label: "sidebar.menuItem.manageadmin",
            type: "nav-item",
            icon: <AdminPanelSettingsIcon sx={{ fontSize: 20 }} />,
          },
        ],
      },
      {
        label: "sidebar.menuItem.logs",
        type: "collapsible",
        icon: <FeedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/admin-logs",
            label: "sidebar.menuItem.adminlogs",
            type: "nav-item",
            icon: <AdminPanelSettingsIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/user-logs",
            label: "sidebar.menuItem.userlogs",
            type: "nav-item",
            icon: <AdminPanelSettingsIcon sx={{ fontSize: 20 }} />,
          },
        ],
      },
      {
        label: "sidebar.menuItem.CommissionDetails",
        type: "collapsible",
        icon: <PendingActionsIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/current-month-pay",
            label: "sidebar.menuItem.CurrentMonthPay",
            type: "nav-item",
            icon: <PendingActionsIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/pending-pay",
            label: "sidebar.menuItem.PendingPay",
            type: "nav-item",
            icon: <PendingActionsIcon sx={{ fontSize: 20 }} />,
          }
        ],
      },
      {
        uri: "/plan-limits",
        label: "Plan Limits",
        type: "nav-item",
        icon: <GraphicEqIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/users-list",
        label: "sidebar.menuItem.users-list",
        type: "nav-item",
        icon: <GroupIcon sx={{ fontSize: 20 }} />,
      },
      // {
      //   uri: "/users-list-binary-details",
      //   label: "sidebar.menuItem.userbinarydetails",
      //   type: "nav-item",
      //   icon: <GroupIcon sx={{ fontSize: 20 }} />,
      // },
      {
        uri: "/trial-users",
        label: "sidebar.menuItem.trial-users",
        type: "nav-item",
        icon: <GroupIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/account-setting",
        label: "Account Setting",
        type: "nav-item",
        icon: <DetailsIcon sx={{ fontSize: 20 }} />,
      },
      {
        label: "Gray Label",
        type: "collapsible",
        icon: <FeedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/create-new-gray-label",
            label: "Create Gray Label",
            type: "nav-item",
            icon: <AdminPanelSettingsIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/gray-label",
            label: "Manage Gray Label",
            type: "nav-item",
            icon: <AdminPanelSettingsIcon sx={{ fontSize: 20 }} />,
          },
        ],
      },
      {
        label: "Commission",
        type: "collapsible",
        icon: <FeedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/pending-commission",
            label: "Pending",
            type: "nav-item",
            icon: <AdminPanelSettingsIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/unpaid-commission",
            label: "Unpaid",
            type: "nav-item",
            icon: <AdminPanelSettingsIcon sx={{ fontSize: 20 }} />,
          },
        ],
      },
      {
        label: "sidebar.menuItem.reports",
        type: "collapsible",
        icon: <ReportIcon sx={{ fontSize: 20 }} />,
        children: [
          // {
          //   uri: "/subscription-report",
          //   label: "sidebar.menuItem.subscription-report",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
          // {
          //   uri: "/affiliate-report",
          //   label: "sidebar.menuItem.affiliate-report",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },

          // {
          //   uri: "/pending-residuel-report",
          //   label: "sidebar.menuItem.pending-residuel-report",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
          {
            uri: "/pool-reports",
            label: "Pool Commission",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/unilevel-reports",
            label: "Level Commission",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/pool-distribution",
            label: "Pool Distribution",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
          // {
          //   uri: "/level-bonus-deducted",
          //   label: "sidebar.menuItem.levelbonusdeducted",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
          // {
          //   uri: "/rank-report",
          //   label: "sidebar.menuItem.rank-report",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
          // {
          //   uri: "/nova-rank-report",
          //   label: "sidebar.menuItem.nova-rank-report",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
        ],
      },
      {
        label: "sidebar.menuItem.payout",
        type: "collapsible",
        icon: <PaidIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/pending-payout",
            label: "sidebar.menuItem.pending-payout",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/approved-payout",
            label: "sidebar.menuItem.approved-payout",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/processing-payout",
            label: "sidebar.menuItem.processing_payout",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
        ],
      },
      {
        label: "sidebar.menuItem.settings",
        type: "collapsible",
        icon: <SettingsIcon sx={{ fontSize: 20 }} />,
        children: [
          // {
          //     uri: "/update-user-balance",
          //     label: 'sidebar.menuItem.update-user-balance',
          //     type: "nav-item",
          //     icon: <BalanceIcon sx={{ fontSize: 20 }} />
          // },
          // {
          //     uri: "/managetransactionpassword",
          //     label: 'sidebar.menuItem.managetransactionpassword',
          //     type: "nav-item",
          //     icon: <Diversity1Icon sx={{ fontSize: 20 }} />
          // },
          {
            uri: "/manage-payout",
            label: "sidebar.menuItem.manage-payout",
            type: "nav-item",
            icon: <AccountBalanceIcon sx={{ fontSize: 20 }} />,
          },
          // {
          //   uri: "/manage-commission",
          //   label: "sidebar.menuItem.manage-commission",
          //   type: "nav-item",
          //   icon: <MonetizationOnIcon sx={{ fontSize: 20 }} />,
          // },
          {
            uri: "/manage-zoom",
            label: "sidebar.menuItem.manage-zoom",
            type: "nav-item",
            icon: <VideocamIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/conversion-rate",
            label: "sidebar.menuItem.conversion-rate",
            type: "nav-item",
            icon: <AccountBalanceIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/unilevel",
            label: "sidebar.menuItem.manage-unilevel-bonus",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/manage-pool",
            label: "sidebar.menuItem.managepool",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
          // {
          //   uri: "/manage-unilevel-bonus",
          //   label: "sidebar.menuItem.manage-unilevel-bonus",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
          {
            uri: "/update-login-password",
            label: "sidebar.menuItem.update-login-password",
            type: "nav-item",
            icon: <PasswordIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/update-transaction-password",
            label: "sidebar.menuItem.update-transaction-password",
            type: "nav-item",
            icon: <PasswordIcon sx={{ fontSize: 20 }} />,
          },
          // {
          //     uri: "/update-user-password",
          //     label: 'sidebar.menuItem.update-user-password',
          //     type: "nav-item",
          //     icon: <PasswordIcon sx={{ fontSize: 20 }} />
          // },
        ],
      },
      {
        label: "sidebar.menuItem.kyc",
        type: "collapsible",
        icon: <SupportAgentIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/kyc-pending",
            label: "sidebar.menuItem.kyc-pending",
            type: "nav-item",
          },
          {
            uri: "/kyc-approved",
            label: "sidebar.menuItem.kyc-approved",
            type: "nav-item",
          },
          {
            uri: "/kyc-rejected",
            label: "sidebar.menuItem.kyc-rejected",
            type: "nav-item",
          },
        ],
      },

      // {
      //     uri: "/active-users",
      //     label: 'sidebar.menuItem.activeusers',
      //     type: "nav-item",
      //     icon: <GroupIcon sx={{ fontSize: 20 }} />
      // },
      // {
      //     uri: "/inactive-users",
      //     label: 'sidebar.menuItem.inactiveusers',
      //     type: "nav-item",
      //     icon: <GroupIcon sx={{ fontSize: 20 }} />
      // },
      // {
      //     label: 'sidebar.menuItem.payments',
      //     type: "collapsible",
      //     icon: <FeedIcon sx={{ fontSize: 20 }} />,
      //     children: [
      //         {
      //             uri: "/pending-manual-payments",
      //             label: 'sidebar.menuItem.pendingpayments',
      //             type: "nav-item",
      //             icon: <PaymentsIcon sx={{ fontSize: 20 }} />
      //         },
      //         {
      //             uri: "/approved-manual-payments",
      //             label: 'sidebar.menuItem.approvedpayments',
      //             type: "nav-item",
      //             icon: <PaymentsIcon sx={{ fontSize: 20 }} />
      //         },
      //         {
      //             uri: "/rejected-manual-payments",
      //             label: 'sidebar.menuItem.rejectedpayments',
      //             type: "nav-item",
      //             icon: <PaymentsIcon sx={{ fontSize: 20 }} />
      //         },
      //     ]
      // },

      // {
      //     uri: "/change-sponsor",
      //     label: 'sidebar.menuItem.changesponsor',
      //     type: "nav-item",
      //     icon: <ManageAccountsIcon sx={{ fontSize: 20 }} />
      // },
      // {
      //     label: 'sidebar.menuItem.summery',
      //     type: "collapsible",
      //     icon: <SummarizeIcon sx={{ fontSize: 20 }} />,
      //     children: [
      //         {
      //             uri: "/donation-summary",
      //             label: 'sidebar.menuItem.donationsummary',
      //             type: "nav-item",
      //             icon: <SummarizeIcon sx={{ fontSize: 20 }} />
      //         },
      //         {
      //             uri: "/payout-summary",
      //             label: 'sidebar.menuItem.payoutsummary',
      //             type: "nav-item",
      //             icon: <SummarizeIcon sx={{ fontSize: 20 }} />
      //         },
      //     ]
      // },

      //
      // {
      //   label: "sidebar.menuItem.news",
      //   type: "collapsible",
      //   icon: <NewspaperIcon sx={{ fontSize: 20 }} />,
      //   children: [
      //     {
      //       uri: "/add-news",
      //       label: "sidebar.menuItem.add-news",
      //       type: "nav-item",
      //       icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
      //     },
      //     {
      //       uri: "/manage-news",
      //       label: "sidebar.menuItem.manage-news",
      //       type: "nav-item",
      //       icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
      //     },
      //   ],
      // },

      {
        label: "sidebar.menuItem.payoutdetail",
        type: "collapsible",
        icon: <DetailsIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/payout-detail-pending",
            label: "sidebar.menuItem.payout-detail-pending",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/payout-detail-approved",
            label: "sidebar.menuItem.payout-detail-approved",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/payout-detail-rejected",
            label: "sidebar.menuItem.payout-detail-rejected",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
        ],
      },

      // {
      //     uri: "/listofAlluser",
      //     label: 'sidebar.menuItem.listofAlluser',
      //     type: "nav-item",
      //     icon: <Diversity1Icon sx={{ fontSize: 20 }} />
      // },
      // {
      //     uri: "/profile",
      //     label: 'sidebar.menuItem.updatepassword',
      //     type: "nav-item",
      //     icon: <PasswordIcon sx={{ fontSize: 20 }} />
      // },

      // {
      //     uri: "/updateuserlist",
      //     label: 'sidebar.menuItem.updateuserlist',
      //     type: "nav-item",
      //     icon: <Diversity1Icon sx={{fontSize: 20}}/>
      // },

      // {
      //     uri: "/payment-method",
      //     label: 'sidebar.menuItem.updatepayment',
      //     type: "nav-item",
      //     icon: <AccountBalanceIcon sx={{fontSize: 20}}/>
      // },
      // {
      //     label: 'sidebar.menuItem.auto-coupon',
      //     type: "collapsible",
      //     icon: <DiscountIcon sx={{ fontSize: 20 }} />,
      //     children: [
      // {
      //   uri: "/add-coupon",
      //   label: "sidebar.menuItem.add-coupon",
      //   type: "nav-item",
      //   icon: <DiscountIcon sx={{ fontSize: 20 }} />,
      // },
      //     {
      //         uri: "/manage",
      //         label: 'sidebar.menuItem.manage',
      //         type: "nav-item",
      //         icon: <DiscountIcon sx={{ fontSize: 20 }} />
      //     },
      // ]
      // },

      {
        uri: "/logout",
        label: "sidebar.menuItem.logout",
        type: "nav-item",
        icon: <LogoutIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
