import React, { useEffect } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Autocomplete, Box, CircularProgress, Grid, List, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { getlimitsdata, updatelimitsdata } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  plan1_fb_messages: yup.number().required("Plan1 Facebook Messages is required"),
  plan1_insta_messages:yup.number().required("Plan1 Instagram Messages is required"),
  plan1_AI_credits:yup.number().required("Plan1 AI Credits is required"),
  plan1_Tags_pipelines:yup.number().required("Plan1 Tags/Pipelines is required"),

  plan2_fb_messages: yup.number().required("Plan2 Facebook Messages is required"),
  plan2_insta_messages:yup.number().required("Plan2 Instagram Messages is required"),
  plan2_AI_credits:yup.number().required("Plan2 AI Credits is required"),
  plan2_Tags_pipelines:yup.number().required("Plan2 Tags/Pipelines is required"),

  plan3_fb_messages: yup.number().required("Plan3 Facebook Messages is required"),
  plan3_insta_messages:yup.number().required("Plan3 Instagram Messages is required"),
  plan3_AI_credits:yup.number().required("Plan3 AI Credits is required"),
  plan3_Tags_pipelines:yup.number().required("Plan3 Tags/Pipelines is required"),
})


const PlanLimits = () => {
  const { t } = useTranslation();
  const [limitsData, setLimitsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [selectedOption, setSelectedOption] = useState({});

  const option = [
    { title: 'FaceBook' },
    { title: 'Instagram' }
  ]

  const fetchLimits = () => {
    setIsLoading(true);
    getlimitsdata(
      (response) => {
        setLimitsData(response?.data?.data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchLimits();
  }, []);

  const handleSubmit = (data, setSubmitting) => {
    let obj
   
      obj = {
        plan1_id: "Basic",
        plan1_fb_messages: data.plan1_fb_messages,
        plan1_insta_messages:data.plan1_insta_messages,
        plan1_AI_credits:data.plan1_AI_credits,
        plan1_Tags_pipelines:data.plan1_Tags_pipelines,

     
        plan2_id: "Business",
        plan2_fb_messages: data.plan2_fb_messages,
        plan2_insta_messages:data.plan2_insta_messages,
        plan2_AI_credits:data.plan2_AI_credits,
        plan2_Tags_pipelines:data.plan2_Tags_pipelines,
       
  
        plan3_id: "Unlimited_new",
        plan3_fb_messages: data.plan3_fb_messages,
        plan3_insta_messages:data.plan3_insta_messages,
        plan3_AI_credits:data.plan3_AI_credits,
        plan3_Tags_pipelines:data.plan3_Tags_pipelines,
       
    }


    updatelimitsdata(
      obj,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: "Something went wrong please try again later.",
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
        }
      },
      (error) => {
      }
    );
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={"Manage Plan Limits"} noWrapper>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}

          {console.log(limitsData)}
         
          {
            <List disablePadding sx={{ mb: 2 }}>
              {
               
                <Formik
                enableReinitialize
                  validateOnChange={true}
                  initialValues={{
                    plan1_fb_messages:limitsData[3]?.fb_messages || 0,
                    plan1_insta_messages:limitsData[3]?.insta_messages || 0,
                    plan1_AI_credits:limitsData[3]?.ai_credits_new || 0,
                    plan1_Tags_pipelines:limitsData[3]?.tags_pipelines || 0,

                    plan2_fb_messages:limitsData[4]?.fb_messages || 0,
                    plan2_insta_messages:limitsData[4]?.insta_messages || 0,
                    plan2_AI_credits:limitsData[4]?.ai_credits_new || 0,
                    plan2_Tags_pipelines:limitsData[4]?.tags_pipelines || 0,

                    plan3_fb_messages:limitsData[5]?.fb_messages || 0,
                    plan3_insta_messages:limitsData[5]?.insta_messages || 0,
                    plan3_AI_credits:limitsData[5]?.ai_credits_new || 0,
                    plan3_Tags_pipelines:limitsData[5]?.tags_pipelines || 0

                 
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(data, { setSubmitting }) => {
                    setSubmitting(true);
                    handleSubmit(data, setSubmitting);
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form
                      style={{ textAlign: "left" }}
                      noValidate
                      autoComplete="off"
                    >
                      <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="p" sx={{ fontWeight: "bold" }}>
                              Starter Limits
                            </Typography>
                          </Grid>

                          

                          <Grid item xs={6}>
                            <JumboTextField
                              fullWidth
                              name="plan1_fb_messages"
                              label="Facebook Messages"
                              type="number"
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <JumboTextField
                              fullWidth
                              name="plan1_insta_messages"
                              label="Instagram Messages"
                              type="number"
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <JumboTextField
                              fullWidth
                              name="plan1_AI_credits"
                              label="AI Credits"
                              type="number"
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <JumboTextField
                              fullWidth
                              name="plan1_Tags_pipelines"
                              label="Tags / Pipelines"
                              type="number"
                            />
                          </Grid>
      
                        </Grid>
                      </Box>

                      <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="p" sx={{ fontWeight: "bold" }}>
                            Business Limits
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <JumboTextField
                              fullWidth
                              name="plan2_fb_messages"
                              label="Facebook Messages"
                              type="number"
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <JumboTextField
                              fullWidth
                              name="plan2_insta_messages"
                              label="Instagram Messages"
                              type="number"
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <JumboTextField
                              fullWidth
                              name="plan2_AI_credits"
                              label="AI Credits"
                              type="number"
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <JumboTextField
                              fullWidth
                              name="plan2_Tags_pipelines"
                              label="Tags / Pipelines"
                              type="number"
                            />
                          </Grid>
                    
                        </Grid>
                      </Box>

                      <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="p" sx={{ fontWeight: "bold" }}>
                              Unlimited Limits
                            </Typography>
                          </Grid>

                         

                          <Grid item xs={6}>
                            <JumboTextField
                              fullWidth
                              name="plan3_fb_messages"
                              label="Facebook Messages"
                              type="number"
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <JumboTextField
                              fullWidth
                              name="plan3_insta_messages"
                              label="Instagram Messages"
                              type="number"
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <JumboTextField
                              fullWidth
                              name="plan3_AI_credits"
                              label="AI Credits"
                              type="number"
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <JumboTextField
                              fullWidth
                              name="plan3_Tags_pipelines"
                              label="Tags / Pipelines"
                              type="number"
                            />
                          </Grid>

                        </Grid>
                      </Box>

                      <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ mb: 3 }}
                          loading={isSubmitting}
                        >
                          {t("pages.title.submit")}
                        </LoadingButton>
                      </Div>
                    </Form>
                  )}
                </Formik>
              }
            
            </List>
          }
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default PlanLimits;
