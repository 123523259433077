import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  ApproveAllPayoutApi,
  ApproveSinglePayoutApi,
  PendingPayoutApi,
} from "backendServices/ApiCalls";
import { Button, Grid, IconButton, TableCell } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Div from "@jumbo/shared/Div/Div";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { getAllProcessingPayout } from "backendServices/ApiCalls";

const Processingpayout = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [pendingPayout, setPendingPayout] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [feeData, setFeeData] = useState();
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const PendingPayoutapidata = () => {
    getAllProcessingPayout(
      (response) => {
        const processedData = response?.data?.data?.map((row, index) => ({
          ...row,
          index: index + 1,
        }));
        setPendingPayout(processedData);
        setFeeData(response?.data?.feeData);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    PendingPayoutapidata();
  }, []);
  const rowsWithIndex = pendingPayout?.map((row) => ({ ...row, id: row.id }));

  const handleApproved = (id) => {
    let params = { userid: id };
    ApproveSinglePayoutApi(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setPendingPayout((data) => data.filter((row) => row.id !== id));
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => {}
    );
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
      // valueGetter: (params) => params.row.index + 1,
    },

    {
      field: "username",
      headerName: "User Name",
      width: 200,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 200,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 200,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    // {
    //     field: "current_balance",
    //     headerName: "Amount",
    //     width: 200,
    //     editable: true,
    //     groupable: false,
    //     valueGetter: (params) => {
    //         const { value } = params;
    //         return value ? `€${params.value}` : "N/A";
    //     },
    // },
    {
      field: "current_balance",
      headerName: "Amount",
      width: 200,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const payoutEURBalance = params.row.current_balance_eur_payout;
        const payoutUSDBalance = params.row.current_balance_usd_payout;
        const walletAddress = params.row.wallet_address;
        const bankAccountTitle = params.row.bank_account_title;
        const outsideBankAccountTitle = params.row.outside_bank_account_title;
        let eurAmount =
          parseFloat(payoutEURBalance) +
          parseFloat(payoutUSDBalance * feeData?.conversion_usd_to_eur);
        let usdAmount =
          parseFloat(payoutUSDBalance) +
          parseFloat(payoutEURBalance * feeData?.conversion_eur_to_usd);
        let amount = 0;
        if (walletAddress !== null) {
          amount = usdAmount;
          return `$${amount.toFixed(2)}`;
        } else if (walletAddress === null && bankAccountTitle !== null) {
          amount = eurAmount;
          return `€${amount.toFixed(2)}`;
        } else if (
          walletAddress === null &&
          bankAccountTitle === null &&
          outsideBankAccountTitle !== null
        ) {
          amount = usdAmount;
          return `$${amount.toFixed(2)}`;
        }

        return "N/A";
      },
    },
    {
      field: "final_amount",
      headerName: "Final Amount",
      width: 200,
      editable: true,
      groupable: false,

      valueGetter: (params) => {
        const payoutEURBalance = params.row.current_balance_eur_payout;
        const payoutUSDBalance = params.row.current_balance_usd_payout;
        const walletAddress = params.row.wallet_address;
        const bankAccountTitle = params.row.bank_account_title;
        const outsideBankAccountTitle = params.row.outside_bank_account_title;
        let eurAmount =
          parseFloat(payoutEURBalance) +
          parseFloat(payoutUSDBalance * feeData?.conversion_usd_to_eur);
        let usdAmount =
          parseFloat(payoutUSDBalance) +
          parseFloat(payoutEURBalance * feeData?.conversion_eur_to_usd);
        let amount = 0;
        let currency = "";
        let method = "";
        if (walletAddress !== null) {
          amount = usdAmount;
          currency = "$";
          method = "wallet";
        } else if (walletAddress === null && bankAccountTitle !== null) {
          amount = eurAmount;
          currency = "€";
          method = "eur";
        } else if (
          walletAddress === null &&
          bankAccountTitle === null &&
          outsideBankAccountTitle !== null
        ) {
          amount = usdAmount;
          currency = "$";
          method = "usd";
        }

        if (method === "wallet") {
          const finalAmount = amount - (amount * feeData?.percentage) / 100;
          return `${currency}${finalAmount.toFixed(2)}`;
        } else if (method === "usd") {
          return `${currency}${(amount - feeData?.flat_fee).toFixed(2)}`;
        } else if (method === "eur") {
          return `${currency}${(amount - feeData?.flat_fee).toFixed(2)}`;
        } else {
          return "N/A";
        }
      },
    },
    {
      field: "bank_account_title",
      headerName: "Full Name",
      width: 200,
      editable: true,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "bank_account_bic",
      headerName: "Bank Account BIC",
      width: 200,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value.toUpperCase() : "N/A";
      },
    },
    {
      field: "bank_account_country",
      headerName: "Bank Account Country",
      width: 215,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "bank_account_iban",
      headerName: "Bank Account IBAN",
      width: 250,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value.toUpperCase() : "N/A";
      },
    },
    {
      field: "bank_account_address",
      headerName: "Personal Address",
      width: 215,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "bank_account_city",
      headerName: "City",
      width: 215,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "bank_account_zip_code",
      headerName: "Zip Code",
      width: 215,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "payout_country",
      headerName: "Country",
      width: 215,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "outside_bank_account_title",
      headerName: "Full Name (Outside UE)",
      width: 250,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "outside_bank_account_country",
      headerName: "Bank Account Country (Outside UE)",
      width: 250,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "outside_bank_account_number",
      headerName: "Bank Account Number (Outside UE)",
      width: 250,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "outside_bank_account_swift_code",
      headerName: "Swift Code (Outside UE)",
      width: 250,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "outside_bank_account_routing",
      headerName: "Routing Number (Outside UE)",
      width: 250,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "outside_bank_account_address",
      headerName: "Personal Address (Outside UE)",
      width: 250,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "outside_bank_account_city",
      headerName: "City (Outside UE)",
      width: 250,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "outside_bank_account_zip_code",
      headerName: "Zip Code (Outside UE)",
      width: 250,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "outside_payout_country",
      headerName: "Country (Outside UE)",
      width: 250,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "wallet_address",
      headerName: "Wallet Address",
      width: 250,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "approved",
      headerName: "Approved",
      width: 200,
      editable: true,
      groupable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleApproved(params.row.id)}
        >
          Approved
        </Button>
      ),
    },
  ];

  // const gridDesign = {
  //     '& .MuiDataGrid-toolbarContainer': {
  //         '& .MuiButton-text': {
  //             fontSize: '13px !important',
  //             color: '#000',
  //         },
  //         '& .MuiBadge-badge': {
  //             backgroundColor: '#074682',
  //         },
  //         '& .MuiInput-root': {
  //             borderRadius: 2,
  //             paddingLeft: 2,
  //             overflow: 'hidden',
  //         },

  //     }
  // }
  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  const handleClickSendToAll = () => {
    ApproveAllPayoutApi(
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setOpen(false);
          PendingPayoutapidata();
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => {}
    );
  };
  const Swal = useSwalWrapper();
  const sweetAlerts = () => {
    Swal.fire({
      title: "Alert!",
      text: "approved are succesfully send to all.",
    });
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={12} xs={12}>
        <Div sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" onClick={() => setOpen(true)}>
            Send To All
          </Button>

          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{ color: "green", display: "flex", justifyContent: "center" }}
            >
              {"Alert!"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure to approve all payouts?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ marginRight: "5rem" }}
                onClick={handleClickSendToAll}
              >
                Yes
              </Button>
              <Button onClick={() => setOpen(false)} autoFocus>
                {t("pages.title.cancel")}{" "}
              </Button>
            </DialogActions>
          </Dialog>

          {/* <Button variant="contained" endIcon={<SendIcon />} onClick={handleClickSendToAll} >
                    Send To All
                </Button> */}
        </Div>

        <JumboDemoCard
          title={t("pages.title.pendingpayout")}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}

          <Box sx={{ height: 500, width: 1 }}>
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={rowsWithIndex}
              getRowId={(row) => row.id}
              columns={columns}
              // slots={{ toolbar: GridToolbar }}
              slots={{
                toolbar: (props) => (
                  <GridToolbar
                    {...props}
                    sx={{
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#424242" : "#fff",
                      "& .MuiIconButton-root": {
                        color: theme.palette.mode === "dark" ? "#fff" : "#000",
                      },
                    }}
                  />
                ),
              }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default Processingpayout;
