import React, { useEffect } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Box,
  CircularProgress,
  Grid,
  List,
} from "@mui/material";
import { useState } from "react";
import {
  singleUserApi,
  updateUserLevelCommissionApi,
} from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const validationSchema = yup.object({
  level1_commission_per: yup
    .number()
    .required("Level 1 Commission is required"),
  level2_commission_per: yup
    .number()
    .required("Level 2 Commission is required"),
  level_commission_individual_status: yup
    .string()
    .required("Individual Status is required"),
});

const UpdateUserunilevel = () => {
  const { t } = useTranslation();
  const [singleUser, setSingleUser] = useState({});
  const [updateRecord, setUpdateRecord] = useState(false);
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const user_id = location?.state?.user_id || "";
  const getSingleUserFunction = () => {
    let params = {
      userid: user_id,
    };

    setIsLoading(true);
    singleUserApi(params, (response) => {
      setSingleUser(response?.data?.data[0]);
      setIsLoading(false);
    });
  };

  const handleSubmit = (data, setSubmitting) => {
    data.userid = user_id;
    updateUserLevelCommissionApi(
      data,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setUpdateRecord(false);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setUpdateRecord(true);
          setSubmitting(false);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong. Please try again leter",
            variant: "error",
          });
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    getSingleUserFunction();
  }, [updateRecord]);

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={"Manage User Level Commission"} noWrapper>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {isLoading ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%",
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
            <List disablePadding sx={{ mb: 2 }}>
              <Formik
                initialValues={{
                  username: singleUser?.username,
                  level1_commission_per: singleUser?.level1_commission_per,
                  level2_commission_per: singleUser?.level2_commission_per,
                  level_commission_individual_status:
                    singleUser?.level_commission_individual_status,
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                  setSubmitting(true);
                  handleSubmit(data, setSubmitting);
                }}
              >
                {({ isSubmitting, values, setFieldValue }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <JumboTextField
                            fullWidth
                            name="username"
                            label="Username"
                            type="text"
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <JumboTextField
                            fullWidth
                            name="level1_commission_per"
                            label="Level1 Commission Percentage"
                            type="text"
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <JumboTextField
                            fullWidth
                            name="level2_commission_per"
                            label="Level2 Commission Percentage"
                            type="text"
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography sx={{ pt: 1, pl: 1 }}>
                            {"Individual Status"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  values.level_commission_individual_status ===
                                  "On"
                                } // Check if referral_commission_status is 'On'
                                onChange={(e) =>
                                  setFieldValue(
                                    "level_commission_individual_status",
                                    e.target.checked ? "On" : "Off"
                                  )
                                } // Set 'On' or 'Off' based on the switch state
                                name="level_commission_individual_status"
                                color="primary"
                                value={
                                  values.level_commission_individual_status
                                } // Set value as 'On' or 'Off'
                              />
                            }
                            label={values.level_commission_individual_status} // Display 'On' or 'Off' based on the switch state
                          />
                        </Grid>
                      </Grid>
                    </Div>

                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        {t("pages.title.submit")}
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          )}
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default UpdateUserunilevel;
