import React, { useEffect } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Box,
  CircularProgress,
  Grid,
  List,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { addDeductPoints } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const validationSchema = yup.object({
  username: yup.mixed().required("User Name is required"),
  binary_points_type: yup.string().required("User type is required"),
  type: yup.string().required("Points type is required"),
  reason: yup.string().required("Reason is required"),
  enter_points: yup.number().required("Points are required"),
  admin_transaction_password: yup
    .string()
    .required("Transaction Password is required"),
});

const Adddeductbinarypoints = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const user_id = location.state?.id || "";
  const username = location.state?.username || "";

  const handleSubmit = (data, setSubmitting, resetForm) => {
    let params = {
      userid: user_id,
      points: data.enter_points,
      type: data.type,
      usertype: data.binary_points_type,
      reason: data.reason,
      admin_transaction_password: data.admin_transaction_password,
    };
    addDeductPoints(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
          resetForm();
        }
      },
      (error) => {}
    );
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={8} xs={12}>
        <JumboCardQuick
          title={t("pages.title.addDeduct_binary_points")}
          noWrapper
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {isLoading ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%",
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
            <List disablePadding sx={{ mb: 2 }}>
              <Formik
                validateOnChange={true}
                initialValues={{
                  username: username,
                  binary_points_type: "",
                  type: "",
                  enter_points: "",
                  reason: "",
                  admin_transaction_password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  handleSubmit(data, setSubmitting, resetForm);
                }}
              >
                {({ isSubmitting }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="username"
                        label="User Name"
                        type="text"
                        InputProps={{
                          readOnly: true,
                        }}
                      ></JumboTextField>
                    </Div>

                    <Grid container sm={12} xs={12} sx={{ mt: 2 }}>
                      <Grid item sm={6} xs={12}>
                        <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                          <JumboTextField
                            fullWidth
                            name="binary_points_type"
                            label="User Type"
                            select
                          >
                            <MenuItem value="team">
                              {t("pages.title.team")}
                            </MenuItem>
                            <MenuItem value="sponsor">
                              {t("pages.title.sponsor")}
                            </MenuItem>
                          </JumboTextField>
                        </Box>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                          <JumboTextField
                            fullWidth
                            name="type"
                            label="Points Type"
                            select
                          >
                            <MenuItem value="add">
                              {t("pages.title.add")}
                            </MenuItem>
                            <MenuItem value="deduct">
                              {t("pages.title.deduct")}
                            </MenuItem>
                          </JumboTextField>
                        </Box>
                      </Grid>
                    </Grid>
                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="reason"
                        label="Enter Reason"
                        type="text"
                      />
                    </Div>
                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="enter_points"
                        label="Enter Points"
                        type="number"
                      />
                    </Div>
                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="admin_transaction_password"
                        label="Transaction Password"
                        type="password"
                      />
                    </Div>

                    <Div sx={{ mt: 3, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        {t("pages.title.update")}
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          )}
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default Adddeductbinarypoints;
