import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { deletenews, getnews } from "backendServices/ApiCalls";
import { Grid } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Managenews = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [manageallnews, setManageAllNews] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const GetallNews = () => {
    getnews(
      (response) => {
        const processedData = response?.data?.data.map((row, index) => ({
          ...row,
          index: index + 1,
        }));
        setManageAllNews(processedData);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    GetallNews();
  }, []);
  const rowsWithIndex = manageallnews?.map((row) => ({ ...row, id: row.id }));

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
    },
    {
      field: "title",
      headerName: "News Title",
      width: 200,
      editable: true,
    },
    {
      field: "description",
      headerName: "News Description",
      width: 400,
      editable: true,
      groupable: false,
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 200,
      renderCell: (params) => (
        <DeleteIcon
          onClick={() => {
            setOpen(true);
            setSelectedRowId(params.row.id);
          }}
          style={{ cursor: "pointer", color: "red" }}
        />
      ),
    },
  ];

  const handleDelete = () => {
    let params = { id: selectedRowId };
    deletenews(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setOpen(false);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => {}
    );
    setManageAllNews((data) => data.filter((row) => row.id !== selectedRowId));
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={t("pages.title.managenews")}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {/* {isLoading ? (
              <Div
              sx={{
                mt:"20%",
                ml:"45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ):( */}
          <Div>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setOpen(false)}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle sx={{ fontSize: 30 }}>
                {t("pages.title.alert")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  sx={{ fontSize: 20 }}
                >
                  {t("pages.title.deleteIcon")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDelete}>{t("pages.title.yes")}</Button>
                <Button onClick={() => setOpen(false)}>
                  {t("pages.title.no")}
                </Button>
              </DialogActions>
            </Dialog>
          </Div>
          <Box sx={{ height: 500, width: 1 }}>
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={manageallnews}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              // slots={{
              //     toolbar: (props) => (
              //         <GridToolbar
              //             {...props}
              //             sx={{
              //                 backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#fff',
              //                 '& .MuiIconButton-root': {
              //                     color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              //                 },
              //             }}
              //         />
              //     ),
              // }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default Managenews;
