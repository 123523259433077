import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import { Box, CircularProgress } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { poolreport } from "backendServices/ApiCalls";
import React, { useEffect } from "react";
import { useState } from "react";

function PoolReport() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  console.log('data', data)
  const report = () => {
    poolreport(
      "",
      (response) => {
        // console.log('response', response)
        // const processedData = response?.data?.data?.enteries.map(
        //   (row, index) => ({
        //     ...row,
        //     index: index + 1,
        //   })
        // );
        setData(response?.data?.data);
        setLoading(false)
      },
      (error) => {}
    );
  };
  useEffect(() => {
    report();
  }, []);

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          username: false,
          month: false,
          email: false,
          poolname: false,
          silver: false,
          gold: false,
          Platinum: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#000",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [
    // {
    //   field: "index",
    //   headerName: "Sr #",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 150,
    //   editable: true,
    //   groupable: false,
    //   aggregable: false,
    // },
    {
      field: "username",
      headerName: "Username",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "firstname",
      headerName: "First Name",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "email",
      headerName: "Email",
      dataGeneratorUniquenessEnabled: true,
      width: 300,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "poolmonth",
      headerName: "Month",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "pool",
      headerName: "Pool",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      valueFormatter: (params) => {
        return 'Platinum'; // Adjusting index since months are 1-based
      },
    },
    {
      field: "totalamount",
      headerName: "Total Amount",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      valueFormatter: (params) => {
        return params.value.toFixed(2);
      },
    },
    {
      field: "poolamount",
      headerName: "Pool Amount",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      valueFormatter: (params) => {
        return params.value.toFixed(2);
      },
    },
    {
      field: "totaluser",
      headerName: "total User",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
  ];

  const rows = data;

  return (
    <>
      {/* <button onClick={report}>get data</button> */}
      <JumboDemoCard
        title={"Donation Summary"}
        wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
      >
      {loading ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%",
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
        <Box sx={{ height: 460, width: 1 }}>
          <DataGrid
            initialState={{
              initialState,
              pagination: { paginationModel: { pageSize: 6 } },
            }}
            rows={rows}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            sx={gridDesign}
            pageSizeOptions={[6, 12, 18, 24, 30]}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>
      )}
      </JumboDemoCard>
    </>
  );
}

export default PoolReport;
