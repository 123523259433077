import React, { useEffect } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Box,
  CircularProgress,
  Grid,
  List,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { getsettingsdata, updatesettingdata } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  referral_commission_type: yup
    .string()
    .required("Referral Commission is required"),
  referral_commission_value: yup
    .number()
    .required("Referral Commission value is required"),
  referral_commission_status: yup
    .string()
    .typeError("Payout date must be a valid date")
    .required("Referral Commission Status is required"),
  unilevel_status: yup.string().required("Unilevel Status is required"),
});

const Managecommission = () => {
  const { t } = useTranslation();
  const [commissiondata, setCommissionData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const fetchcommissiondata = () => {
    const params = {
      keynames:
        "'referral_commission_type', 'referral_commission_value', 'referral_commission_status', 'unilevel_status'",
    };
    getsettingsdata(params, (response) => {
      setCommissionData(response?.data?.data?.values);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    });
  };

  useEffect(() => {
    fetchcommissiondata();
  }, []);

  const handleSubmit = (data, setSubmitting) => {
    const newData = {
      obj: {
        referral_commission_type: data.referral_commission_type,
        referral_commission_value: data.referral_commission_value,
        referral_commission_status: data.referral_commission_status,
        unilevel_status: data.unilevel_status,
      },
    };
    updatesettingdata(
      newData,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
        }
      },
      (error) => {}
    );
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={t("pages.title.managecommission")} noWrapper>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {isLoading ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%",
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
            <List disablePadding sx={{ mb: 2 }}>
              <Formik
                initialValues={{
                  referral_commission_type: commissiondata[0]?.keyvalue || "",
                  referral_commission_value: commissiondata[1]?.keyvalue || "",
                  referral_commission_status: commissiondata[2]?.keyvalue || "",
                  unilevel_status: commissiondata[3]?.keyvalue || "",
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                  setSubmitting(true);
                  handleSubmit(data, setSubmitting);
                }}
              >
                {({ isSubmitting, values, setFieldValue }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="referral_commission_type"
                        label="Referral Commission Type"
                        select
                      >
                        <MenuItem value="Percentage">
                          {t("pages.title.percentage")}
                        </MenuItem>
                        <MenuItem value="Flat">
                          {t("pages.title.flat")}
                        </MenuItem>
                      </JumboTextField>
                    </Div>

                    <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <JumboTextField
                            fullWidth
                            name="referral_commission_value"
                            label="Referral Commission Value"
                            type="text"
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography sx={{ pt: 1, pl: 1 }}>
                            {t("pages.title.commissionstatus")}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  values.referral_commission_status === "On"
                                } // Check if referral_commission_status is 'On'
                                onChange={(e) =>
                                  setFieldValue(
                                    "referral_commission_status",
                                    e.target.checked ? "On" : "Off"
                                  )
                                } // Set 'On' or 'Off' based on the switch state
                                name="referral_commission_status"
                                color="primary"
                                value={values.referral_commission_status} // Set value as 'On' or 'Off'
                              />
                            }
                            label={values.referral_commission_status} // Display 'On' or 'Off' based on the switch state
                          />
                        </Grid>
                      </Grid>
                    </Div>

                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography sx={{ pt: 1, pl: 1 }}>
                            {t("pages.title.unilevelstatus")}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.unilevel_status === "On"} // Check if referral_commission_status is 'On'
                                onChange={(e) =>
                                  setFieldValue(
                                    "unilevel_status",
                                    e.target.checked ? "On" : "Off"
                                  )
                                } // Set 'On' or 'Off' based on the switch state
                                name="unilevel_status"
                                color="primary"
                                value={values.unilevel_status} // Set value as 'On' or 'Off'
                              />
                            }
                            label={values.unilevel_status} // Display 'On' or 'Off' based on the switch state
                          />
                        </Grid>
                      </Grid>
                    </Div>

                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        {t("pages.title.submit")}
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          )}
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default Managecommission;
