import React, { useEffect, useRef, useState } from "react";
import "./tree.css";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Slide,
  Dialog,
} from "@mui/material";
import MuiPopper from "@mui/material/Popper";
import {
  getBinaryTreeData,
  getSingleUserBinaryTreeData,
} from "backendServices/ApiCalls";
import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div/Div";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { styled } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StarIcon from "@mui/icons-material/Star";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import { getAssetPath } from "app/utils/appHelpers";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Popper = styled(MuiPopper, {
  shouldForwardProp: (prop) => prop !== "arrow",
})(({ theme, arrow }) => ({
  zIndex: 1,
  "& > div": {
    position: "relative",
  },
  '&[data-popper-placement*="bottom"]': {
    "& > div": {
      marginTop: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    "& > div": {
      marginBottom: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="right"]': {
    "& > div": {
      marginLeft: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="left"]': {
    "& > div": {
      marginRight: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}));

const BinaryTree = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [userHierarchyData, setUserHierarchyData] = useState();
  const [topUserData, setTopUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [popoverLoading, setPopoverLoading] = useState(false);
  const [randomCode, setRandomCode] = useState(null);
  const [open, setOpen] = useState(false);
  // State for popover anchor element
  const [singleUserData, setSingleUserData] = useState({});
  const containerRef = useRef(null); // Ref to the container element
  const navigate = useNavigate();

  const randomcode = location.state?.randomcode || "";

  const getSingleUserTreeData = (id, topid) => {
    setOpen(true);
    setPopoverLoading(true);
    let params = {
      id: id,
      topid: topid,
    };
    getSingleUserBinaryTreeData(
      params,
      (response) => {
        setSingleUserData(response?.data?.data);
        setPopoverLoading(false);
      },
      (error) => {
        setPopoverLoading(false);
      }
    );
  };

  const HierArchyData = () => {
    setLoading(true);
    let params;
    let localRandomCode = localStorage.getItem("randomCode");
    if (localRandomCode) {
      params = {
        userrandomcode: localRandomCode,
      };
      localStorage.removeItem("randomCode");
    } else {
      params = {
        userrandomcode: randomcode,
      };
    }
    getBinaryTreeData(
      params,
      (response) => {
        setUserHierarchyData(response?.data?.data);
        setTopUserData(response?.data?.data?.user1?.userRankSponsorData);
        setLoading(false);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    HierArchyData();
  }, [randomCode]);

  const handleTreeView = (prandomcode) => {
    localStorage.setItem("randomCode", prandomcode);
    setRandomCode(prandomcode);
    navigate("/binary-tree", { state: { randomcode: randomcode } });
  };

  let rankColors = [
    "red",
    "yellow",
    "blue",
    "orange",
    "pink",
    "green",
    "purple",
    "maroon",
    "gold",
  ];

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12} md={6} lg={12}>
        <Grid sx={{ textAlign: "center" }} container spacing={3.75}>
          <Grid item xs={12} md={4} lg={4}>
            {randomCode !== randomcode ? (
              <Button
                onClick={() => handleTreeView(randomcode)}
                type="button"
                color="primary"
                variant="contained"
                endIcon={<KeyboardDoubleArrowUpIcon />}
              >
                {t("pages.title.backto_Top")}
              </Button>
            ) : null}
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            {/* <SearchUser setRandomCode={setRandomCode} /> */}
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            {randomCode !== randomcode ? (
              <Button
                onClick={() => handleTreeView(topUserData?.levelUpRandomCode)}
                type="button"
                color="primary"
                variant="contained"
                endIcon={<KeyboardDoubleArrowUpIcon />}
              >
                One Level Up
              </Button>
            ) : null}
          </Grid>
        </Grid>

        <section
          className="management-hierarchy"
          id="tree"
          style={{ backgroundColor: "secondary" }}
        >
          <Box>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setOpen(false)}
              aria-describedby="alert-dialog-slide-description"
            >
              {popoverLoading ? (
                <Div
                  sx={{
                    display: "flex",
                    minWidth: 0,
                    alignItems: "center",
                    alignContent: "center",
                    height: 300,
                    width: 300,
                  }}
                >
                  <CircularProgress sx={{ m: "-40px auto 0" }} />
                </Div>
              ) : (
                <Div sx={{ width: 380 }}>
                  <Typography variant="h5" sx={{ padding: 1 }} color="initial">
                    {"Details of " +
                      singleUserData?.userRankSponsorData?.username}
                  </Typography>
                  <Box sx={{ textAlign: "center" }}>
                    <Button
                      onClick={() =>
                        handleTreeView(
                          singleUserData?.userRankSponsorData?.randomcode
                        )
                      }
                      type="button"
                      color="primary"
                      variant="contained"
                      size="small"
                    >
                      {t("pages.title.viewtree")}
                    </Button>
                    <Button
                      sx={{ marginLeft: 2 }}
                      onClick={() => setOpen(false)}
                      type="button"
                      color="primary"
                      variant="contained"
                      size="small"
                    >
                      {t("pages.title.close")}
                    </Button>
                    {/* <Button component={Link} to={popoverContent.link} color='primary' variant="contained" size='small'>View Tree</Button> */}
                  </Box>
                  <List disablePadding sx={{ mb: 2 }}>
                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={6} lg={6} sm={6}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <AccountCircleIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.username")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {singleUserData?.userRankSponsorData?.username}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                      <Grid item md={6} lg={6} sm={6}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <AccountCircleIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.fullname")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {singleUserData?.userRankSponsorData
                                  ?.firstname +
                                  " " +
                                  singleUserData?.userRankSponsorData?.lastname}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>

                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={12} lg={12} sm={12}>
                        {!singleUserData?.crown ? (
                          <ListItem
                            alignItems="flex-start"
                            sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                          >
                            <ListItemIcon
                              sx={{ minWidth: 36, color: "text.secondary" }}
                            >
                              <GroupAddIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body1"
                                  color="text.secondary"
                                >
                                  {t("pages.title.sponsorname")}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body1"
                                  color="text.primary"
                                >
                                  {
                                    singleUserData?.userRankSponsorData
                                      ?.sponsor_name
                                  }
                                </Typography>
                              }
                            />
                          </ListItem>
                        ) : null}
                      </Grid>
                      <Grid item md={12} lg={12} sm={12}>
                        {singleUserData?.crown ? (
                          <ListItem
                            alignItems="flex-start"
                            sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                          >
                            <ListItemIcon
                              sx={{ minWidth: 36, color: "text.secondary" }}
                            >
                              <MarkEmailReadIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body1"
                                  color="text.secondary"
                                >
                                  {t("pages.title.email")}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body1"
                                  color="text.primary"
                                >
                                  {singleUserData?.userRankSponsorData?.email}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ) : null}
                      </Grid>
                    </Grid>

                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={6} lg={6} sm={6}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <GroupAddIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.affiliate")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {singleUserData?.userRankSponsorData
                                  ?.user_type === "Distributor"
                                  ? "Yes"
                                  : "No"}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                      <Grid item md={6} lg={6} sm={6}>
                        {singleUserData?.crown ? (
                          <ListItem
                            alignItems="flex-start"
                            sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                          >
                            <ListItemIcon
                              sx={{ minWidth: 36, color: "text.secondary" }}
                            >
                              <PhoneInTalkIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body1"
                                  color="text.secondary"
                                >
                                  {t("pages.title.mobile")}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body1"
                                  color="text.primary"
                                >
                                  {singleUserData?.userRankSponsorData?.phone}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ) : null}
                      </Grid>
                    </Grid>

                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={6} lg={6} sm={6}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <MilitaryTechIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.payoutrank")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {singleUserData?.userRankSponsorData?.rank_name}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                      <Grid item md={6} lg={6} sm={6}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <MilitaryTechIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.lifetimerank")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {
                                  singleUserData?.userRankSponsorData
                                    ?.life_time_rank_name
                                }
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>

                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={12} lg={12} sm={12}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <MilitaryTechIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.novafreerank")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {
                                  singleUserData?.userRankSponsorData
                                    ?.nova_rank_name
                                }
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>

                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={12} lg={12} sm={12}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <GroupAddIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.activepersonalLR")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {singleUserData?.activeReferrals
                                  ?.personalActiveLeftCount +
                                  "/" +
                                  singleUserData?.activeReferrals
                                    ?.personalActiveRightCount}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>

                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={12} lg={12} sm={12}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <AccountBalanceWalletIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.current_month_volumeLR")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {singleUserData?.binaryPoints
                                  ?.organizationLeftPointsCount +
                                  "/" +
                                  singleUserData?.binaryPoints
                                    ?.organizationRightPointsCount}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </List>
                </Div>
              )}
            </Dialog>
          </Box>

          <div className="hv-container">
            <div id="wrapper" className="hv-wrapper">
              {/* start */}
              {/* Key component */}
              <div
                id="container"
                className="hv-item custom_zoom"
                ref={containerRef}
              >
                <div className="hv-item-parent">
                  {userHierarchyData?.user1?.crown ? (
                    <img
                      style={{
                        width: 40,
                        height: 25,
                        marginRight: -50,
                        float: "left",
                      }}
                      src={getAssetPath(`${ASSET_IMAGES}/crown.png`)}
                    />
                  ) : null}
                  <div
                    style={{ textDecoration: "none" }}
                    className="person"
                    onClick={() =>
                      userHierarchyData?.user1 !== null
                        ? handleTreeView(
                            userHierarchyData?.user1?.userRankSponsorData
                              ?.randomcode
                          )
                        : null
                    }
                  >
                    <img
                      src={userHierarchyData?.imageUrl + "profile.png"}
                      alt=""
                      style={{
                        marginBottom: 5,
                        width: 80,
                        height: 80,
                        borderColor: "#7352C7",
                      }}
                    />
                    <p
                      className="name"
                      style={{
                        backgroundColor: "#7352C7",
                        color: "white",
                        width: 175,
                      }}
                      onMouseEnter={() =>
                        userHierarchyData?.user1 !== null
                          ? getSingleUserTreeData(
                              userHierarchyData?.user1?.userRankSponsorData?.id,
                              userHierarchyData?.user1?.userRankSponsorData?.id
                            )
                          : null
                      }
                    >
                      {userHierarchyData?.user1?.userRankSponsorData
                        ?.username || "empty"}
                      <br />
                      {userHierarchyData?.user1?.userRankSponsorData?.rank_name}
                      <span
                        style={{
                          display: "inline-block",
                          background:
                            rankColors[
                              userHierarchyData?.user1?.userRankSponsorData
                                ?.rank
                            ],
                          borderRadius: "50%",
                          width: "10px",
                          height: "10px",
                          marginLeft: 3,
                        }}
                      ></span>
                      <br />
                      {
                        userHierarchyData?.user1?.userRankSponsorData
                          ?.nova_rank_name
                      }
                      {userHierarchyData?.user1?.userRankSponsorData
                        ?.novarank === 1 ? (
                        <>
                          {
                            userHierarchyData?.user1?.userRankSponsorData
                              ?.nova_rank_name
                          }{" "}
                          <StarIcon style={{ color: "gold", fontSize: 17 }} />
                        </>
                      ) : userHierarchyData?.user1?.userRankSponsorData
                          ?.novarank === 2 ? (
                        <>
                          <StarIcon style={{ color: "gold", fontSize: 17 }} />
                          <StarIcon style={{ color: "gold", fontSize: 17 }} />
                        </>
                      ) : userHierarchyData?.user1?.userRankSponsorData
                          ?.novarank === 3 ? (
                        <>
                          <StarIcon style={{ color: "gold", fontSize: 17 }} />
                          <StarIcon style={{ color: "gold", fontSize: 17 }} />
                          <StarIcon style={{ color: "gold", fontSize: 17 }} />
                        </>
                      ) : (
                        <span
                          style={{
                            display: "inline-block",
                            background:
                              rankColors[
                                userHierarchyData?.user1?.userRankSponsorData
                                  ?.rank
                              ],
                            borderRadius: "50%",
                            width: "10px",
                            height: "10px",
                            marginLeft: 3,
                          }}
                        ></span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="hv-item-children">
                  <div className="hv-item-child">
                    {/* Key component */}
                    <div className="hv-item">
                      <div className="hv-item-parent">
                        {userHierarchyData?.user2?.crown ? (
                          <img
                            style={{
                              width: 40,
                              height: 25,
                              marginRight: -50,
                              float: "left",
                            }}
                            src={getAssetPath(`${ASSET_IMAGES}/crown.png`)}
                          />
                        ) : null}
                        <div
                          style={{ textDecoration: "none" }}
                          className="person"
                          onClick={() =>
                            userHierarchyData?.user2 !== null
                              ? handleTreeView(
                                  userHierarchyData?.user2?.userRankSponsorData
                                    ?.randomcode
                                )
                              : null
                          }
                        >
                          <img
                            src={userHierarchyData?.imageUrl + "profile.png"}
                            alt=""
                            style={{
                              marginBottom: 5,
                              width: 80,
                              height: 80,
                              borderColor: "#7352C7",
                            }}
                          />
                          {userHierarchyData?.user2 !== null ? (
                            <p
                              className="name"
                              style={{
                                backgroundColor: "#7352C7",
                                color: "white",
                                width: 175,
                              }}
                              onMouseEnter={() =>
                                userHierarchyData?.user2 !== null
                                  ? getSingleUserTreeData(
                                      userHierarchyData?.user2
                                        ?.userRankSponsorData?.id,
                                      userHierarchyData?.user1
                                        ?.userRankSponsorData?.id
                                    )
                                  : null
                              }
                            >
                              {
                                userHierarchyData?.user2?.userRankSponsorData
                                  ?.username
                              }
                              <br />
                              {
                                userHierarchyData?.user2?.userRankSponsorData
                                  ?.rank_name
                              }
                              <span
                                style={{
                                  display: "inline-block",
                                  background:
                                    rankColors[
                                      userHierarchyData?.user2
                                        ?.userRankSponsorData?.rank
                                    ],
                                  borderRadius: "50%",
                                  width: "10px",
                                  height: "10px",
                                  marginLeft: 3,
                                }}
                              ></span>
                              <br />
                              {
                                userHierarchyData?.user2?.userRankSponsorData
                                  ?.nova_rank_name
                              }
                              {userHierarchyData?.user2?.userRankSponsorData
                                ?.novarank === 1 ? (
                                <>
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                </>
                              ) : userHierarchyData?.user2?.userRankSponsorData
                                  ?.novarank === 2 ? (
                                <>
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                </>
                              ) : userHierarchyData?.user2?.userRankSponsorData
                                  ?.novarank === 3 ? (
                                <>
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                </>
                              ) : (
                                <span
                                  style={{
                                    display: "inline-block",
                                    background:
                                      rankColors[
                                        userHierarchyData?.user2
                                          ?.userRankSponsorData?.rank
                                      ],
                                    borderRadius: "50%",
                                    width: "10px",
                                    height: "10px",
                                    marginLeft: 3,
                                  }}
                                ></span>
                              )}
                            </p>
                          ) : (
                            <p
                              className="name"
                              style={{
                                backgroundColor: "#7352C7",
                                color: "white",
                                width: 175,
                              }}
                            >
                              {t("pages.title.empty")}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="hv-item-children">
                        <div className="hv-item-child">
                          <div className="hv-item-child">
                            {/* Key component */}
                            <div className="hv-item">
                              <div className="">
                                {userHierarchyData?.user4?.crown ? (
                                  <img
                                    style={{
                                      width: 40,
                                      height: 25,
                                      marginRight: -50,
                                      float: "left",
                                    }}
                                    src={getAssetPath(
                                      `${ASSET_IMAGES}/crown.png`
                                    )}
                                  />
                                ) : null}
                                <div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={() =>
                                    userHierarchyData?.user4 !== null
                                      ? handleTreeView(
                                          userHierarchyData?.user4
                                            ?.userRankSponsorData?.randomcode
                                        )
                                      : null
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.imageUrl +
                                      "profile.png"
                                    }
                                    alt=""
                                    style={{
                                      marginBottom: 5,
                                      width: 80,
                                      height: 80,
                                      borderColor: "#7352C7",
                                    }}
                                  />
                                  {userHierarchyData?.user4 !== null ? (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                      onMouseEnter={() =>
                                        userHierarchyData?.user4 !== null
                                          ? getSingleUserTreeData(
                                              userHierarchyData?.user4
                                                ?.userRankSponsorData?.id,
                                              userHierarchyData?.user1
                                                ?.userRankSponsorData?.id
                                            )
                                          : null
                                      }
                                    >
                                      {userHierarchyData?.user4
                                        ?.userRankSponsorData?.username ||
                                        "empty"}
                                      <br />
                                      {
                                        userHierarchyData?.user4
                                          ?.userRankSponsorData?.rank_name
                                      }
                                      <span
                                        style={{
                                          display: "inline-block",
                                          background:
                                            rankColors[
                                              userHierarchyData?.user4
                                                ?.userRankSponsorData?.rank
                                            ],
                                          borderRadius: "50%",
                                          width: "10px",
                                          height: "10px",
                                          marginLeft: 3,
                                        }}
                                      ></span>
                                      <br />
                                      {
                                        userHierarchyData?.user4
                                          ?.userRankSponsorData?.nova_rank_name
                                      }
                                      {userHierarchyData?.user4
                                        ?.userRankSponsorData?.novarank ===
                                      1 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user4
                                          ?.userRankSponsorData?.novarank ===
                                        2 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user4
                                          ?.userRankSponsorData?.novarank ===
                                        3 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <span
                                          style={{
                                            display: "inline-block",
                                            background:
                                              rankColors[
                                                userHierarchyData?.user4
                                                  ?.userRankSponsorData?.rank
                                              ],
                                            borderRadius: "50%",
                                            width: "10px",
                                            height: "10px",
                                            marginLeft: 3,
                                          }}
                                        ></span>
                                      )}
                                    </p>
                                  ) : (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                    >
                                      {t("pages.title.empty")}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hv-item-child">
                          <div className="hv-item-child">
                            {/* Key component */}
                            <div
                              className="hv-item"
                              style={{ marginBottom: 100 }}
                            >
                              <div className="">
                                {userHierarchyData?.user5?.crown ? (
                                  <img
                                    style={{
                                      width: 40,
                                      height: 25,
                                      marginRight: -50,
                                      float: "left",
                                    }}
                                    src={getAssetPath(
                                      `${ASSET_IMAGES}/crown.png`
                                    )}
                                  />
                                ) : null}
                                <div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={() =>
                                    userHierarchyData?.user5 !== null
                                      ? handleTreeView(
                                          userHierarchyData?.user5
                                            ?.userRankSponsorData?.randomcode
                                        )
                                      : null
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.imageUrl +
                                      "profile.png"
                                    }
                                    alt=""
                                    style={{
                                      marginBottom: 5,
                                      width: 80,
                                      height: 80,
                                      borderColor: "#7352C7",
                                    }}
                                  />
                                  {userHierarchyData?.user5 !== null ? (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                      onMouseEnter={() =>
                                        userHierarchyData?.user5 !== null
                                          ? getSingleUserTreeData(
                                              userHierarchyData?.user5
                                                ?.userRankSponsorData?.id,
                                              userHierarchyData?.user1
                                                ?.userRankSponsorData?.id
                                            )
                                          : null
                                      }
                                    >
                                      {userHierarchyData?.user5
                                        ?.userRankSponsorData?.username ||
                                        "empty"}
                                      <br />
                                      {
                                        userHierarchyData?.user5
                                          ?.userRankSponsorData?.rank_name
                                      }
                                      <span
                                        style={{
                                          display: "inline-block",
                                          background:
                                            rankColors[
                                              userHierarchyData?.user5
                                                ?.userRankSponsorData?.rank
                                            ],
                                          borderRadius: "50%",
                                          width: "10px",
                                          height: "10px",
                                          marginLeft: 3,
                                        }}
                                      ></span>
                                      <br />
                                      {
                                        userHierarchyData?.user5
                                          ?.userRankSponsorData?.nova_rank_name
                                      }
                                      {userHierarchyData?.user5
                                        ?.userRankSponsorData?.novarank ===
                                      1 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user5
                                          ?.userRankSponsorData?.novarank ===
                                        2 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user5
                                          ?.userRankSponsorData?.novarank ===
                                        3 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <span
                                          style={{
                                            display: "inline-block",
                                            background:
                                              rankColors[
                                                userHierarchyData?.user5
                                                  ?.userRankSponsorData?.rank
                                              ],
                                            borderRadius: "50%",
                                            width: "10px",
                                            height: "10px",
                                            marginLeft: 3,
                                          }}
                                        ></span>
                                      )}
                                    </p>
                                  ) : (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                    >
                                      {t("pages.title.empty")}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hv-item-child">
                    {/* Key component */}
                    <div className="hv-item">
                      <div className="hv-item-parent">
                        {userHierarchyData?.user3?.crown ? (
                          <img
                            style={{
                              width: 40,
                              height: 25,
                              marginRight: -50,
                              float: "left",
                            }}
                            src={getAssetPath(`${ASSET_IMAGES}/crown.png`)}
                          />
                        ) : null}
                        <div
                          style={{ textDecoration: "none" }}
                          className="person"
                          onClick={() =>
                            userHierarchyData?.user3 !== null
                              ? handleTreeView(
                                  userHierarchyData?.user3?.userRankSponsorData
                                    ?.randomcode
                                )
                              : null
                          }
                        >
                          <img
                            src={userHierarchyData?.imageUrl + "profile.png"}
                            alt=""
                            style={{
                              marginBottom: 5,
                              width: 80,
                              height: 80,
                              borderColor: "#7352C7",
                            }}
                          />
                          {userHierarchyData?.user3 !== null ? (
                            <p
                              className="name"
                              style={{
                                backgroundColor: "#7352C7",
                                color: "white",
                                width: 175,
                              }}
                              onMouseEnter={() =>
                                userHierarchyData?.user3 !== null
                                  ? getSingleUserTreeData(
                                      userHierarchyData?.user3
                                        ?.userRankSponsorData?.id,
                                      userHierarchyData?.user1
                                        ?.userRankSponsorData?.id
                                    )
                                  : null
                              }
                            >
                              {
                                userHierarchyData?.user3?.userRankSponsorData
                                  ?.username
                              }
                              <br />
                              {
                                userHierarchyData?.user3?.userRankSponsorData
                                  ?.rank_name
                              }
                              <span
                                style={{
                                  display: "inline-block",
                                  background:
                                    rankColors[
                                      userHierarchyData?.user3
                                        ?.userRankSponsorData?.rank
                                    ],
                                  borderRadius: "50%",
                                  width: "10px",
                                  height: "10px",
                                  marginLeft: 3,
                                }}
                              ></span>
                              <br />
                              {
                                userHierarchyData?.user3?.userRankSponsorData
                                  ?.nova_rank_name
                              }
                              {userHierarchyData?.user3?.userRankSponsorData
                                ?.novarank === 1 ? (
                                <>
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                </>
                              ) : userHierarchyData?.user3?.userRankSponsorData
                                  ?.novarank === 2 ? (
                                <>
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                </>
                              ) : userHierarchyData?.user3?.userRankSponsorData
                                  ?.novarank === 3 ? (
                                <>
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                </>
                              ) : (
                                <span
                                  style={{
                                    display: "inline-block",
                                    background:
                                      rankColors[
                                        userHierarchyData?.user3
                                          ?.userRankSponsorData?.rank
                                      ],
                                    borderRadius: "50%",
                                    width: "10px",
                                    height: "10px",
                                    marginLeft: 3,
                                  }}
                                ></span>
                              )}
                            </p>
                          ) : (
                            <p
                              className="name"
                              style={{
                                backgroundColor: "#7352C7",
                                color: "white",
                                width: 175,
                              }}
                            >
                              {t("pages.title.empty")}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="hv-item-children">
                        <div className="hv-item-child">
                          <div className="hv-item-child">
                            {/* Key component */}
                            <div className="hv-item">
                              <div className="">
                                {userHierarchyData?.user6?.crown ? (
                                  <img
                                    style={{
                                      width: 40,
                                      height: 25,
                                      marginRight: -50,
                                      float: "left",
                                    }}
                                    src={getAssetPath(
                                      `${ASSET_IMAGES}/crown.png`
                                    )}
                                  />
                                ) : null}
                                <div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={() =>
                                    userHierarchyData?.user6 !== null
                                      ? handleTreeView(
                                          userHierarchyData?.user6
                                            ?.userRankSponsorData?.randomcode
                                        )
                                      : null
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.imageUrl +
                                      "profile.png"
                                    }
                                    alt=""
                                    style={{
                                      marginBottom: 5,
                                      width: 80,
                                      height: 80,
                                      borderColor: "#7352C7",
                                    }}
                                  />
                                  {userHierarchyData?.user6 !== null ? (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                      onMouseEnter={() =>
                                        userHierarchyData?.user6 !== null
                                          ? getSingleUserTreeData(
                                              userHierarchyData?.user6
                                                ?.userRankSponsorData?.id,
                                              userHierarchyData?.user1
                                                ?.userRankSponsorData?.id
                                            )
                                          : null
                                      }
                                    >
                                      {userHierarchyData?.user6
                                        ?.userRankSponsorData?.username ||
                                        "empty"}
                                      <br />
                                      {
                                        userHierarchyData?.user6
                                          ?.userRankSponsorData?.rank_name
                                      }
                                      <span
                                        style={{
                                          display: "inline-block",
                                          background:
                                            rankColors[
                                              userHierarchyData?.user6
                                                ?.userRankSponsorData?.rank
                                            ],
                                          borderRadius: "50%",
                                          width: "10px",
                                          height: "10px",
                                          marginLeft: 3,
                                        }}
                                      ></span>
                                      <br />
                                      {
                                        userHierarchyData?.user6
                                          ?.userRankSponsorData?.nova_rank_name
                                      }
                                      {userHierarchyData?.user6
                                        ?.userRankSponsorData?.novarank ===
                                      1 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user6
                                          ?.userRankSponsorData?.novarank ===
                                        2 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user6
                                          ?.userRankSponsorData?.novarank ===
                                        3 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <span
                                          style={{
                                            display: "inline-block",
                                            background:
                                              rankColors[
                                                userHierarchyData?.user6
                                                  ?.userRankSponsorData?.rank
                                              ],
                                            borderRadius: "50%",
                                            width: "10px",
                                            height: "10px",
                                            marginLeft: 3,
                                          }}
                                        ></span>
                                      )}
                                    </p>
                                  ) : (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                    >
                                      {t("pages.title.empty")}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="hv-item-child">
                          <div className="hv-item-child">
                            {/* Key component */}
                            <div className="hv-item">
                              <div className="">
                                {userHierarchyData?.user7?.crown ? (
                                  <img
                                    style={{
                                      width: 40,
                                      height: 25,
                                      marginRight: -50,
                                      float: "left",
                                    }}
                                    src={getAssetPath(
                                      `${ASSET_IMAGES}/crown.png`
                                    )}
                                  />
                                ) : null}
                                <div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={() =>
                                    userHierarchyData?.user7 !== null
                                      ? handleTreeView(
                                          userHierarchyData?.user7
                                            ?.userRankSponsorData?.randomcode
                                        )
                                      : null
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.imageUrl +
                                      "profile.png"
                                    }
                                    alt=""
                                    style={{
                                      marginBottom: 5,
                                      width: 80,
                                      height: 80,
                                      borderColor: "#7352C7",
                                    }}
                                  />
                                  {userHierarchyData?.user7 !== null ? (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                      onMouseEnter={() =>
                                        userHierarchyData?.user7 !== null
                                          ? getSingleUserTreeData(
                                              userHierarchyData?.user7
                                                ?.userRankSponsorData?.id,
                                              userHierarchyData?.user1
                                                ?.userRankSponsorData?.id
                                            )
                                          : null
                                      }
                                    >
                                      {userHierarchyData?.user7
                                        ?.userRankSponsorData?.username ||
                                        "empty"}
                                      <br />
                                      {
                                        userHierarchyData?.user7
                                          ?.userRankSponsorData?.rank_name
                                      }
                                      <span
                                        style={{
                                          display: "inline-block",
                                          background:
                                            rankColors[
                                              userHierarchyData?.user7
                                                ?.userRankSponsorData?.rank
                                            ],
                                          borderRadius: "50%",
                                          width: "10px",
                                          height: "10px",
                                          marginLeft: 3,
                                        }}
                                      ></span>
                                      <br />
                                      {
                                        userHierarchyData?.user7
                                          ?.userRankSponsorData?.nova_rank_name
                                      }
                                      {userHierarchyData?.user7
                                        ?.userRankSponsorData?.novarank ===
                                      1 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user7
                                          ?.userRankSponsorData?.novarank ===
                                        2 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user7
                                          ?.userRankSponsorData?.novarank ===
                                        3 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <span
                                          style={{
                                            display: "inline-block",
                                            background:
                                              rankColors[
                                                userHierarchyData?.user7
                                                  ?.userRankSponsorData?.rank
                                              ],
                                            borderRadius: "50%",
                                            width: "10px",
                                            height: "10px",
                                            marginLeft: 3,
                                          }}
                                        ></span>
                                      )}
                                    </p>
                                  ) : (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                    >
                                      {t("pages.title.empty")}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end */}
            </div>
          </div>
        </section>
      </Grid>
    </Grid>
  );
};

export default BinaryTree;
