import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getunpaidcomission } from "backendServices/ApiCalls";
import React, { useEffect } from "react";
import { useState } from "react";
import EuroIcon from "@mui/icons-material/Euro";
import CardIconText from "@jumbo/shared/CardIconText";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

function UnpaidCommission() {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
	const [totalEurPayout, setTotalEurPayout] = useState(0);
	const [totalUsdPayout, setTotalUsdPayout] = useState(0);


  const report = () => {
    setLoader(true);
    getunpaidcomission(
      "",
      (response) => {
        setData(response?.data?.data);
				const receivedData = response?.data?.data;
				const totalEur = receivedData.reduce((sum, item) => sum + item.current_balance_eur_payout, 0);
        setTotalEurPayout(totalEur);
				const totalUsd = receivedData.reduce((sum, item) => sum + item.current_balance_usd_payout, 0);
        setTotalUsdPayout(totalUsd);
        setLoader(false);
      },
      (error) => {
        setLoader(false);
      }
    );
  };
  useEffect(() => {
    report();
  }, []);



  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          username: false,
          month: false,
          email: false,
          poolname: false,
          silver: false,
          gold: false,
          Platinum: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#000",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const columns = [
    {
      field: "username",
      headerName: "Username",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "firstname",
      headerName: "First Name",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      aggregable: false,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      aggregable: false,
    },
    {
      field: "email",
      headerName: "Email",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "current_balance_eur_payout",
      headerName: "EUR Payout",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
			valueFormatter: (params) => {
				return parseFloat(params.value).toFixed(2);
			}
    },
    {
      field: "current_balance_usd_payout",
      headerName: "USD Payout",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
			valueFormatter: (params) => {
				return parseFloat(params.value).toFixed(2);
			}
    },
  ];

  const rows = data;

  if (loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item md={6} xs={12}>
				<CardIconText
            sx={{ pb: 2 }}
            icon={<EuroIcon fontSize="large" />}
            // title={<Typography variant={"p"} color={color}>{value}</Typography>}
            subTitle={<>
                <Div sx={{ mt: 2, fontSize: "20px" }}>
                    <Typography variant={"p"} color={'primary.main'}>{(totalEurPayout.toFixed(2))}</Typography>
                </Div>
                <Div sx={{ pb: 2, mt: 2 }}>
                    <Typography variant={"p"}
                        color={"text.primary"}>Total EUR Unpaid Commission
                    </Typography>
                </Div>
                {/* <Div>
                    <Button size='small' variant="contained" onClick={() => navigate(navTo)}>{t('pages.title.view')}</Button>
                </Div> */}
            </>
            }
            color={'primary.main'}
            disableHoverEffect={true}
            hideArrow={true}
            variant={"outlined"}
        />
				</Grid>
        <Grid item md={6} xs={12}>
				<CardIconText
            sx={{ pb: 2 }}
            icon={<AttachMoneyIcon fontSize="large" />}
            // title={<Typography variant={"p"} color={color}>{value}</Typography>}
            subTitle={<>
                <Div sx={{ mt: 2, fontSize: "20px" }}>
                    <Typography variant={"p"} color={'primary.main'}>{(totalUsdPayout.toFixed(2))}</Typography>
                </Div>
                <Div sx={{ pb: 2, mt: 2 }}>
                    <Typography variant={"p"}
                        color={"text.primary"}>Total USD Unpaid Commission
                    </Typography>
                </Div>
                {/* <Div>
                    <Button size='small' variant="contained" onClick={() => navigate(navTo)}>{t('pages.title.view')}</Button>
                </Div> */}
            </>
            }
            color={'primary.main'}
            disableHoverEffect={true}
            hideArrow={true}
            variant={"outlined"}
        />
        </Grid>
      </Grid>
      {/* <button onClick={report}>get data</button> */}
      <JumboDemoCard
        title={"Unpaid Commission"}
        wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
      >
        <Box sx={{ height: 460, width: 1 }}>
          <DataGrid
            initialState={{
              initialState,
              pagination: { paginationModel: { pageSize: 6 } },
            }}
            rows={rows}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            sx={gridDesign}
            pageSizeOptions={[6, 12, 18, 24, 30]}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>
      </JumboDemoCard>
    </>
  );
}

export default UnpaidCommission;
