import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { getusersales } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div/Div";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useLocation } from "react-router-dom";

function UserSales() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [salesUser, setSalesUser] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const sales = location?.state?.sales;

  const SalesUser = (month) => {
    setLoading(true);
    let params = {
      sales: sales,
      month,
    };
    getusersales(
      params,
      (response) => {
        setLoading(false);
        setSalesUser(response?.data?.data);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    SalesUser(selectedMonth);
  }, []);

  useEffect(() => {
    SalesUser(selectedMonth);
  }, [selectedMonth]);

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const columns = [
    {
      field: "username",
      headerName: "Username",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "firstname",
      headerName: "First Name",
      dataGeneratorUniquenessEnabled: true,
      width: 170,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "lastname",
      headerName: "Last name",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "email",
      headerName: "Email",
      dataGeneratorUniquenessEnabled: true,
      width: 230,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "totalUser",
      headerName: "Sales",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
  ];

  const rows = salesUser;
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth() + 1;

  const option = months
    .map((month, index) => ({ title: month, monthNumber: index + 1 }))
    .slice(0, currentMonthIndex)
    .slice(-4); // Get the last 4 months including the current month

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        getOptionLabel={(option) => option.title}
        options={option}
        value={option.find((opt) => opt.monthNumber === selectedMonth)}
        sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}
        renderInput={(params) => <TextField {...params} label="Month" />}
        onChange={(event, newValue) => {
          setSelectedMonth(newValue?.monthNumber); // Assuming newValue is an object with a 'title' property
        }}
      />
      {loading ? (
        <Div
          sx={{
            display: "flex",
            minWidth: 0,
            alignItems: "center",
            alignContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress sx={{ m: "-40px auto 0" }} />
        </Div>
      ) : (
        <JumboDemoCard
          title={"User Sales"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          <Box sx={{ height: 460, width: 1 }}>
            <DataGrid
              initialState={{
                initialState,
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={rows}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
        </JumboDemoCard>
      )}
    </>
  );
}

export default UserSales;
