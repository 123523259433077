import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick'
import Div from '@jumbo/shared/Div/Div'
import { Grid, List, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Stack from '@mui/material/Stack';
import { managecolumnsApi, singleUserApi } from 'backendServices/ApiCalls';
import { useLocation } from 'react-router-dom';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import { useTranslation } from 'react-i18next';

const Managecolumn = () => {
  const {t} = useTranslation();
  const [singleUser, setSingleUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [updateRecord, setUpdateRecord] = useState(false);
  const location = useLocation();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: ""
  })
  const user_id = location.state?.id || '';
  const handleClick = (status, columnname) => {
    let params = {
      userid: user_id,
      status: status,
      columnname: columnname
    }
    managecolumnsApi(params, (response) => {
      setIsLoading(true)
      if (response?.data?.status === "error") {
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: "error"
        })
      } else if (response?.data?.status === "success") {
        setUpdateRecord(false)
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: "success"
        })
        setUpdateRecord(true)
      }
      else {
        setalertData({
          show: true,
          message: 'Something went wrong. Please try again leter',
          variant: "error"
        })
      }
      setIsLoading(false)
    })
  }

  const getSingleUserFunction = () => {
    let params = {
      userid: user_id
    }
    setIsLoading(true);
    singleUserApi(params, response => {
      setSingleUser(response?.data?.data[0]);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    getSingleUserFunction();
  }, [updateRecord]);

  if (isLoading) {
    return <Div
      sx={{
        display: 'flex',
        minWidth: 0,
        alignItems: 'center',
        alignContent: 'center',
        height: '100%',
      }}
    >
      <CircularProgress sx={{ m: '-40px auto 0' }} />
    </Div>
  }

  return (
    <Grid container fullWidth sm={12} xs={12} alignItems="center" justifyContent="center">
      {
        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
      }
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={t('pages.title.user_novalya')} noWrapper >

          <List disablePadding sx={{ mb: 2 }}>

            <form noValidate autoComplete='off'>
              <Grid container sm={12} xs={12} sx={{ mb: 3, pl: 3, ml: 4 }}>
                <Grid item sm={6} xs={12}>

                  <Div>{t('pages.title.connect_status')}</Div>
                  <FormControl sx={{ marginTop: 1.4 }}>
                    <Stack direction="row" spacing={2}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="connect_status"
                      >
                        <FormControlLabel onClick={() => handleClick("On", "connect")} control={<Radio />} checked={singleUser?.connect_status === 'On'} label="On" />
                        <FormControlLabel onClick={() => handleClick("Off", "connect")} control={<Radio />} checked={singleUser?.connect_status === 'Off'} label="Off" />
                      </RadioGroup>
                    </Stack>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>

                  <Div>{t('pages.title.birthday_status')}</Div>
                  <FormControl sx={{ marginTop: 1.4 }}>
                    <Stack direction="row" spacing={2}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="birthday_status"
                      >
                        <FormControlLabel onClick={() => handleClick("On", "birthday")} control={<Radio />} checked={singleUser?.birthday_status === 'On'} label="On" />
                        <FormControlLabel onClick={() => handleClick("Off", "birthday")} control={<Radio />} checked={singleUser?.birthday_status === 'Off'} label="Off" />
                      </RadioGroup>
                    </Stack>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container sm={12} xs={12} sx={{ mb: 3, pl: 3, ml: 4, marginTop: "40px" }}>
                <Grid sm={6} xs={12}  >

                  <Div>{t('pages.title.crm_status')}</Div>
                  <FormControl sx={{ marginTop: 1.4 }}>
                    <Stack direction="row" spacing={2}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="crm_status"
                      //   onChange={handleReferralSideChange}
                      >
                        <FormControlLabel onClick={() => handleClick("On", "crm")} control={<Radio />} checked={singleUser?.crm_status === 'On'} label="On" />
                        <FormControlLabel onClick={() => handleClick("Off", "crm")} control={<Radio />} checked={singleUser?.crm_status === 'Off'} label="Off" />
                      </RadioGroup>
                    </Stack>
                  </FormControl>
                </Grid>
                <Grid sm={6} xs={12} >

                  <Div>{t('pages.title.unfollow_status')}</Div>
                  <FormControl sx={{ marginTop: 1.4 }}>
                    <Stack direction="row" spacing={2}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="unfollow_status"
                      //   onChange={handleReferralSideChange}
                      >
                        <FormControlLabel onClick={() => handleClick("On", "unfollow")} control={<Radio />} checked={singleUser?.unfollow_status === 'On'} label="On" />
                        <FormControlLabel onClick={() => handleClick("Off", "unfollow")} control={<Radio />} checked={singleUser?.unfollow_status === 'Off'} label="Off" />
                      </RadioGroup>
                    </Stack>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </List>
        </JumboCardQuick>
      </Grid>
    </Grid>
  )
}

export default Managecolumn