import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  getGrayLabelListing,
  deleteGrayLabelUser,
  updateGrayLabelStatus,
} from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Inactive = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const navigate = useNavigate();
  const [miniadmin, setMiniAdmin] = useState([]);
  const [rowData, setRowData] = useState(null);

  const GetGrayLable = () => {
    getGrayLabelListing(
      (response) => {
        if (response?.data?.status) {
          const processedData = response?.data?.data?.map((row, index) => ({
            ...row,
            index: index + 1,
          }));
          setMiniAdmin(processedData);
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    GetGrayLable();
  }, []);
  const generateRowId = () => {
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };

  const rowsWithIndex = miniadmin?.map((row) => ({ ...row, id: row.id }));

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
    },
    {
      field: "username",
      headerName: "User Name",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "firstname",
      headerName: "First Name",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: false,
      groupable: false,
      aggregable: false,
    },

    {
      field: "email",
      headerName: "Email",
      dataGeneratorUniquenessEnabled: true,
      width: 250,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      dataGeneratorUniquenessEnabled: true,
      width: 250,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "address1",
      headerName: "Address",
      dataGeneratorUniquenessEnabled: true,
      width: 250,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => (
        <Switch
          color="warning"
          checked={params.row.status === "Approved" ? "active" : ""} // Assuming params.row.status is the status field in your data
          onChange={(event) => {
            handleUpdateStatus(params.row.id, event.target.checked);
          }}
        />
      ),
    },
    {
      field: "update",
      headerName: "Update",
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => (
        <>
          <EditIcon
            color="warning"
            style={{ cursor: "pointer" }}
            onClick={() => handleOpen(params.row.id)}
          />
        </>
      ),
    },
  ];

  const handleOpen = (id) => {
    const rowToEdit = miniadmin.find((row) => row.id === id);
    setRowData(rowToEdit);
    navigate("/graylabel-edit/" + id);
  };

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const handleDelete = () => {
    let params = {
      id: selectedRowId,
    };
    deleteGrayLabelUser(
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: "User Deleted",
            variant: "success",
          });
          GetGrayLable();
          setOpen(false);
        }
      },
      (error) => {}
    );
  };

  const handleUpdateStatus = (id, status) => {
    let params = {
      id: id,
      status: status ? 1 : 0,
    };
    updateGrayLabelStatus(
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: "Update Status Success",
            variant: "success",
          });
          GetGrayLable();
          setOpen(false);
        }
      },
      (error) => {}
    );
  };

  // const rows= usersdata

  let idCounter = 1; // Initialize the counter

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  return (
    <JumboDemoCard
      title="Gray Label Listing"
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpen(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle sx={{ fontSize: 30 }}>
            {t("pages.title.alert")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{ fontSize: 20 }}
            >
              {t("pages.title.deleteIcon")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete}>{t("pages.title.yes")}</Button>
            <Button onClick={() => setOpen(false)}>
              {t("pages.title.no")}
            </Button>
          </DialogActions>
        </Dialog>
      </Div>

      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rowsWithIndex}
          getRowId={(row) => generateRowId()}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
};

export default Inactive;
