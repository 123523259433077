import React from "react";
import List from "@mui/material/List";
import { Grid, Box } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  getAccountSetting,
  updateAccountSetting,
} from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import "./style.css";

const AccountSetting = () => {
  const { t } = useTranslation();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const validationSchema = yup.object({
    extension_version: yup
      .string("Enter Extension version")
      .required("Extension version is required"),
  });
  const [values, setValues] = React.useState();
  const onSubmitForm = (data, setSubmitting) => {
    let params = {
      extension_version: data.extension_version,
    };

    updateAccountSetting(
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: "Account Setting Updated Successfully",
            variant: "success",
          });
          setSubmitting(false);
        }
      },
      (error) => {
        setalertData({
          show: true,
          message: error?.response?.data?.message,
          variant: "error",
        });
        setSubmitting(false);
      }
    );
  };

  function fetchData() {
    getAccountSetting(
      {},
      (response) => {
        const result = response?.data?.data[0];
        if (result) {
          setValues({
            extension_version: result?.extension_version,
          });
        }
      },
      (error) => {
      }
    );
  }

  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <JumboCardQuick title="Account Setting"  className="ctm_acc_setting">
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Box sx={{ position: "relative" }}></Box>
      <List disablePadding>
        <Formik
          validateOnChange={true}
          enableReinitialize="true"
          initialValues={{
            extension_version: values?.extension_version,
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            onSubmitForm(data, setSubmitting);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item sm={8} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label="Extension Version"
                    name="extension_version"
                    type="text"
                  />
                </Grid>

                <Grid
                  item
                  sm={4}
                  sx={{ width: { xs: "100%" }, justifyContent: "end" }}
                >
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    {t("pages.title.submit")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </List>
    </JumboCardQuick>
  );
};

export default AccountSetting;
