import React from "react";
import {
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useState } from "react";
import { useBasicAuth } from "app/auth-providers/BasicAuth/hooks";
import { useEffect } from "react";
import {
  verifyemailaccount,
} from "backendServices/ApiCalls";
import { useTranslation } from "react-i18next";
import jwtAuthAxios from "app/services/auth/jwtAuth";

const validationSchema = yup.object({
  username: yup.string("Enter your username").required("Username is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const companyLogo = async (domain) => {
  try {
    return await jwtAuthAxios.post("/get-company-logo", { domain: domain });
  } catch (e) {
    return e.response;
  }
};

const Login = ({ disableSmLogin }) => {
  const { t } = useTranslation();
  const [randomQuote, setRandomQuote] = useState("");

  const [companyData, setCompanyData] = useState(null);
  const [isDomainLoading, setIsDomainLoading] = React.useState(true);

  const { signIn } = useBasicAuth();
  const navigate = useNavigate();
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  useEffect(() => {
    if (window.location.hostname === "localhost") {
      var testDomainText = "admin-chroex.com";
      var testDomain = testDomainText.split(".");
    } else {
      var testDomain = window.location.hostname.split(".");
    }
    const domainParts = testDomain;

    if (domainParts.length >= 1 && domainParts[0]) {
      let final_domain;
      if (domainParts[0].includes("-")) {
        const parts = domainParts[0].split("-");
        final_domain = parts[1];
      } else {
        final_domain = domainParts[0];
      }

      companyLogo(final_domain)
        .then((response) => {
          setIsDomainLoading(true);
          setCompanyData(response.data.data);

          const favicon = document.querySelector('link[rel="icon"]');

          if (favicon && response.data.data.logo_val) {
            favicon.href = response.data.data.logo_val;
          }

          if (response.data.data.company) {
            var capitalizedCompany =
              response.data.data.company.charAt(0).toUpperCase() +
              response.data.data.company.slice(1);
            document.title = capitalizedCompany + " Admin";
          }
          setIsDomainLoading(false);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false); // Set loading to false once data is fetched
        });
    }
  }, []); // Run only once when the component mounts

  const [validToken, setValidToken] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const [serverError, setServerError] = React.useState(null);
  const { token, email } = useParams();
  const quotes = [
    "Psalm 119:11 “I have stored up Your Word in my heart, that I might not sin against You”.",
    "“Faith expects from God what is beyond all expectation.” (Andrew Murray)",
    "“If we cannot believe God when circumstances seem be against us, we do not believe Him at all.” (Charles Spurgeon)",
    "“For we walk by faith, not by sight” (Paul, 2 Corinthians 5:7)",
    "“Faith is not the belief that God will do what you want.  It is the belief that God will do what is right.” (Max Lucado)",
    "“Faith consists in believing what reason cannot.” (Voltaire)",
    '"Faith is the gaze of a soul upon a saving God." (A.W. Tozer)',
    '"If by faith you are in Christ, God is as much for you in Christ as He will ever be or could ever be" (John Piper)',
    "“Faith is the strength by which a shattered world shall emerge into the light.” (Helen Keller)",
    "“Faith never knows where it is being led, but it loves and knows the One who is leading.” (Oswald Chambers)",
    "“True faith manifests itself through our actions.” (Francis Chan)",
    "“Joy is the infallible proof of the presence of God.”—Madeleine",
    "“For prayer is nothing else than being on terms of friendship with God.”—Saint Teresa of Ávila",
    "“What God intended for you goes far beyond anything you can imagine.”—Oprah Winfrey",
  ];


  useEffect(() => {
    const getRandomQuote = () => {
      const randomIndex = Math.floor(Math.random() * quotes.length);
      const quote = quotes[randomIndex];
      setRandomQuote(quote);
    };

    getRandomQuote();
  }, []);

  const onSignIn = (username, password, setSubmitting) => {
    const ipaddress = "127.0.0.1";
    signIn(
      username,
      password,
      ipaddress,
      (response) => {
        if (response?.status === "error") {
          setalertData({
            show: true,
            message: response?.message,
            variant: "error",
          });
          setSubmitting(false);
          setIsLoading(false);
        } else if (response?.status === "success") {
          setIsLoading(false);
          navigate("/dashboard");
        } else {
          setIsLoading(false);
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setSubmitting(false);
        }
      },
      (error) => {
      }
    );
  };

  function checkToken(token, email) {
    if (token && email) {
      setIsLoading(true);
      verifyemailaccount(
        token,
        email,
        (response) => {
          if (response?.data?.status === "success") {
            setValidToken(true);
            setIsLoading(false);
          } else {
            setValidToken(false);
            setIsLoading(false);
          }
        },
        (error) => {
        }
      );
    }
  }

  useEffect(() => {
    checkToken(token, email);
  }, []);

  if (isLoading || isDomainLoading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <Div
      sx={{
        width: 720,
        maxWidth: "100%",
        margin: "auto",
        p: 4,
      }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Card
        sx={{
          display: "flex",
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent
          sx={{
            flex: "0 1 300px",
            position: "relative",
            background: `#0267a0 url(${getAssetPath(
              `${ASSET_IMAGES}/widgets/login-page.jpg`,
              "640x428"
            )}) no-repeat center`,
            backgroundSize: "cover",

            "&::after": {
              display: "inline-block",
              position: "absolute",
              content: `''`,
              inset: 0,
              backgroundColor: "rgb(27 32 62 / 85%)",
            },
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              color: "common.white",
              position: "relative",
              zIndex: 1,
              height: "100%",
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={"h3"}
                color={"inherit"}
                fontWeight={500}
                mb={3}
              >
                Sign In
              </Typography>
              <Typography variant={""} mb={2}>
                <h4>{t("pages.title.quoteday")}</h4>
                <p>{randomQuote}</p>
              </Typography>
              {/* <Typography variant={"body1"}>
                                <MyLink
                                    style={{color:'yellow'}}
                                    to={"/forget-password"}
                                    color={"inherit"}
                                    underline={'none'}
                                >{t('pages.title.forgotpassword')}
                                </MyLink>
                            </Typography> */}
            </Div>

            <Div sx={{ mt: "auto", textAlign: "center" }}>
              <Link href="#" underline="none" sx={{ display: "inline-flex" }}>
                <img
                  src={
                    companyData && companyData.logo
                      ? companyData.logo_val
                      : `${ASSET_IMAGES}/logo-white.png`
                  }
                  style={{ width: "150px" }}
                  alt=""
                />
              </Link>
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          {validToken === true ? (
            <Alert severity="success">
              {t("pages.title.verifiedaccount")}{" "}
            </Alert>
          ) : validToken === false ? (
            <Alert severity="error">{t("pages.title.invalid")} </Alert>
          ) : null}
          <Formik
            validateOnChange={true}
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              onSignIn(data.username, data.password, setSubmitting);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                {serverError?.message && (
                  <Alert severity="error">{serverError.message}</Alert>
                )}
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboTextField fullWidth name="username" label="Username" />
                </Div>
                <Div sx={{ mt: 1, mb: 2 }}>
                  <JumboTextField
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                  />
                </Div>
                <Div sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Remember me"
                  />
                </Div>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}
                >
                  {t("pages.title.signin")}
                </LoadingButton>
                {/* <Div sx={{mb: 2}}>
                                    <Typography variant="p">Don't have an account? <MyLink style={{color:'#ab90f2'}} component="Link" to="/signup">Signup</MyLink>
</Typography>
                                </Div> */}
                {disableSmLogin && (
                  <React.Fragment>
                    <Typography variant={"body1"} mb={2}>
                      {t("pages.title.signwith")}
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      mb={1}
                    >
                      <IconButton
                        sx={{
                          bgcolor: "#385196",
                          color: "common.white",
                          p: (theme) => theme.spacing(1.25),

                          "&:hover": {
                            backgroundColor: "#385196",
                          },
                        }}
                        aria-label="Facebook"
                      >
                        <Facebook fontSize="small" />
                      </IconButton>
                      <IconButton
                        sx={{
                          bgcolor: "#00a8ff",
                          color: "common.white",
                          p: (theme) => theme.spacing(1.25),

                          "&:hover": {
                            backgroundColor: "#00a8ff",
                          },
                        }}
                        aria-label="Twitter"
                      >
                        <Twitter fontSize="small" />
                      </IconButton>
                      <IconButton
                        sx={{
                          bgcolor: "#23272b",
                          color: "common.white",
                          p: (theme) => theme.spacing(1.25),

                          "&:hover": {
                            backgroundColor: "#23272b",
                          },
                        }}
                        aria-label="Twitter"
                      >
                        <Google fontSize="small" />
                      </IconButton>
                    </Stack>
                  </React.Fragment>
                )}
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Div>
  );
};

export default Login;
