import React from 'react';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import CardIconText from "@jumbo/shared/CardIconText";
import EuroIcon from '@mui/icons-material/Euro';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';


import { useTranslation } from "react-i18next";



const Ideas = ({ dashboardApiData }) => {
    const { t } = useTranslation();
    
    return (
        <CardIconText
            icon={<GroupAddIcon fontSize={"large"} />}
            onHoverIcon={<OfflineBoltOutlinedIcon fontSize={"large"} />}
            title={dashboardApiData?.users?.affiliate === 0 ? "0" : dashboardApiData?.users?.affiliate}
            subTitle={t('widgets.subheader.ideas')}
        />
    );
};

export default Ideas;
