import React, { useEffect } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { CircularProgress, Grid, List } from "@mui/material";
import { useState } from "react";
import { getsettingsdata, updatesettingdata } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { t } from "i18next";

const validationSchema = yup.object({
  payout_fee: yup.number().required("Widthdrawal fee is required"),
  payout_date: yup
    .date()
    .typeError("Payout date must be a valid date")
    .required("Payout date is required"),
  payout_flat_fee: yup.number().required("Payout Flat Fee required"),
  payout_percentage_fee: yup.number().required("Payout Percentage Fee"),
});

const Updatewithdrawal = () => {
  const [payoutdata, setPayoutData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const fetchpayoutdata = () => {
    const params = {
      keynames:
        "'payout_fee', 'payout_date', 'payout_flat_fee', 'payout_percentage_fee'",
    };
    getsettingsdata(params, (response) => {
      setPayoutData(response?.data?.data?.values);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    });
  };

  useEffect(() => {
    fetchpayoutdata();
  }, []);

  const handleSubmit = (data, setSubmitting) => {
    const newData = {
      obj: {
        payout_fee: data.payout_fee,
        payout_date: data.payout_date,
        payout_flat_fee: data.payout_flat_fee,
        payout_percentage_fee: data.payout_percentage_fee,
      },
    };
    updatesettingdata(
      newData,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
        }
      },
      (error) => {}
    );
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={t("pages.title.withdrawal")} noWrapper>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {isLoading ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%",
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
            <List disablePadding sx={{ mb: 2 }}>
              <Formik
                validateOnChange={true}
                initialValues={{
                  payout_fee: payoutdata[0].keyvalue,
                  payout_date: payoutdata[1].keyvalue,
                  payout_flat_fee: payoutdata[2].keyvalue,
                  payout_percentage_fee: payoutdata[3].keyvalue,
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                  setSubmitting(true);
                  handleSubmit(data, setSubmitting);
                }}
              >
                {({ isSubmitting }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="payout_fee"
                        label="With Drawal Fee"
                        type="text"
                      />
                    </Div>

                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="payout_date"
                        label="Payout Date"
                        type="date"
                      />
                    </Div>

                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="payout_flat_fee"
                        label="Pay Out Flat"
                        type="text"
                      />
                    </Div>

                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="payout_percentage_fee"
                        label="Payout Percentage Fee"
                        type="text"
                      />
                    </Div>

                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        {t("pages.title.submit")}
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          )}
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default Updatewithdrawal;
