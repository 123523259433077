import React, { useContext, useState } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import Divider from "@mui/material/Divider";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useTranslation } from "react-i18next";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BadgeIcon from "@mui/icons-material/Badge";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import CodeIcon from "@mui/icons-material/Code";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import FolderZipIcon from "@mui/icons-material/FolderZip";

const CurrentPayOutDetails = ({
  setCurrentPayoutDetails,
  setUpdatePayoutInformationOpen,
  rowData,
}) => {
  const { t } = useTranslation();
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const [loader, setLoader] = useState(false);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const updateDetails = () => {
    setCurrentPayoutDetails(false);
    setUpdatePayoutInformationOpen(true);
  };

  const send_request = () => {
    setLoader(true);
  };

  return (
    <JumboCardQuick noWrapper>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <List disablePadding>
        {rowData?.bank_account_title === null &&
        rowData?.outside_bank_account_title === null ? (
          <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
            <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
              <AccountBalanceWalletIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                t("pages.title.ba_wallet_address") +
                ": " +
                rowData?.wallet_address
              }
            />
          </ListItem>
        ) : rowData?.wallet_address === null &&
          rowData?.outside_bank_account_title === null ? (
          <>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.ba_country") +
                  ": " +
                  rowData?.bank_account_country
                }
              />
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <BadgeIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.ba_full_name") +
                  ": " +
                  rowData?.bank_account_title
                }
              />
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <AccountBalanceWalletIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.ba_iban") +
                  ": " +
                  rowData?.bank_account_iban?.toUpperCase()
                }
              />
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.ba_bic") +
                  ": " +
                  rowData?.bank_account_bic?.toUpperCase()
                }
              />
            </ListItem>

            <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.p_address") +
                  ": " +
                  rowData?.bank_account_address
                }
              />
            </ListItem>

            <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <LocationCityIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.ba_city") + ": " + rowData?.bank_account_city
                }
              />
            </ListItem>

            <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <FolderZipIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.ba_zip_code") +
                  ": " +
                  rowData?.bank_account_zip_code
                }
              />
            </ListItem>

            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.p_country") + ": " + rowData?.payout_country
                }
              />
            </ListItem>
          </>
        ) : (
          <>
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.ba_country") +
                  ": " +
                  rowData?.outside_bank_account_country
                }
              />
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <BadgeIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.ba_full_name") +
                  ": " +
                  rowData?.outside_bank_account_title
                }
              />
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <AccountBalanceWalletIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.ba_number") +
                  ": " +
                  rowData?.outside_bank_account_number
                }
              />
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.ba_swift_code") +
                  ": " +
                  rowData?.outside_bank_account_swift_code
                }
              />
            </ListItem>

            <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <AltRouteIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.ba_routing") +
                  ": " +
                  rowData?.outside_bank_account_routing
                }
              />
            </ListItem>

            <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.p_address") +
                  ": " +
                  rowData?.outside_bank_account_address
                }
              />
            </ListItem>

            <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <LocationCityIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.ba_city") +
                  ": " +
                  rowData?.outside_bank_account_city
                }
              />
            </ListItem>

            <ListItem sx={{ p: (theme) => theme.spacing(1.25, 3) }}>
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <FolderZipIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.ba_zip_code") +
                  ": " +
                  rowData?.outside_bank_account_zip_code
                }
              />
            </ListItem>

            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(1.25, 3) }}
            >
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  t("pages.title.p_country") +
                  ": " +
                  rowData?.outside_payout_country
                }
              />
            </ListItem>
          </>
        )}
        <Divider component="li" />
        {/* <Box sx={{ padding: '10px' }}>
          <Typography sx={{ color: 'red' }} variant='p'>{t('pages.title.payment_info')}</Typography>
          <br />
          <br />
          <Typography sx={{ color: 'red' }} variant='p'>{t('pages.title.payout_info')}</Typography>
        </Box> */}
        <Box sx={{ textAlign: "center", padding: "10px" }}>
          <LoadingButton
            fullWidth
            type="button"
            variant="contained"
            size="large"
            sx={{ mb: 3 }}
            onClick={updateDetails}
            loading={loader}
          >
            {t("pages.title.update_payout")}
          </LoadingButton>
        </Box>
      </List>
    </JumboCardQuick>
  );
};

export default CurrentPayOutDetails;
