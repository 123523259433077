import React, { useEffect } from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { CircularProgress, Grid, List } from "@mui/material";
import { useState } from 'react';
import { getAdminLogs } from 'backendServices/ApiCalls';
import { t } from 'i18next';
import Div from '@jumbo/shared/Div';



const UserLogs = () => {
    const [logs, setLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchpayoutdata = () => {
        let params = {
            type: "user"
        }
        setIsLoading(true)
        getAdminLogs(params, (response) => {
            setLogs(response?.data?.data);
            setIsLoading(false)
        });
    };


    useEffect(() => {
        fetchpayoutdata();
    }, []);

    function formatSimpleDate(dateString) {
        const originalDate = new Date(dateString);
        const formattedDate = originalDate.toISOString().slice(0, 19).replace('T', ' ');
        return formattedDate;
    }

    if (isLoading) {
        return <Div
            sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                alignContent: 'center',
                height: '100%',
            }}
        >
            <CircularProgress sx={{ m: '-40px auto 0' }} />
        </Div>
    }


    return (
        <Grid container fullWidth sm={12} xs={12}>
            <Grid item sm={12} xs={12}>
                <JumboCardQuick title={t('sidebar.menuItem.userlogs')}>
                    <List disablePadding sx={{ mb: 2 }}>
                        <ul>
                            {logs?.map((log, index) => (
                                <>
                                    <li key={index}>{log?.message}
                                        <br></br>
                                        {formatSimpleDate(log?.timestamp)}
                                    </li>
                                    <br></br>
                                </>
                            ))}
                        </ul>
                    </List>
                </JumboCardQuick>
            </Grid>
        </Grid>
    );

};

export default UserLogs;
