import React, { useEffect } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Box, CircularProgress, Grid, List } from "@mui/material";
import { useState } from "react";
import { autocouponApi, getnovafreeranksApi } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  coupon1: yup.string().required("Coupon is required"),
  coupon2: yup.string().required("Coupon is required"),
  transaction_password: yup
    .mixed()
    .required("Transaction Password is required"),
  coupon3: yup.string().required("Coupon is required"),
});

const AutoCoupon = () => {
  const { t } = useTranslation();
  const [couponList, setCouponList] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const getnovafreeranks = () => {
    getnovafreeranksApi((response) => {
      setCouponList(response?.data?.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getnovafreeranks();
  }, [update]);

  const handleSubmit = (data, setSubmitting, resetForm) => {
    const params = {
      obj: {
        NovaFree: data.coupon1,
        "NovaFree Plus": data.coupon2,
        "SuperNova Free": data.coupon3,
        admin_transaction_password: data.transaction_password,
      },
    };
    autocouponApi(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setUpdate(true);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setUpdate(false);
          setSubmitting(false);
        }
      },
      (error) => {
      }
    );
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={8} xs={12}>
        <JumboCardQuick title={t("pages.title.auto-coupon")} noWrapper>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {isLoading ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%",
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
            <List disablePadding sx={{ mb: 2 }}>
              <Formik
                validateOnChange={true}
                initialValues={{
                  nova_rank_one: couponList[0]?.name,
                  coupon1: couponList[0]?.coupon,
                  nova_rank_two: couponList[1]?.name,
                  coupon2: couponList[1]?.coupon,
                  nova_rank_three: couponList[2]?.name,
                  transaction_password: "",
                  coupon3: couponList[2]?.coupon,
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  handleSubmit(data, setSubmitting, resetForm);
                }}
              >
                {({ isSubmitting, setFieldValue }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container fullWidth spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <JumboTextField
                            fullWidth
                            name="nova_rank_one"
                            label="Novafree Rank"
                            type="text"
                            // value={currentBalance}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <JumboTextField
                            fullWidth
                            name="coupon1"
                            label="Coupon"
                            type="string"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <JumboTextField
                            fullWidth
                            name="nova_rank_two"
                            label="Novafree Rank"
                            type="text"
                            // value={currentBalance}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <JumboTextField
                            fullWidth
                            name="coupon2"
                            label="Coupon"
                            type="string"
                          ></JumboTextField>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <JumboTextField
                            fullWidth
                            name="nova_rank_three"
                            label="Novafree Rank"
                            type="text"
                            // value={currentBalance}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <JumboTextField
                            fullWidth
                            name="coupon3"
                            label="Coupon"
                            type="string"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="transaction_password"
                        label="Transaction Password"
                        type="password"
                      />
                    </Div>

                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        {t("pages.title.submit")}
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          )}
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default AutoCoupon;
