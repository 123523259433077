import React, { useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import {
  Checkbox,
  Grid,
  ListItemText,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { updateminiadmin } from "backendServices/ApiCalls";
import { routesForAuthenticatedOnly } from "../../../routes";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import json5 from "json5";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationSchema = yup.object({
  username: yup.string().required("User Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  firstname: yup.string().required("First Name is required"),
  lastname: yup.string().required("Last Name is required"),
  admin_transaction_password: yup
    .string()
    .required("Admin Transaction Password is required"),
    routenames: yup.array().min(3, "At least one route must be selected").required("User roles are required"),
});

function UpdateMiniAdmin() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const filteredRoutes = routesForAuthenticatedOnly.filter(
    (route) => route.path !== "/logout" && route.path !== "/404"
  );
  const location = useLocation();
  const userData = location.state?.rowData;
  
  const [currentRoutes, setCurrentRoutes] = useState(
    userData?.allowedroutes || []
  );

  let cleanedArray;
  if (typeof currentRoutes === "string") {
    let arrayData = json5.parse(currentRoutes);

    if (arrayData[0].includes("[")) {
      arrayData = arrayData.map((element) => element.replace(/[\[\]"]/g, ""));
      arrayData = [...arrayData[0].split(",")];
    }

    cleanedArray = arrayData;
  }

  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [routenames, setRoutenames] = useState(cleanedArray);
  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;

  //   const updatedRoutes = value;
  //   setRoutenames(updatedRoutes);
  //   setCurrentRoutes(updatedRoutes);
  // };

  const handleCustomChange = (event, setFieldValue, values) => {
    const { value } = event.target;
    let newValues = [...value];
  
    // Handle '/' selection
    if (newValues.includes('/') && !values.routenames.includes('/dashboard')) {
      newValues = Array.from(new Set([...newValues, '/dashboard']));
    } else if (!newValues.includes('/') && values.routenames.includes('/dashboard')) {
      newValues = newValues.filter((val) => val !== '/dashboard');
    }
  
    // Handle '/dashboard' selection
    if (newValues.includes('/dashboard') && !values.routenames.includes('/')) {
      newValues = Array.from(new Set([...newValues, '/']));
    } else if (!newValues.includes('/dashboard') && values.routenames.includes('/')) {
      newValues = newValues.filter((val) => val !== '/');
    }
  
    setFieldValue('routenames', newValues);
  };

  const handleSubmit = (data, setSubmitting, resetForm) => {
    setSubmitting(true);
    // if (routenames.length < 1) {
    //   setalertData({
    //     show: true,
    //     message: "User roles are required",
    //     variant: "warning",
    //   });
    //   setSubmitting(false);
    // } else {
      const routesJSONString = JSON.stringify(data.routenames);
      let params = {
        userid: userData.id,
        username: data.username,
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        admin_transaction_password: data.admin_transaction_password,
        allowedroutes: routesJSONString,
      };
      updateminiadmin(
        params,
        (response) => {
          if (response?.data?.status === "error") {
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "error",
            });
            navigate("/manage-admin");
          } else if (response?.data?.status === "success") {
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "success",
            });
            resetForm();
            navigate("/manage-admin");
          } else {
            setalertData({
              show: true,
              message: "Something went wrong, please try again later.",
              variant: "error",
            });
            setRoutenames(["/logout", "/404"]);
          }
          setSubmitting(false);
        },
        (error) => {
          setSubmitting(false);
        }
      );
    // }
  };

  return (
    <Grid
      container
      spacing={2}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <Grid item sm={7}>
        <JumboDemoCard
          title={t("pages.title.updateadmin")}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          <Formik
            initialValues={{
              username: userData?.username || "",
              email: userData?.email || "",
              firstname: userData?.firstname || "",
              lastname: userData?.lastname || "",
              admin_transaction_password: "",
              routenames:userData?.allowedroutes,
              // ... other initial values ...
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
              
              handleSubmit(data, setSubmitting, resetForm);
            }}
            validateOnChange={true}  // Validate fields on each change
            validateOnBlur={true}    // Validate fields on blur
            validateOnMount={true}   // Validate fields as soon as the form mounts
          >
            {({ errors, touched, isSubmitting, handleChange,setFieldValue, values }) => (
              <Form
                style={{ textAlign: "left", width: "100%" }}
                noValidate
                autoComplete="off"
              >
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="username"
                        label="User Name"
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="email"
                        label="Email"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="firstname"
                        label="First Name"
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="lastname"
                        label="Last Name"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid item xs={12}>
                    <JumboTextField
                      fullWidth
                      name="admin_transaction_password"
                      label="Admin Transaction Password"
                      type="password"
                    />
                  </Grid>
                </Box>

                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid item xs={12}>
                    <FormControl sx={{ mb: 1, width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        {t("pages.title.selectroles")}
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        fullWidth
                        value={Array.isArray(values.routenames) ? values.routenames : JSON.parse(values.routenames)}
                        name="routenames" 
                        onChange={(event) => handleCustomChange(event, setFieldValue, values)}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(", ")}// Ensure `selected` is always an array
                        MenuProps={MenuProps}
                      >
                        {filteredRoutes.map((route) => (
                          <MenuItem key={route.path} value={route.path}>
                            <Checkbox
                              checked={values.routenames.indexOf(route.path) > -1}
                            />
                            <ListItemText primary={route.path} />
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.routenames && touched.routenames && (
                        
                          <Typography
                            variant="body2"
                            color="error"
                            sx={{
                              fontFamily: 'NoirPro, Arial',
                              fontWeight: 400,
                              fontSize: '0.75rem',
                              lineHeight: 1.66,
                              textAlign: 'left',
                              mt: '3px',
                              mr: '14px',
                              mb: 0,
                              ml: '14px',
                            }}
                          >
                            {errors.routenames}
                          </Typography>

                        )}
                    </FormControl>
                  </Grid>

                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    {t("pages.title.submit")}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
}

export default UpdateMiniAdmin;
