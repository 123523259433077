import React from 'react';
import { Button, Typography } from "@mui/material";
import CardIconText from "@jumbo/shared/CardIconText";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Div from '@jumbo/shared/Div/Div';
import { Link as   MyLink , useNavigate, useParams } from "react-router-dom";


const ObjectCountRevenue = ({ vertical, title, value, icon, color, dashboardApiData, navTo }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <CardIconText
            sx={{ pb: 2 }}
            icon={icon}
            // title={<Typography variant={"p"} color={color}>{value}</Typography>}
            subTitle={<>
                <Div sx={{ mt: 2, fontSize: "20px" }}>
                    <Typography variant={"p"} color={color}>{dashboardApiData}</Typography>
                </Div>
                <Div sx={{ pb: 2, mt: 2 }}>
                    <Typography variant={"p"}
                        color={"text.primary"}>{t('widgets.subheader.' + title)}
                    </Typography>
                </Div>
                <Div>
                    <Button size='small' variant="contained" onClick={() => navigate(navTo)}>{t('pages.title.view')}</Button>
                </Div>
            </>
            }
            color={color}
            disableHoverEffect={true}
            hideArrow={true}
            variant={"outlined"}
        />
    );
};
/* Todo vertical prop define */
ObjectCountRevenue.propTypes = {
    vertical: PropTypes.bool,
};
export default ObjectCountRevenue;
