import React, { useContext } from "react";
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import { ASSET_IMAGES } from "../../utils/constants/paths";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import ChangeFavIcon from "../../pages/dashboard/Crypto/ChangeFavIcon";

const Logo = ({ mini, mode, sx }) => {
  const { loginUserData } = useContext(CustomProvider);
  return (
    <Div
      sx={{
        marginLeft: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <Link href={"/dashboard"}>
        {(loginUserData && loginUserData.usertype === "reseller") ||
        (loginUserData.usertype === "admin" && loginUserData.parent_id > 0) ? (
          <img
            src={
              mode === "light"
                ? loginUserData.profilepictureurl
                : loginUserData.profilepictureurl
            }
            style={{ width: "80px" }}
            alt="Novalya"
          />
        ) : !mini ? (
          <img
            className="logo"
            src={
              mode === "light"
                ? `${ASSET_IMAGES}/logo.png`
                : `${ASSET_IMAGES}/logo-white.png`
            }
            style={{ width: "80px" }}
            alt="Novalya"
          />
        ) : (
          <img
            src={
              mode === "dark"
                ? `${ASSET_IMAGES}/logo-short.png`
                : `${ASSET_IMAGES}/logo-short-white.png`
            }
            style={{ width: "80px" }}
            alt="Novalya"
          />
        )}
      </Link>
      <ChangeFavIcon />
    </Div>
  );
};

Logo.defaultProps = {
  mode: "light",
};

export default Logo;
