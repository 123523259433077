import React, { useEffect } from 'react';
import { enUS, frFR, arEG, zhCN, esES, itIT } from '@mui/material/locale';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
const languages = [
    {
        label: "English",
        locale: "en-US",
        muiLocale: enUS
    },
    {
        label: "French",
        locale: "fr-FR",
        muiLocale: frFR
    },
];

const LocalizationOptions = () => {
    const { i18n } = useTranslation();
    const { theme, setMuiLocale, setTheme } = useJumboTheme();
    const [activeLocale, setActiveLocale] = React.useState(languages[0]);

    useEffect(() => {
        // Load the selected language from localStorage on component mount
        const storedLocale = localStorage.getItem('selectedLocale');
        if (storedLocale) {
            const storedLanguage = languages.find(language => language.locale === storedLocale);
            if (storedLanguage) {
                setActiveLocale(storedLanguage);
                setMuiLocale(storedLanguage.muiLocale);
                i18n.changeLanguage(storedLanguage.locale);
            }
        }
    }, []);

    const handleChange = (event) => {
        const localeIndex = languages.findIndex(language => language.locale === event.target.value);
        if (localeIndex !== -1) {
            i18n.changeLanguage(languages[localeIndex].locale).then(() => {
                setActiveLocale(languages[localeIndex]);
                setMuiLocale(languages[localeIndex].muiLocale);
                // Save the selected language to localStorage
                localStorage.setItem('selectedLocale', languages[localeIndex].locale);
            });
        }
    };

    return (

        <FormControl fullWidth>
            <InputLabel id="customizer-select-locale-label">Language</InputLabel>
            <Select
                size={"small"}
                labelId="select-locale"
                id="customizer-select-locale"
                value={activeLocale?.locale}
                label="Language"
                onChange={handleChange}
            >
                {
                    languages?.map(language => (
                        <MenuItem key={language?.locale}
                            value={language?.locale}>{language?.label}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};

export default LocalizationOptions;
