import React from "react";
import Crypto from "app/pages/dashboard/Crypto/Crypto";
import Page from "@jumbo/shared/Page";
import Login from "app/pages/auth-pages/login/Login";
import Logout from "app/pages/auth-pages/logout/Logout";
import ForgotPassword from "app/pages/auth-pages/forgot-password/ForgotPassword";
import ResetPassword from "app/pages/auth-pages/reset-password/ResetPassword";
import BinaryTree from "app/pages/treeview/BinaryTree";
import CreateAdmin from "app/pages/mini-admin/create/createadmin";
import ManageAdmin from "app/pages/mini-admin/manage/manageadmin";
import Error404 from "app/pages/Error404/Error404";
import Updatewithdrawal from "app/pages/settings/updatewithdrawal/Updatewithdrawal";
import Managezoom from "app/pages/settings/managezoom/Managezoom";
import Managecommission from "app/pages/settings/managecommission/Managecommission";
import Manageunilevelbonus from "app/pages/settings/manageunilevelbonus/Manageunilevelbonus";
import Addnews from "app/pages/news/addnews/Addnews";
import Managenews from "app/pages/news/managenews/Managenews";
import Updateuserbalance from "app/pages/updateuserbalance/Updateuserbalance";
import KYCPending from "app/pages/kycreports/pending/KYCPending";
import Approvedkyc from "app/pages/kycreports/approved/Approvedkyc";
import Rejected from "app/pages/kycreports/rejected/Rejectedkyc";
import Payoutdetailpending from "app/pages/payoutdetailrequest/payoutdetailpending/Payoutdetailpending";
import Payoutdetailapproved from "app/pages/payoutdetailrequest/payoutdetailapproved/Payoutdetailapproved";
import Payoutdetailrejected from "app/pages/payoutdetailrequest/payoutdetailrejected/Payoutdetailrejected";
import Subscriptionreport from "app/pages/reports/subscriptionsreport/Subscriptionreport";
import Affiliatereport from "app/pages/reports/affiliatereport/Affiliatereport";
import Updateloginpassword from "app/pages/userlist/updateloginpassword/Updateloginpassword";
import Updatetransactionpassword from "app/pages/userlist/updatetransactionpassword/Updatetransactionpassword";
import Updateuserpassword from "app/pages/userlist/updateuserpassword/Updateuserpassword";
import Userlisttable from "app/pages/userlist/getuserslist/Userlisttable";
import Rankreport from "app/pages/reports/rankreport/Rankreport";
import Pendingpayout from "../pages/payout/pendingpayout/Pendingpayout";
import Approvedpayout from "app/pages/payout/approvedpayout/Approvedpayout";
import Processingpayout from "app/pages/payout/processingpayout/Processingpayout";
import AutoCoupon from "app/pages/autocoupon/AutoCoupon";
import PendingResiduelReport from "app/pages/reports/pendingResiduel/PendingResiduelReport";
import MonthlyReport from "app/pages/reports/previousMonthRecord/MonthlyReport";
import BinaryPoints from "app/pages/reports/binaryPoints/BinaryPoints";
import Managecolumn from "app/pages/managecolumn/Managecolumn";
import Adddeductbinarypoints from "app/pages/add-deduct-binarypoints/Adddeductbinarypoints";
import UpdatePersonalInfo from "app/pages/settings/updatepersonalinformation/UpdatePersonalInfo";
import NovaRankreport from "app/pages/reports/novarankreport/NovaRankreport";
import BinaryDetails from "app/pages/userlist/binarydetails/BinaryDetails";
import AdminLogs from "app/pages/adminlogs/AdminLogs";
import UserLogs from "app/pages/userlogs/UserLogs";
import AccountSetting from "app/pages/account-setting";
import PlanLimits from "app/pages/planlimits/PlanLimits";
import UpdateUserPlanLimits from "app/pages/settings/userplanlimits/UpdateUserPlanLimits";
import UpdateUserunilevel from "app/pages/settings/userunilevelcommission/UpdateUserunilevel";
import ManageUnilevel from "app/pages/settings/manageunilevels/ManageUnilevel";
import ManagePool from "app/pages/settings/managepool/ManagePool";
import unilevel from "app/pages/reports/unilevel";
import PoolDistribution from "app/pages/reports/pooldistribution/PoolDistribution";
import PoolReports from "app/pages/reports/poolreports/PoolReports";
import ConversionRate from "app/pages/settings/conversionrate/ConversionRate";
import LevelBonusDeducted from "../pages/reports/levelbonusdeducted/LevelBonusDeducted";
import ChangeSponsor from "app/pages/userlist/chnagesponsor/ChangeSponsor";
import BinaryPointsData from "app/pages/BinaryPoints/BinaryPoints";
import UpdateMiniAdmin from "app/pages/mini-admin/manage/UpdateMiniAdmin";
import UnlockUser from "app/pages/userlist/unlockuser/UnlockUser";
import UserSales from "app/pages/dashboard/Crypto/UserSales";
/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/

// gray lable 
import ManageGrayLable from "app/pages/gray-lable/manage/listing";
import CreateGrayLabel from "app/pages/gray-lable/create/createadmin";
import EditGrayLabel from "app/pages/gray-lable/manage/edit";
import PoolReport from "app/pages/reports/pool-report/PoolReport";
import PendingComission from "app/pages/commession/PendingComission";
import UnpaidCommission from "app/pages/commession/UnpaidCommission";
import TrialUsers from "app/pages/userlist/trialusers/TrialUsers";
import ManageConnection from "app/pages/userlist/manageconnection/ManageConnection";
import CurrentMonthPay from "app/pages/commission-details/CurrentMonthPay";
import PendingPay from "app/pages/commission-details/PendingPay";

const routesForPublic = [



];

/**
 routes only accessible to authenticated users
 **/

const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Page component={Crypto} />
    },
    {
        path: "/dashboard",
        element: <Page component={Crypto} />
    },
    {
        path: "/users-list",
        element: <Page component={Userlisttable} />
    },
    {
        path: "/unlock-user",
        element: <Page component={UnlockUser} />
    },
    {
        path: "/user-sales",
        element: <Page component={UserSales} />
    },
    {
        path: "/admin-logs",
        element: <Page component={AdminLogs} />
    },
    {
        path: "/user-logs",
        element: <Page component={UserLogs} />
    },
    {
        path: "/current-month-pay",
        element: <Page component={CurrentMonthPay} />
    },
    {
        path: "/pending-pay",
        element: <Page component={PendingPay} />
    },
    {
        path: "/users-list-binary-details",
        element: <Page component={BinaryDetails} />
    },
    {
        path: "/create-admin",
        element: <Page component={CreateAdmin} />
    },
    {
        path: "/manage-admin",
        element: <Page component={ManageAdmin} />
    },
    {
        path: "/pending-commission",
        element: <Page component={PendingComission} />
    },
    {
        path: "/unpaid-commission",
        element: <Page component={UnpaidCommission} />
    },
    {
        path: "/update-mini-admin",
        element: <Page component={UpdateMiniAdmin} />
    },
    {
        path: "/plan-limits",
        element: <Page component={PlanLimits} />
    },
    {
        path: "/update-user-plan-limits",
        element: <Page component={UpdateUserPlanLimits} />
    },
    {
        path: "/manage-user-unilevel",
        element: <Page component={UpdateUserunilevel} />
    },
    {
        path: "/change-sponsor",
        element: <Page component={ChangeSponsor} />
    },
    {
        path: "/binary-tree",
        element: <Page component={BinaryTree} />
    },
    {
        path: "/binary-points-data",
        element: <Page component={BinaryPointsData} />
    },
    {
        path: "/add-coupon",
        element: <Page component={AutoCoupon} />
    },
    // {
    //     path: "/manage",
    //     element: <Page component={Manage} />
    // },
    {
        path: "/kyc-pending",
        element: <Page component={KYCPending} />
    },
    {
        path: "/kyc-approved",
        element: <Page component={Approvedkyc} />
    },
    {
        path: "/kyc-rejected",
        element: <Page component={Rejected} />
    },
    {
        path: "/affiliate-list-details",
        element: <Page component={BinaryPoints} />
    },
    {
        path: "/conversion-rate",
        element: <Page component={ConversionRate} />
    },
    {
        path: "/payout-detail-pending",
        element: <Page component={Payoutdetailpending} />
    },
    {
        path: "/payout-detail-approved",
        element: <Page component={Payoutdetailapproved} />
    },
    {
        path: "/payout-detail-rejected",
        element: <Page component={Payoutdetailrejected} />
    },
    {
        path: "/pending-residuel-report",
        element: <Page component={PendingResiduelReport} />
    },
    {
        path: "/subscription-report",
        element: <Page component={Subscriptionreport} />
    },
    {
        path: "/affiliate-report",
        element: <Page component={Affiliatereport} />
    },
    {
        path: "/monthly-report",
        element: <Page component={MonthlyReport} />
    },
    {
        path: "/rank-report",
        element: <Page component={Rankreport} />
    },
    {
        path: "/trial-users",
        element: <Page component={TrialUsers} />
    },
    {
        path: "/nova-rank-report",
        element: <Page component={NovaRankreport} />
    },
    // {
    //     path: "/payoutreport",
    //     element: <Page component={Payoutreport} />
    // },
    {
        path: "/pending-payout",
        element: <Page component={Pendingpayout} />
    },
    {
        path: "/approved-payout",
        element: <Page component={Approvedpayout} />
    },
    {
        path: "/processing-payout",
        element: <Page component={Processingpayout} />
    },
    {
        path: "/update-login-password",
        element: <Page component={Updateloginpassword} />
    },
    {
        path: "/update-transaction-password",
        element: <Page component={Updatetransactionpassword} />
    },
    {
        path: "/update-user-password",
        element: <Page component={Updateuserpassword} />
    },
    {
        path: "/manage-column",
        element: <Page component={Managecolumn} />
    },
    {
        path: "/manage-payout",
        element: <Page component={Updatewithdrawal} />
    },
    {
        path: "/add-deduct-binarypoints",
        element: <Page component={Adddeductbinarypoints} />
    },
    {
        path: "/manage-zoom",
        element: <Page component={Managezoom} />
    },
    {
        path: "/manage-commission",
        element: <Page component={Managecommission} />
    },
    {
        path: "/manage-connections",
        element: <Page component={ManageConnection} />
    },
    {
        path: "/manage-unilevel-bonus",
        element: <Page component={Manageunilevelbonus} />
    },
    {
        path: "/unilevel",
        element: <Page component={ManageUnilevel} />
    },
    {
        path: "/unilevel-reports",
        element: <Page component={unilevel} />
    },
    {
        path: "/pool-reports",
        element: <Page component={PoolReports} />
    },
    {
        path: "/pool-distribution",
        element: <Page component={PoolDistribution} />
    },
    {
        path: "/manage-pool",
        element: <Page component={ManagePool} />
    },
    {
        path: "/level-bonus-deducted",
        element: <Page component={LevelBonusDeducted} />,
      },
    {
        path: "/update-user-balance",
        element: <Page component={Updateuserbalance} />
    },
    {
        path: "/update-user-personal-info",
        element: <Page component={UpdatePersonalInfo} />
    },
    {
        path: "/add-news",
        element: <Page component={Addnews} />
    },
    {
        path: "/manage-news",
        element: <Page component={Managenews} />
    },
    {
        path: "/logout",
        element: <Page component={Logout} />
    },
    {
        path: "/404",
        element: <Page component={Error404} />
    },
    {
        path: "/account-setting",
        element: <Page component={AccountSetting} />
    
    },
    {
        path: "/account-setting-new",
        element: <Page component={AccountSetting} />
    
    },


    // for gray lable program
    {
        path: "/gray-label",
        element: <Page component={ManageGrayLable} />
    },
    {
        path: "/create-new-gray-label",
        element: <Page component={CreateGrayLabel} />
    },{
        path: "/graylabel-edit/:user_id",
        element: <Page component={EditGrayLabel} />
    },
    {
        path: "/pool-report",
        element: <Page component={PoolReport} />
    }
   
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: "/login",
        element: <Page component={Login} />
    },
    {
        path: "/login/:token/:email",
        element: <Page component={Login} />
    },

    {
        path: "/forget-password",
        element: <Page component={ForgotPassword} />
    },
    {
        path: "/reset-password/:token/:email",
        element: <Page component={ResetPassword} />
    },
];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };