import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import Div from "@jumbo/shared/Div/Div";
import { CircularProgress } from "@mui/material";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { binarypointsreportApi } from "backendServices/ApiCalls";

const VISIBLE_FIELDS = ["sr", "sender", "amount", "date"];
const BinaryPoints = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [investmentdata, setInvestmentData] = useState([]);
  const location = useLocation();

  const user_name = location.state?.username || "";
  const user_id = location.state?.id || "";

  const BinaryPointsReportData = () => {
    setLoading(true);
    let params = {
      userid: user_id,
    };
    binarypointsreportApi(
      params,
      (response) => {
        setLoading(false);
        setInvestmentData(response?.data?.data);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    BinaryPointsReportData();
  }, []);

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const rows = investmentdata
    ? [
        {
          id: 1,
          username: user_name,
          lifeTimeEarning: investmentdata.lifeTimeEarning || 0,
          directActives: investmentdata.directActives || 0,
          l2Active: investmentdata.l2Active || 0,
          l1Personal: investmentdata.l1Personal || 0,
          l2: investmentdata.l2 || 0,
          total: investmentdata.total || 0,
          silver: investmentdata.silver || 0,
          gold: investmentdata.gold || 0,
          platinum: investmentdata.platinum || 0,
        },
      ]
    : [];

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "text.primary",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      editable: false,
    },
    {
      field: "username",
      headerName: "Username",
      width: 150,
      editable: false,
      renderCell: (params) => {
        const username = params.row.username;
        return <span>{username}</span>;
      },
    },
    {
      field: "lifeTimeEarning",
      headerName: "Life Time Earning",
      width: 170,
      editable: false,
      renderCell: (params) => {
        const lifeTimeEarning = params.row.lifeTimeEarning;
        const formattedLifeTimeEarning = parseFloat(lifeTimeEarning).toFixed(2);
        return <span>{formattedLifeTimeEarning}</span>;
      },
    },
    {
      field: "directActives",
      headerName: "Direct Actives",
      width: 150,
      editable: false,
    },
    {
      field: "l2Active",
      headerName: "L2 Active",
      width: 150,
      editable: false,
    },
    {
      field: "l1Personal",
      headerName: "L1 Personal",
      width: 150,
      editable: false,
    },
    {
      field: "l2",
      headerName: "L2",
      width: 150,
      editable: false,
    },
    {
      field: "total",
      headerName: "Total",
      width: 150,
      editable: false,
    },
    {
      field: "silver",
      headerName: "Silver",
      width: 150,
      editable: false,
    },
    {
      field: "gold",
      headerName: "Gold",
      width: 150,
      editable: false,
    },
    {
      field: "platinum",
      headerName: "Platinum",
      width: 150,
      editable: false,
    },
  ];
  columns[2].field = user_name;

  return (
    <JumboDemoCard
      title={t("pages.title.affiliate_report")}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Box sx={{ height: 460, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rows}
          columns={columns}
          // slots={{ toolbar: GridToolbar }}
          slots={{
            toolbar: (props) => (
              <GridToolbar
                {...props}
                sx={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#424242" : "#fff",
                  "& .MuiIconButton-root": {
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                  },
                }}
              />
            ),
          }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
};

export default BinaryPoints;
