import React, { useEffect, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { getSingleData, updateGrayLabel } from "backendServices/ApiCalls";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const validationSchema = yup.object({
  firstname: yup.string().required("First Name is required"),
  lastname: yup.string().required("Last Name is required"),
  phone: yup.string().required("Phone is required"),
  company_address: yup.string().required("Company address is required"),
});

function EditGrayLabel() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user_id } = useParams();

  useEffect(() => {
    getResellerData();
  }, []);

  const [resellerData, setResellerData] = useState(null);

  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  function getResellerData() {
    getSingleData(
      { user_id },
      (response) => {
        setResellerData(response?.data?.data);
      },
      (error) => {}
    );
  }

  const handleSubmit = (data, setSubmitting, resetForm) => {
    setSubmitting(true);

    let params = {
      id: user_id,
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone,
      company_address: data.company_address,
    };

    updateGrayLabel(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          navigate("/gray-label");
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          resetForm();
          navigate("/gray-label");
        } else {
          setalertData({
            show: true,
            message: "Something went wrong, please try again later.",
            variant: "error",
          });
          // setRoutenames(["/logout", "/404"]);
        }
        setSubmitting(false);
      },
      (error) => {
        setSubmitting(false);
      }
    );
  };

  return (
    <Grid
      container
      spacing={2}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <Grid item sm={7}>
        <JumboDemoCard
          title="Update Gray Label"
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              firstname: resellerData?.firstname || "",
              lastname: resellerData?.lastname || "",
              company_name: resellerData?.company || "",
              username: resellerData?.username || "",
              email: resellerData?.email || "",
              phone: resellerData?.mobile || "",
              company_address: resellerData?.address1 || "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
              handleSubmit(data, setSubmitting, resetForm);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="firstname"
                        label="First Name"
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="lastname"
                        label="Last Name"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="company_name"
                        label="Company Name"
                        type="text"
                        inputProps={{
                          readOnly: true,
                          sx: {
                            color: "text.disabled",
                            backgroundColor: "action.disabledBackground",
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="username"
                        label="User Name(*same for domain)"
                        type="text"
                        inputProps={{
                          readOnly: true,
                          sx: {
                            color: "text.disabled",
                            backgroundColor: "action.disabledBackground",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="email"
                        label="Email"
                        type="text"
                        inputProps={{
                          readOnly: true,
                          sx: {
                            color: "text.disabled",
                            backgroundColor: "action.disabledBackground",
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="phone"
                        label="Phone"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid item xs={12}>
                    <JumboTextField
                      fullWidth
                      name="company_address"
                      label="Company Address"
                      type="text"
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Box>

                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <JumboTextField
                        type="color"
                        name="favcolor"
                        label="Platform color"
                        defaultValue="#3FB337"
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
               <Grid item xs={12}>
                 <JumboTextField
                   fullWidth
                   name="admin_transaction_password"
                   label="Admin Transaction Password"
                   type="password"
                 />
               </Grid>
             </Box> */}

                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  {/* <Grid item xs={12}>
                 <FormControl sx={{ mb: 1, width: "100%" }}>
                   <InputLabel id="demo-simple-select-label">
                     {t("pages.title.selectroles")}
                   </InputLabel>
                   <Select
                     labelId="demo-multiple-checkbox-label"
                     id="demo-multiple-checkbox"
                     multiple
                     fullWidth
                     value={routenames}
                     onChange={handleChange}
                     input={<OutlinedInput label="Tag" />}
                     renderValue={(selected) => selected.join(", ")}
                     MenuProps={MenuProps}
                   >
                     {filteredRoutes.map((routes) => (
                       <MenuItem key={routes.path} value={routes.path}>
                         <Checkbox
                           checked={routenames.indexOf(routes.path) > -1}
                         />
                         <ListItemText primary={routes.path} />
                       </MenuItem>
                     ))}
                   </Select>
                 </FormControl>
               </Grid> */}

                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    {t("pages.title.submit")}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
}

export default EditGrayLabel;
