import React, { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboOverlay from "@jumbo/components/JumboOverlay";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import axios from "axios";

const Dashboardbgcitycard = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [userCountry, setUserCountry] = useState("");

  const isMountedRef = useRef(true); // Ref to track component mounted state

  useEffect(() => {
    isMountedRef.current = true;

    const interval = setInterval(() => {
      if (isMountedRef.current) {
        setCurrentTime(new Date());
      }
    }, 1000);

    const fetchUserCountry = async () => {
      try {
        const response = await axios.get("http://ip-api.com/json");
        if (isMountedRef.current) {
          setUserCountry(response?.data?.country);
        }
      } catch (error) {}
    };

    fetchUserCountry();

    return () => {
      isMountedRef.current = false; // Component is unmounted
      clearInterval(interval); // Cleanup the interval
    };
  }, []);

  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedDate = `${
    daysOfWeek[currentTime.getDay()]
  }, ${currentTime.getDate()} ${months[currentTime.getMonth()]}`;

  return (
    <JumboCardQuick
      noWrapper
      bgImage={getAssetPath(`${ASSET_IMAGES}/cities-bg.png`, "680x180")}
      sx={{ height: 125 }}
    >
      <JumboOverlay
        margin={24}
        opacity={0}
        vAlign={"center"}
        sx={{ color: "common.white" }}
      >
        <Stack
          direction={"row"}
          spacing={3}
          justifyContent={"space-around"}
          alignItems={"center"}
        >
          <div className={"Stack-item"}>
            <Typography variant={"body1"} fontSize={"12px"} mt={0.25}>
              {formattedDate}
            </Typography>
            <Typography
              variant={"h3"}
              fontSize={"33px"}
              color={"inherit"}
              mb={0}
            >
              {formattedTime}
            </Typography>
            <Typography variant={"body1"} fontSize={"12px"}>
              {userCountry}
            </Typography>
          </div>
        </Stack>
      </JumboOverlay>
    </JumboCardQuick>
  );
};

export default Dashboardbgcitycard;
