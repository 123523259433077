import React, { useEffect } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  List,
  TextField,
} from "@mui/material";
import { useState } from "react";
import {
  getusers,
  singleUserApi,
  updatesponsor,
} from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const validationSchema = yup.object({
  username: yup.string().required("Username is required"),
  current_sponsor: yup.string().required("Current Sponsor is required"),
  userid: yup.mixed().required("Sponsor Name is required"),
});

function ChangeSponsor() {
  const { t } = useTranslation();
  const [singleUser, setSingleUser] = useState({});
  const location = useLocation();
  const [userlist, setUserList] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [updateSponsor, setUpdateSponsor] = useState(false);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const user_id = location?.state?.user_id || "";
  const getSingleUserFunction = () => {
    let params = {
      userid: user_id,
    };
    setIsLoading(true);
    singleUserApi(params, (response) => {
      setSingleUser(response?.data?.data[0]);
      setIsLoading(false);
    });
  };

  const userslist = () => {
    let params = {
      status: "approved",
    };
    getusers(params, (response) => {
      setUserList(response?.data?.userdata);
      setIsLoading(false);
    });
  };

  const handleSubmit = (data, setSubmitting) => {
    const params = {
      userid: user_id,
      sponsorid: data.userid.id,
    };
    updatesponsor(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
          setUpdateSponsor(true);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong. Please try again leter",
            variant: "error",
          });
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    getSingleUserFunction();
    userslist();
  }, [updateSponsor]);

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={"Update Sponsor"} noWrapper>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {isLoading ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%",
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
            <List disablePadding sx={{ mb: 2 }}>
              <Formik
                initialValues={{
                  username: singleUser?.username,
                  current_sponsor: singleUser?.sponsorName,
                  userid: null,
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                  setSubmitting(true);
                  handleSubmit(data, setSubmitting);
                }}
              >
                {({ isSubmitting, values, setFieldValue, errors }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <JumboTextField
                            fullWidth
                            name="username"
                            label="Username"
                            type="text"
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <JumboTextField
                            fullWidth
                            name="current_sponsor"
                            label="Current Sponsor"
                            type="text"
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {userlist && (
                            <Autocomplete
                              sx={{ width: "100%" }}
                              value={values.userid}
                              onChange={(event, newValue) => {
                                setFieldValue("userid", newValue);
                              }}
                              disablePortal
                              id="combo-box-demo"
                              options={userlist}
                              getOptionLabel={(option) => option.username}
                              getOptionSelected={(option, value) =>
                                option.userid === value.userid
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="userid"
                                  label="Select User"
                                  error={Boolean(errors.userid)} // Set error state based on whether there's an error for the userid field
                                  helperText={errors.userid} // Show error message
                                />
                              )}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Box>

                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        {t("pages.title.submit")}
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          )}
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
}

export default ChangeSponsor;
