import React, { useEffect } from "react";
import routes from "./routes";
import useJumboRoutes from "@jumbo/hooks/useJumboRoutes";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { userAllowedRoutes } from "backendServices/ApiCalls";

const AppRoutes = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const appRoutes = useJumboRoutes(routes);
    const { isLoading } = useJumboAuth();

    const GetAllowedRoutes = () => {
        const params = { username: '' }
        // console.log(params)

        userAllowedRoutes(params,
            (response) => {
                if (response?.data?.data[0]?.username !== 'admin') {
                    let a = JSON.parse(response?.data?.data[0]?.allowedroutes);
                    // console.log(a)
                    // console.log("masla kya ha: ",Object.values(a).length)
                    var url = location.pathname;
                    const index = url.indexOf("/", url.indexOf("/") + 1);
                    if (index !== -1) {
                        url = url.slice(0, index);
                    }
                    const urlExistsInTestArr = a.includes(url);
                    if (!urlExistsInTestArr) {
                        if (url === '/') {
                            let z = a.includes('/dashboard');
                            if (z) {
                                navigate(url);
                                return;
                            }
                        }
                        if (url === '/dashboard') {
                            let z = a.includes('/');
                            if (z) {
                                navigate(url);
                                return;
                            }
                        }
                        
                        if(Object.values(a).length < 3){
                            console.log("masla kya ha: ",Object.values(a).length)
                            navigate('/404')
                        }else{
                            const urlString = String(a[2]);
                            navigate(urlString);
                        }
                    }

                }
            },
            (error) => {
                console.log('error')
            }
        );
    }

    useEffect(() => {
        GetAllowedRoutes();
    }, [])


    return (
        <React.Fragment>
            {
                isLoading ? "Loading" : appRoutes
            }
        </React.Fragment>
    );
};
export default AppRoutes;
