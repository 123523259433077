import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  ApprovePayoutUpdateRequestApi,
  GetPayoutDetailsUpdateReport,
  RejectPayoutUpdateRequestApi,
} from "backendServices/ApiCalls";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Modal, TextField, Typography, MenuItem,
  FormControl,
  InputLabel,
  Select
} from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import RecommendIcon from "@mui/icons-material/Recommend";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div";

const Payoutdetailpending = () => {
  const { t } = useTranslation();
  const [payoutDetailReport, setPayOutDetailReport] = useState([]);
  const [rowDataDetail, setRowDataDetail] = useState(null);
  const [oldDetailOpen, setOldDetailOpen] = useState(false);
  const [newDetailOpen, setNewDetailOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState(false);
  const [reason, setReason] = useState('');
  const [rejectok, setRejectok] = useState(false);
  const [reasonData, setReasonData] = useState([]);

  const [selectedReason, setSelectedReason] = useState('');
  const [customReason, setCustomReason] = useState('');

  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
    setCustomReason('');
  };

  const handleCustomReasonChange = (event) => {
    setCustomReason(event.target.value);
    setSelectedReason('');
  };


  const handleChange = (event) => {
    setReason(event.target.value);
  };

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const GetPayoutDetailsReport = () => {
    GetPayoutDetailsUpdateReport(
      (response) => {
        setReasonData(response?.data?.reasonData)
        const pendingData = response?.data?.data.filter(
          (data) => data.status !== "Approved" && data.status !== "Rejected"
        );
        const data = pendingData?.map((row, index) => ({
          ...row,
          index: index + 1,
        }));
        setPayOutDetailReport(data);
      },
      (error) => { }
    );
  };

  useEffect(() => {
    GetPayoutDetailsReport();
  }, [rejectok]);

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 150,
      editable: true,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 150,
      editable: true,
      groupable: false,
    },
    {
      field: "username",
      headerName: "User Name",
      width: 150,
      editable: true,
      groupable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "oldpayoutdetail",
      headerName: "Old Payout Detail",
      width: 150,
      editable: true,
      groupable: false,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              handleOldDetail(params.row.id);
            }}
          >
            Payout Detail
          </Button>
        </>
      ),
    },
    {
      field: "newpayoutdetail",
      headerName: "New Payout Detail",
      width: 150,
      editable: true,
      groupable: false,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              handleNewDetail(params.row.id);
            }}
          >
            Payout Detail
          </Button>
        </>
      ),
    },
    {
      field: "dat",
      headerName: "Date",
      width: 200,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      editable: true,
      groupable: false,
      valueGetter: (params) => {
        const { value } = params;
        return value ? value : "N/A";
      },
    },
    {
      field: "approved",
      headerName: "Approved",
      width: 100,
      renderCell: (params) => (
        <RecommendIcon
          onClick={() => handleApproved(params.row.id)}
          style={{ cursor: "pointer", color: "green" }}
        />
      ),
    },
    {
      field: "rejected",
      headerName: "Rejected",
      width: 100,
      renderCell: (params) => (
        <ThumbDownAltIcon
          onClick={() => handleClickOpen(params.row.id)}
          style={{ cursor: "pointer", color: "red" }}
        />
      ),
    },
  ];

  const handleClickOpen = (id) => {
    const rowToEdit = payoutDetailReport.find((row) => row.id === id);
    setRowData(rowToEdit);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOldDetail = (id) => {
    const rowToEdit = payoutDetailReport.find((row) => row.id === id);
    setRowDataDetail(rowToEdit);
    setOldDetailOpen(true);
  };

  const handleNewDetail = (id) => {
    const rowToEdit = payoutDetailReport.find((row) => row.id === id);
    setRowDataDetail(rowToEdit);
    setNewDetailOpen(true);
  };

  const handleApproved = (id) => {
    let params = { id: id };
    ApprovePayoutUpdateRequestApi(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setPayOutDetailReport((data) => data.filter((row) => row.id !== id));
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => { }
    );
  };
  const handleRejected = (id) => {
    let params = {
      id: rowData.id,
      reason: selectedReason || customReason
    };

    console.log('params', params);

    if (params.reason === '') {
      setalertData({
        show: true,
        message: 'Rejected reason is required',
        variant: "error",
      });
    }

    else {
      RejectPayoutUpdateRequestApi(
        params,
        (response) => {
          if (response?.data?.status === "error") {
            setOpen(false);
            setReason('');
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "error",
            });
          } else if (response?.data?.status === "success") {
            setOpen(false);
            setReason('');
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "success",
            });
            setRejectok(true)
            setPayOutDetailReport((data) => data.filter((row) => row.id !== id));
          } else {
            setOpen(false);
            setReason('');
            setalertData({
              show: true,
              message: "Something went wrong please try again later",
              variant: "error",
            });
          }
        },
        (error) => { }
      );
    }

  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const handleOldClose = () => setOldDetailOpen(false);
  const handleNewClose = () => setNewDetailOpen(false);

  const walletAddress = rowDataDetail?.walletaddress || "N/A";
  const bankaccounttitle = rowDataDetail?.bankaccounttitle || "N/A";
  const bankaccountiban = rowDataDetail?.bankaccountiban || "N/A";
  const bankaccountbic = rowDataDetail?.bankaccountbic || "N/A";
  const bankaccountaddress = rowDataDetail?.bankaccountaddress || "N/A";
  const bankaccountcity = rowDataDetail?.bankaccountcity || "N/A";
  const bankaccountzipcode = rowDataDetail?.bankaccountzipcode || "N/A";
  const bankaccountcountry = rowDataDetail?.bankaccountcountry || "N/A";
  const outsidebankaccounttitle =
    rowDataDetail?.outsidebankaccounttitle || "N/A";
  const outsidebankaccountswiftcode =
    rowDataDetail?.outsidebankaccountswiftcode || "N/A";
  const outsidebankaccountnumber =
    rowDataDetail?.outsidebankaccountnumber || "N/A";
  const outsidebankaccountrouting =
    rowDataDetail?.outsidebankaccountrouting || "N/A";
  const outsidebankaccountcurrency =
    rowDataDetail?.outsidebankaccountcurrency || "N/A";
  const outsidebankaccountaddress =
    rowDataDetail?.outsidebankaccountaddress || "N/A";
  const outsidebankaccountcity = rowDataDetail?.outsidebankaccountcity || "N/A";
  const outsidebankaccountzipcode =
    rowDataDetail?.outsidebankaccountzipcode || "N/A";
  const outsidepayoutcountry = rowDataDetail?.outsidepayoutcountry || "N/A";

  const wallet_address = rowDataDetail?.wallet_address || "N/A";
  const bank_account_title = rowDataDetail?.bank_account_title || "N/A";
  const bank_account_iban = rowDataDetail?.bank_account_iban || "N/A";
  const bank_account_bic = rowDataDetail?.bank_account_bic || "N/A";
  const bank_account_address = rowDataDetail?.bank_account_address || "N/A";
  const bank_account_city = rowDataDetail?.bank_account_city || "N/A";
  const bank_account_zipcode = rowDataDetail?.bank_account_zipcode || "N/A";
  const bank_account_country = rowDataDetail?.bank_account_country || "N/A";
  const outside_bank_account_title =
    rowDataDetail?.outside_bank_account_title || "N/A";
  const outside_bank_account_swift_code =
    rowDataDetail?.outside_bank_account_swift_code || "N/A";
  const outside_bank_account_number =
    rowDataDetail?.outside_bank_account_number || "N/A";
  const outside_bank_account_routing =
    rowDataDetail?.outside_bank_account_routing || "N/A";
  const outside_bank_account_currency =
    rowDataDetail?.outside_bank_account_currency || "N/A";
  const outside_bank_account_address =
    rowDataDetail?.outside_bank_account_address || "N/A";
  const outside_bank_account_city =
    rowDataDetail?.outside_bank_account_city || "N/A";
  const outside_bank_account_zipcode =
    rowDataDetail?.outside_bank_account_zip_code || "N/A";
  const outside_payout_country = rowDataDetail?.outside_payout_country || "N/A";

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={t("pages.title.payoutpending")}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {/* {isLoading ? (
              <Div
              sx={{
                mt:"20%",
                ml:"45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ):( */}

          <Box sx={{ height: 500, width: 1 }}>
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={payoutDetailReport}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              // slots={{
              //     toolbar: (props) => (
              //         <GridToolbar
              //             {...props}
              //             sx={{
              //                 backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#fff',
              //                 '& .MuiIconButton-root': {
              //                     color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              //                 },
              //             }}
              //         />
              //     ),
              // }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}
        </JumboDemoCard>
      </Grid>
      <Modal
        open={oldDetailOpen}
        onClose={handleOldClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Div sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h2"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Old Payout Detail
          </Typography>
          <Typography id="modal-modal-description" variant="h5" sx={{ mt: 2 }}>
            Crypto USDT
          </Typography>
          <Typography variant="body">
            Wallet Address : {walletAddress}
          </Typography>
          <Divider />
          <Typography id="modal-modal-description" variant="h5" sx={{ mt: 2 }}>
            Bank Detail (Europe)
          </Typography>
          <Typography>Bank Account Title : {bankaccounttitle}</Typography>
          <Typography>Bank Account IBAN : {bankaccountiban}</Typography>
          <Typography>Bank Account BIC : {bankaccountbic}</Typography>
          <Typography>Bank Account Address : {bankaccountaddress}</Typography>
          <Typography>Bank Account City : {bankaccountcity}</Typography>
          <Typography>Bank Account ZipCode : {bankaccountzipcode}</Typography>
          <Typography>Bank Account Country : {bankaccountcountry}</Typography>
          <Divider />
          <Typography id="modal-modal-description" variant="h5" sx={{ mt: 2 }}>
            Bank Detail (Outside Europe)
          </Typography>
          <Typography>
            Bank Account Title : {outsidebankaccounttitle}
          </Typography>
          <Typography>
            Bank Account Swift Code : {outsidebankaccountswiftcode}
          </Typography>
          <Typography>
            Bank Account Number : {outsidebankaccountnumber}
          </Typography>
          <Typography>
            Bank Account Routing : {outsidebankaccountrouting}
          </Typography>
          <Typography>
            Bank Account Currency : {outsidebankaccountcurrency}
          </Typography>
          <Typography>
            Bank Account Address : {outsidebankaccountaddress}
          </Typography>
          <Typography>Bank Account City : {outsidebankaccountcity}</Typography>
          <Typography>
            Bank Account Zip Code : {outsidebankaccountzipcode}
          </Typography>
          <Typography>Bank Account Country : {outsidepayoutcountry}</Typography>
        </Div>
      </Modal>

      <Modal
        open={newDetailOpen}
        onClose={handleNewClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Div sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h2"
            component="h2"
            style={{ textAlign: "center" }}
          >
            New Payout Detail
          </Typography>
          <Typography id="modal-modal-description" variant="h5" sx={{ mt: 2 }}>
            Crypto USDT
          </Typography>
          <Typography variant="body">
            Wallet Address : {wallet_address}
          </Typography>
          <Divider />
          <Typography id="modal-modal-description" variant="h5" sx={{ mt: 2 }}>
            Bank Detail (Europe)
          </Typography>
          <Typography>Bank Account Title : {bank_account_title}</Typography>
          <Typography>Bank Account IBAN : {bank_account_iban}</Typography>
          <Typography>Bank Account BIC : {bank_account_bic}</Typography>
          <Typography>Bank Account Address : {bank_account_address}</Typography>
          <Typography>Bank Account City : {bank_account_city}</Typography>
          <Typography>Bank Account ZipCode : {bank_account_zipcode}</Typography>
          <Typography>Bank Account Country : {bank_account_country}</Typography>
          <Divider />
          <Typography id="modal-modal-description" variant="h5" sx={{ mt: 2 }}>
            Bank Detail (Outside Europe)
          </Typography>
          <Typography>
            Bank Account Title : {outside_bank_account_title}
          </Typography>
          <Typography>
            Bank Account Swift Code : {outside_bank_account_swift_code}
          </Typography>
          <Typography>
            Bank Account Number : {outside_bank_account_number}
          </Typography>
          <Typography>
            Bank Account Routing : {outside_bank_account_routing}
          </Typography>
          <Typography>
            Bank Account Currency : {outside_bank_account_currency}
          </Typography>
          <Typography>
            Bank Account Address : {outside_bank_account_address}
          </Typography>
          <Typography>
            Bank Account City : {outside_bank_account_city}
          </Typography>
          <Typography>
            Bank Account Zip Code : {outside_bank_account_zipcode}
          </Typography>
          <Typography>
            Bank Account Country : {outside_payout_country}
          </Typography>
        </Div>
      </Modal>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Rejected Reason"}
        </DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" style={{ marginTop: 5, width: 400 }}>
            <InputLabel id="reason-select-label">Predefined Reason</InputLabel>
            <Select
              labelId="reason-select-label"
              id="reason-select"
              value={selectedReason}
              onChange={handleReasonChange}
              label="Predefined Reason"
            >
              {reasonData?.map((item, index) => (
                <MenuItem key={index} value={item?.reason}>
                  {item.reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="custom-reason"
            label="Custom Reason"
            variant="outlined"
            style={{ marginTop: 5, width: 400 }}
            value={customReason}
            onChange={handleCustomReasonChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={handleRejected}>
            Submit
          </Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Payoutdetailpending;