import React, { useEffect, useState } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  List,
  MenuItem,
  Select,
} from "@mui/material";
import {
  updateconnection,
} from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  username: yup.mixed().required("Username is required"),
});

function ManageConnection() {
	const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const location = useLocation();
  const [selectConnection, setSelectConnection] = useState("");

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const username = location.state?.username || '';
  const user_id = location.state?.user_id || "";
  const connection = location.state?.connection || "";

  useEffect(() => {
    const savedConnection = localStorage.getItem("selectedConnection");
    if (savedConnection) {
      setSelectConnection(savedConnection);
    } else {
      setSelectConnection(connection); // Fallback to the value from location.state
    }
  }, [connection]);
  

  const handleSubmit = (data, setSubmitting, resetForm) => {
    let params = {
      userId: user_id,
      connection: selectConnection,
    };
  
    updateconnection(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSelectConnection(params.connection); // Update state with sent data
          localStorage.setItem("selectedConnection", params.connection); // Save to localStorage
          resetForm();
          setSubmitting(false);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong. Please try again later",
            variant: "error",
          });
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={t("pages.title.manageconnection")} noWrapper>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {isLoading || isLoading1 ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%",
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
            <List disablePadding sx={{ mb: 2 }}>
              <Formik
                validateOnChange={true}
                initialValues={{
                  username: username,
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  handleSubmit(data, setSubmitting, resetForm);
                }}
              >
                {({ isSubmitting, setFieldValue }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="username"
                        label="User Name"
                        type="text"
                        InputProps={{
                          readOnly: true,
                        }}
                      ></JumboTextField>
                    </Div>
                    <Box sx={{ mt: 3, mb: 3, pl: 2, pr: 2 }}>
                      <FormControl sx={{ minWidth: '100%' }}>
                        <InputLabel id="demo-select-small">Select Connection</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={selectConnection}
                          label="Select Connection"
                          name="connection"
                          onChange={(event) => {
                            const value = event.target.value;
                            setSelectConnection(value);
                            setFieldValue("connection", value);
                          }}
                        >
                          <MenuItem value={'1'}>1</MenuItem>
                          <MenuItem value={'2'}>2</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        {t("pages.title.submit")}
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          )}
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
}

export default ManageConnection;