import React, { useEffect, useRef, useState } from 'react';
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import NotificationsDropdown from "../../../../shared/NotificationsDropdown";
import MessagesDropdown from "../../../../shared/MessagesDropdown";
import {CircularProgress, FormControlLabel, IconButton, Switch, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Logo from "../../../../shared/Logo";
import {SIDEBAR_STYLES, SIDEBAR_VARIANTS} from "@jumbo/utils/constants";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {mainTheme as mainThemeDark} from "../../../../themes/main/dark";
import {headerTheme as headerThemeDark} from "../../../../themes/header/dark";
import {footerTheme as footerThemeDark} from "../../../../themes/footer/dark";
import {sidebarTheme as sidebarThemeDark} from "../../../../themes/sidebar/dark";

import {mainTheme as mainThemeDefault} from "../../../../themes/main/default";
import {headerTheme as headerThemeDefault} from "../../../../themes/header/default";
import {footerTheme as footerThemeDefault} from "../../../../themes/footer/default";
import {sidebarTheme as sidebarThemeDefault} from "../../../../themes/sidebar/default";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import useJumboFooterTheme from "@jumbo/hooks/useJumboFooterTheme";
import Cookies from 'js-cookie';
import LocalizationOptions from 'app/pages/LocalizationOptions';



const Header = () => {
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {headerTheme} = useJumboHeaderTheme();
    const [loading, setLoading] = useState(true);
    const {theme, setTheme} = useJumboTheme();
    const {setSidebarTheme} = useJumboSidebarTheme();
    const {setHeaderTheme} = useJumboHeaderTheme();
    const {setFooterTheme} = useJumboFooterTheme();
    const isMountedRef = useRef(false);

    const handleModeChange = React.useCallback(async (event) => {
        const newMode = event.target.checked ? 'dark' : 'light';
        Cookies.set('mode', newMode);
        if(event.target.checked) {
            await setTheme({mode: "dark", ...mainThemeDark});
            setHeaderTheme({...theme, ...headerThemeDark});
            setSidebarTheme({...theme, ...sidebarThemeDark});
            setFooterTheme({...theme, ...footerThemeDark});
            return;
        }
        await setTheme({mode: "light", ...mainThemeDefault});
        setHeaderTheme({...theme, ...headerThemeDefault});
        setSidebarTheme({...theme, ...sidebarThemeDefault});
        setFooterTheme({...theme, ...footerThemeDefault});
    }, []);

    useEffect(() => {
        isMountedRef.current = true;

        const timer = setTimeout(() => {
            if (isMountedRef.current) {
                setLoading(false);
            }
        }, 2000);

        return () => {
            isMountedRef.current = false;
            clearTimeout(timer);
        };
    }, []);


    return (
        <React.Fragment>
            {
                (
                    sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                    || sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY
                    || (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT && !sidebarOptions.open)
                ) &&
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            ml: sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ? -2 : 0,
                            mr: 3,
                        }}
                        onClick={() => setSidebarOptions({open: !sidebarOptions.open})}
                    >
                        {
                            sidebarOptions?.open ? <MenuOpenIcon/> : <MenuIcon/>
                        }
                    </IconButton>
            }
            {
                sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <Logo sx={{mr: 3}} mode={headerTheme.type ?? "light"}/>
            }
           
            <Stack direction="row" alignItems="center" spacing={1.25} sx={{ml: "auto"}}>
            <FormControlLabel 
            control={
                <Switch
                checked={theme?.mode === "dark"}
                onChange={handleModeChange}
                name="header-fixed"
                icon={<Brightness7Icon color='primary' />} // Icon for light mode
                checkedIcon={<Brightness4Icon color='success' />} // Icon for dark mode
                />
            }
            label={
                <Typography
                    sx={{
                        color: theme?.mode === "dark" ? theme.palette.text.primary : theme.palette.text.dark,
                    }}
                >
                    Theme Mode
                </Typography>
            }
            labelPlacement="end"
            />
             
                        
                        {
                    loading === false ? (
                        <LocalizationOptions />

                    ) : (
                        <CircularProgress />

                    )
                }

                    
                {/* <MessagesDropdown/>
                <NotificationsDropdown/> */}
                <AuthUserDropdown/>
            </Stack>
        </React.Fragment>
    );
};

export default Header;
