import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import PortfolioBalance from "app/shared/metrics/PortfolioBalance/PortfolioBalance";
import Dashboardbgcitycard from "app/shared/widgets/CityBgCard/Dashboardbgcitycard";
import DetailsIcon from "@mui/icons-material/Details";
import PersonIcon from "@mui/icons-material/Person";
import Ideas from "app/shared/widgets/Ideas/Ideas";
import EuroIcon from "@mui/icons-material/Euro";
import Documents from "app/shared/widgets/Documents/Documents";
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { DashboardDataApi, userAllowedRoutes } from "backendServices/ApiCalls";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import Div from "@jumbo/shared/Div/Div";
import CardIconText from "@jumbo/shared/CardIconText";
import { useLocation, useNavigate } from "react-router-dom";

const Crypto = () => {
  const [dashboardApiData, setDashboardApiData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { loginUserData } = useContext(CustomProvider);
  const navigate = useNavigate();
  const isMountedRef = useRef(true); // Ref to track component mounted state

  const location = useLocation()

  const GetAllowedRoutes = () => {
    const params = { username: '' }
    // console.log(params)

    userAllowedRoutes(params,
      (response) => {
        if (response?.data?.data[0]?.username !== 'admin') {
          let a = JSON.parse(response?.data?.data[0]?.allowedroutes);
          // console.log(a)
          var url = location.pathname;
          const index = url.indexOf("/", url.indexOf("/") + 1);
          if (index !== -1) {
            url = url.slice(0, index);
          }
          const urlExistsInTestArr = a.includes(url);
          if (!urlExistsInTestArr) {

            if (url === '/') {
              let z = a.includes('/dashboard');
              if (z) {
                navigate(url);
                return;
              }
            }
            if (url === '/dashboard') {
              let z = a.includes('/');
              if (z) {
                navigate(url);
                return;
              }
            }

            if (Object.values(a).length < 3) {
              console.log("masla kya ha: ", Object.values(a).length)
              navigate('/404')
            } else {
              const urlString = String(a[2]);
              navigate(urlString);
            }
          }
        }
      },
      (error) => {
        console.log('error', error)
      }
    );
  }

  useEffect(() => {
    GetAllowedRoutes();
  }, [])


  const GetDashboardData = () => {
    setLoader(true);
    DashboardDataApi(
      (response) => {
        setDashboardApiData(response?.data);
        setLoader(false);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  // const GetDashboardData = async () => {
  //   setLoader(true);
  //   try {
  //     const response = await DashboardDataApi();
  //     if (isMountedRef.current) {
  //       console.log('sghjsagdjhsagdjhsagjadsh')
  //       console.log(response)
  //       setDashboardApiData(response?.data);
  //     }
  //   } catch (error) {
  //     // Handle the error if necessary
  //   } finally {
  //     if (isMountedRef.current) {
  //       setLoader(false);
  //     }
  //   }
  // };

  useEffect(() => {
    isMountedRef.current = true;
    GetDashboardData();

    return () => {
      isMountedRef.current = false; // Component is unmounted
    };
  }, []);

  if (loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  if (
    loginUserData &&
    loginUserData.usertype === "reseller" &&
    !dashboardApiData?.reseller_mini_admin
  ) {
    return (
      <Grid container spacing={3.75} className="parent_element">
        <Grid item xs={6} lg={6} className="element-1">
          <Grid
            container
            spacing={3.75}
            className="element-2"
            style={{ marginBottom: "30px" }}
          >
            <Grid item xs={12} sm={6} lg={6} className="element-3">
              <ObjectCountRevenue
                dashboardApiData={dashboardApiData?.totalUsers}
                title="referralbonus"
                color="primary.main"
                icon={<PersonIcon fontSize="large" />}
                navTo={"/users-list"}
                vertical={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={6} className="element-4">
              <ObjectCountRevenue
                dashboardApiData={dashboardApiData?.totalMiniAdmin}
                title="totalroi"
                color="success.main"
                icon={<GroupAddIcon fontSize="large" />}
                navTo={"/manage-admin"}
                vertical={true}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Documents dashboardApiData={dashboardApiData} />
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Dashboardbgcitycard />
        </Grid>
      </Grid>
    );
  } else if (
    loginUserData &&
    loginUserData.usertype === "admin" &&
    dashboardApiData?.reseller_mini_admin
  ) {
    return (
      <Grid container spacing={3.75} className="parent_element">
        <Grid item xs={6} lg={6} className="element-1">
          <Grid
            container
            spacing={3.75}
            className="element-2"
            style={{ marginBottom: "30px" }}
          >
            <Grid item xs={12} sm={6} lg={6} className="element-3">
              <ObjectCountRevenue
                dashboardApiData={dashboardApiData?.totalUsers}
                title="referralbonus"
                color="primary.main"
                icon={<PersonIcon fontSize="large" />}
                navTo={"/users-list"}
                vertical={true}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Documents dashboardApiData={dashboardApiData} />
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Dashboardbgcitycard />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={3.75}>
        {loginUserData && loginUserData.usertype === "reseller" ? (
          ""
        ) : (
          <Grid item xs={12} sm={12} lg={7}>
            <PortfolioBalance dashboardApiData={dashboardApiData} />
          </Grid>
        )}

        <Grid item xs={12} lg={5}>
          <Grid container spacing={3.75}>
            <Grid item xs={12}>
              <Dashboardbgcitycard />
            </Grid>
            {loginUserData && loginUserData.usertype === "reseller" ? (
              ""
            ) : (
              <Grid item xs={12} md={6}>
                <Ideas dashboardApiData={dashboardApiData} />
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <Documents dashboardApiData={dashboardApiData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ObjectCountRevenue
            dashboardApiData={dashboardApiData?.totalUsers}
            title="referralbonus"
            color="primary.main"
            icon={<PersonIcon fontSize="large" />}
            navTo={"/users-list"}
            vertical={true}
          />
        </Grid>

        {loginUserData && loginUserData.usertype === "reseller" ? (
          ""
        ) : (
          <Grid item xs={12} sm={6} lg={3}>
            <ObjectCountRevenue
              dashboardApiData={dashboardApiData?.pendingKyc}
              title="tdeposit"
              color="secondary.main"
              icon={<DetailsIcon fontSize="large" />}
              navTo={"/kyc-pending"}
              vertical={true}
            />
          </Grid>
        )}

        {loginUserData && loginUserData.usertype === "reseller" ? (
          ""
        ) : (
          <Grid item xs={12} sm={6} lg={3}>
            <ObjectCountRevenue
              dashboardApiData={dashboardApiData?.pendingPayoutDetailsRequest}
              title="tpayout"
              color="success.main"
              icon={<EuroIcon fontSize={"large"} />}
              navTo={"/payout-detail-pending"}
              vertical={true}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6} lg={3}>
          <ObjectCountRevenue
            dashboardApiData={dashboardApiData?.totalMiniAdmin}
            title="totalroi"
            color="success.main"
            icon={<GroupAddIcon fontSize="large" />}
            navTo={"/manage-admin"}
            vertical={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <CardIconText
            sx={{ pb: 2 }}
            icon={<PersonIcon fontSize={"large"} />}
            subTitle={
              <>
                <Div sx={{ mt: 2, fontSize: "20px" }}>
                  <Typography variant={"p"} color={"primary.main"}>
                    1
                  </Typography>
                </Div>
                <Div sx={{ pb: 2, mt: 2 }}>
                  <Typography variant={"p"} color={"text.primary"}>
                    Sales
                  </Typography>
                </Div>
                <Div>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      navigate("/user-sales", { state: { sales: "1" } })
                    }
                  >
                    View
                  </Button>
                </Div>
              </>
            }
            color={"primary.main"}
            disableHoverEffect={true}
            hideArrow={true}
            variant={"outlined"}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <CardIconText
            sx={{ pb: 2 }}
            icon={<PersonIcon fontSize={"large"} />}
            subTitle={
              <>
                <Div sx={{ mt: 2, fontSize: "20px" }}>
                  <Typography variant={"p"} color={"primary.main"}>
                    2
                  </Typography>
                </Div>
                <Div sx={{ pb: 2, mt: 2 }}>
                  <Typography variant={"p"} color={"text.primary"}>
                    Sales
                  </Typography>
                </Div>
                <Div>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      navigate("/user-sales", { state: { sales: "2" } })
                    }
                  >
                    View
                  </Button>
                </Div>
              </>
            }
            color={"primary.main"}
            disableHoverEffect={true}
            hideArrow={true}
            variant={"outlined"}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <CardIconText
            sx={{ pb: 2 }}
            icon={<PersonIcon fontSize={"large"} />}
            subTitle={
              <>
                <Div sx={{ mt: 2, fontSize: "20px" }}>
                  <Typography variant={"p"} color={"primary.main"}>
                    3-4
                  </Typography>
                </Div>
                <Div sx={{ pb: 2, mt: 2 }}>
                  <Typography variant={"p"} color={"text.primary"}>
                    Sales
                  </Typography>
                </Div>
                <Div>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      navigate("/user-sales", { state: { sales: "3" } })
                    }
                  >
                    View
                  </Button>
                </Div>
              </>
            }
            color={"primary.main"}
            disableHoverEffect={true}
            hideArrow={true}
            variant={"outlined"}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <CardIconText
            sx={{ pb: 2 }}
            icon={<PersonIcon fontSize={"large"} />}
            subTitle={
              <>
                <Div sx={{ mt: 2, fontSize: "20px" }}>
                  <Typography variant={"p"} color={"primary.main"}>
                    5-9
                  </Typography>
                </Div>
                <Div sx={{ pb: 2, mt: 2 }}>
                  <Typography variant={"p"} color={"text.primary"}>
                    Sales
                  </Typography>
                </Div>
                <Div>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      navigate("/user-sales", { state: { sales: "5" } })
                    }
                  >
                    View
                  </Button>
                </Div>
              </>
            }
            color={"primary.main"}
            disableHoverEffect={true}
            hideArrow={true}
            variant={"outlined"}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <CardIconText
            sx={{ pb: 2 }}
            icon={<PersonIcon fontSize={"large"} />}
            subTitle={
              <>
                <Div sx={{ mt: 2, fontSize: "20px" }}>
                  <Typography variant={"p"} color={"primary.main"}>
                    10+
                  </Typography>
                </Div>
                <Div sx={{ pb: 2, mt: 2 }}>
                  <Typography variant={"p"} color={"text.primary"}>
                    Sales
                  </Typography>
                </Div>
                <Div>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      navigate("/user-sales", { state: { sales: "10" } })
                    }
                  >
                    View
                  </Button>
                </Div>
              </>
            }
            color={"primary.main"}
            disableHoverEffect={true}
            hideArrow={true}
            variant={"outlined"}
          />
        </Grid>

      </Grid>
    );
  }
};

export default Crypto;
