import React, { useContext, useState } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
// import loadingGif from '../Crypto/rollingGIF.gif';
import jwtAuthAxios from "app/services/auth/jwtAuth";

const ChangeFavIcon = () => {
  const { loginUserData } = useContext(CustomProvider);
  const [isLoading, setIsLoading] = useState(true);

  let myInterval = setInterval(() => {
    if (loginUserData && loginUserData.usertype === "reseller") {
      setIsLoading(false);
      clearInterval(myInterval);
      companyLogo(loginUserData.company)
        .then((response) => {
          let favData = {
            favIcon: response.data.data.fav_icon_val,
            favTitle: loginUserData.company,
          };
          changeTabTitle(favData);
        })
        .catch((error) => {});
    } else if (
      loginUserData.usertype === "admin" &&
      loginUserData.parent_id > 0
    ) {
      clearInterval(myInterval);
      let faviconEle = document.querySelector('link[rel="icon"]');
      faviconEle.href = loginUserData.favIconUrl;
      document.title = "Admin";
    } else {
      clearInterval(myInterval);
      if (loginUserData) {
        companyLogo(loginUserData.company)
          .then((response) => {
            let favData = {
              favIcon: response.data.data.fav_icon_val,
              favTitle: loginUserData.company,
            };
            changeTabTitle(favData);
          })
          .catch((error) => {});
      } else {
        let faviconEle = document.querySelector('link[rel="icon"]');
        faviconEle.href = "/favicon.png";
      }
    }
  }, 1000);

  const companyLogo = async (domain) => {
    try {
      return await jwtAuthAxios.post("/get-company-logo", { domain: domain });
    } catch (e) {
      return e.response;
    }
  };

  const changeTabTitle = (loginUserData) => {
    const favicon = document.querySelector('link[rel="icon"]');

    if (favicon && loginUserData.favIcon) {
      favicon.href = loginUserData.favIcon;
    }

    if (loginUserData.favTitle) {
      var capitalizedCompany =
        loginUserData.favTitle.charAt(0).toUpperCase() +
        loginUserData.favTitle.slice(1);
      document.title = capitalizedCompany + " Admin";
    }
  };

  return <></>;
};

export default ChangeFavIcon;
