import React, { useEffect } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { CircularProgress, Grid, List, Typography } from "@mui/material";
import { useState } from "react";
import { updateUserLimits, userLimit } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const validationSchema = yup.object({
  fb_messages: yup.number().required("Facebook Messages is required"),
  insta_messages: yup.number().required("Instagram Messages is required"),
  ai_credits_new: yup.number().required("AI Credits is required"),
  tags_pipelines: yup.number().required("Tags/Pipelines is required"),

});

const UpdateUserPlanLimits = () => {
  const { t } = useTranslation();
  const [singleUser, setSingleUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [updateRecord, setUpdateRecord] = useState(false);
  const location = useLocation();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const user_id = location?.state?.user_id || "";

  useEffect(() => {
    getLimitData();
  }, [updateRecord]);

  const getLimitData = () => {
    let params = {
      userid: user_id,
    };
    setIsLoading(true);
    userLimit(
      params,
      (response) => {
        setSingleUser(response?.data?.data[0]);
        setIsLoading(false);
      },
      (error) => {}
    );
  };
  useEffect(() => {
    getLimitData();
  }, []);

  const handleSubmit = (data, setSubmitting) => {
    data.userid = user_id;
    updateUserLimits(
      data,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setUpdateRecord(false);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setUpdateRecord(true);
          setSubmitting(false);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong. Please try again leter",
            variant: "error",
          });
        }
      },
      (error) => {}
    );
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={"Update User Plan Limits"} noWrapper>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {isLoading ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%",
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
            <List disablePadding sx={{ mb: 2 }}>
              <Formik
                validateOnChange={true}
                initialValues={{
                  // username: singleUser?.username,
                  fb_messages: singleUser?.fb_messages,
                  insta_messages: singleUser?.insta_messages,
                  ai_credits_new: singleUser?.ai_credits_new,
                  tags_pipelines: singleUser?.tags_pipelines,

               
              
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                  setSubmitting(true);
                  handleSubmit(data, setSubmitting);
                }}
              >
                {({ isSubmitting }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    {/* <Grid container>
                      <Grid item lg={12} md={12} sm={12}>
                        <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                          <JumboTextField
                            fullWidth
                            name="username"
                            label="User Name"
                            type="text"
                            disabled={true}
                          ></JumboTextField>
                        </Div>
                      </Grid>
                    </Grid> */}
                    {/* <Typography component={"h4"} color={"black"} ml={2}>
                      Facebook
                    </Typography> */}
                    <Grid container>
                      <Grid item lg={6} md={6} sm={12}>
                        <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                          <JumboTextField
                            fullWidth
                            name="fb_messages"
                            label="Facebook Messages"
                            type="number"
                          ></JumboTextField>
                        </Div>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12}>
                        <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                          <JumboTextField
                            fullWidth
                            name="insta_messages"
                            label="Instagram Messages"
                            type="number"
                          ></JumboTextField>
                        </Div>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12}>
                        <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                          <JumboTextField
                            fullWidth
                            name="ai_credits_new"
                            label="AI Credits"
                            type="number"
                          ></JumboTextField>
                        </Div>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12}>
                        <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                          <JumboTextField
                            fullWidth
                            name="tags_pipelines"
                            label="Tags/Pipelines"
                            type="number"
                          ></JumboTextField>
                        </Div>
                      </Grid>

                    </Grid>

                  
               

                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        {t("pages.title.submit")}
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          )}
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default UpdateUserPlanLimits;
