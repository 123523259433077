import React, { useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import {
  Grid,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { Formik, Form } from "formik";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { addnews } from "backendServices/ApiCalls";

let validationSchema = yup.object({});
let initialValues = {};

const Addnews = ({ setBecomeAffiliateOpen }) => {
  const [imageFront, setImageFront] = useState(null);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  validationSchema = yup.object({
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
    newsimage: yup.mixed().required("Image is required"),
  });

  initialValues = {
    title: "",
    description: "",
    newsimage: null,
  };

  const handleUpload = (e, field) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result; // Extract base64 encoded string
          if (base64Data) {
            setImageFront(base64Data);
          }
        };
        reader.readAsDataURL(selectedFile); // Read the selected file as data URL
      } else {
        setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
      }
    }
  };

  const handleSubmit = (data, setSubmitting, resetForm) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    if (!imageFront) {
      setalertData({
        show: true,
        message: "Invalid file type. Please select an image file.",
        variant: "error",
      });
      return; // Don't proceed with form submission
    } else {
      formData.append("newsimage", imageFront);
    }

    addnews(
      formData,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setSubmitting(false);
          resetForm();
          setImageFront(null);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => {
        setSubmitting(false);
        setalertData({
          show: true,
          message: "Something went wrong please try again",
          variant: "error",
        });
      }
    );
  };

  return (
    <>
      <Grid
        container
        fullWidth
        sm={12}
        xs={12}
        lg={12}
        alignItems="center"
        justifyContent="center"
      >
        {alertData.show && (
          <SweetAlert alertData={alertData} setalertData={setalertData} />
        )}

        {/* <Grid item sm={12} xs={12} lg={0} md={0}></Grid> */}

        <Grid item sm={12} xs={12} lg={6} md={6}>
          <JumboDemoCard
            wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
          >
            <Formik
              initialValues={initialValues}
              validateOnChange={true}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                handleSubmit(data, setSubmitting, resetForm);
              }}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <Div>
                    <Div>Add News</Div>
                    <Div>
                      <>
                        <label>Title</label>
                        <Div sx={{ mt: 1 }}>
                          <JumboTextField fullWidth name="title" type="text" />
                        </Div>
                        <label>Description</label>
                        <Div sx={{ mt: 1 }}>
                          <JumboTextField
                            fullWidth
                            name="description"
                            type="text"
                            multiline
                            rows={4}
                          />
                        </Div>
                        <Div>
                          <ListItem sx={{ px: 0, pb: 0 }}>
                            <ListItemText
                              primary={
                                <>
                                  <label>Image</label>
                                  <TextField
                                    onChange={(event) => {
                                      handleUpload(event, "idcardFront");
                                      setFieldValue(
                                        "newsimage",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                    required
                                    name="newsimage"
                                    type="file"
                                    margin="normal"
                                    sx={{ marginBottom: "0px", width: "100%" }}
                                    InputProps={{
                                      endAdornment: (
                                        <CameraAltIcon
                                          fontSize={"small"}
                                          color={"success"}
                                        />
                                      ),
                                    }}
                                  />

                                  <br />
                                  {imageFront && (
                                    <img
                                      src={imageFront}
                                      alt="Passport Front Preview"
                                      style={{
                                        maxWidth: "100px",
                                        marginTop: "10px",
                                      }}
                                    />
                                  )}
                                </>
                              }
                            />
                          </ListItem>
                        </Div>
                      </>

                      <Div sx={{ mt: 1 }}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ mb: 3 }}
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Div>
                    </Div>
                  </Div>
                </Form>
              )}
            </Formik>
          </JumboDemoCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Addnews;
