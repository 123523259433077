import React, { useEffect } from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";

//todo: to see how to define prop-types for this component

const style = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
};

const JumboTextField = (props) => {
  const [field, meta, helpers] = useField(props);
  var multiline1 = "";
  multiline1 = props.multiline;
  const errorText = meta.error && meta.touched ? meta.error : "";

  useEffect(() => {
    if (props.defaultValue && props.name === "favcolor") {
      helpers.setValue(props.defaultValue);
    }
  }, []);

  const handleGrayUserName = (event) => {
    const { value } = event.target;

    if (event.target.getAttribute("name") == "userdomain") {
      helpers.setValue(value.replace(/\d+/g, "")); // Set the value using Formik's helpers
      const containsNumbers = /\d/.test(value);
      if (!containsNumbers && props.onChange) {
        props.onChange(value); // Pass value to parent component
      }
    } else {
      helpers.setValue(value); // Set the value using Formik's helpers
    }
  };
  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      multiline1 // Enables multiline behavior
      error={!!errorText}
      onChange={handleGrayUserName}
      sx={style}
    />
  );
};

export default JumboTextField;
