import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  ApprovekycApi,
  RejectkycApi,
  getkycreport,
} from "backendServices/ApiCalls";
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, MenuItem,
  FormControl,
  InputLabel,
  Select
} from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import RecommendIcon from "@mui/icons-material/Recommend";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ImageIcon from "@mui/icons-material/Image";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const KYCPending = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [kycpending, setKYCPending] = useState([]);
  const [stateImageUrl, setImageUrl] = useState("");
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState(false);
  const [reason, setReason] = useState('');
  const [rejectok, setRejectok] = useState(false);
  const [reasonData, setReasonData] = useState([]);

  const [selectedReason, setSelectedReason] = useState('');
  const [customReason, setCustomReason] = useState('');

  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
    setCustomReason('');
  };

  const handleCustomReasonChange = (event) => {
    setCustomReason(event.target.value);
    setSelectedReason('');
  };


  const handleChange = (event) => {
    setReason(event.target.value);
  };

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const Getkycpending = () => {
    getkycreport(
      (response) => {
        setReasonData(response?.data?.reasonData)
        const pendingData = response?.data?.data.filter(
          (data) => data.status !== "Approved" && data.status !== "Rejected"
        );
        const data = pendingData?.map((row, index) => ({
          ...row,
          index: index + 1,
        }));
        setKYCPending(data);
        setImageUrl(response?.data?.imageURL);
        const getdatakyc = response?.data?.data.filter((data) =>
          data.id_back ? "" : "NAN"
        );
      },
      (error) => { }
    );
  };

  useEffect(() => {
    Getkycpending();
  }, [rejectok]);

  const handleImage = (id, type) => {
    const imageData = kycpending?.filter((data) => data?.id === id);
    if (imageData) {
      let imageUrl;
      type === "front"
        ? (imageUrl = stateImageUrl + imageData[0]?.id_front)
        : (imageUrl = stateImageUrl + imageData[0]?.id_back);
      window.open(imageUrl, "_blank");
    }
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
    },
    {
      field: "username",
      headerName: "User Name",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 200,
      editable: true,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "address",
      headerName: "Address",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "id_front",
      headerName: "ID Front",
      width: 200,
      editable: true,
      groupable: false,
      renderCell: (params) => (
        <ImageIcon
          onClick={() => handleImage(params.row.id, "front")}
          style={{ cursor: "pointer", color: "green" }}
        />
      ),
    },
    {
      field: "id_back",
      headerName: "ID Back",
      width: 200,
      editable: true,
      groupable: false,
      renderCell: (params) => (
        <span>
          {params.row.id_back !== "" ? (
            <ImageIcon
              onClick={() => handleImage(params.row.id, "back")}
              style={{ cursor: "pointer", color: "green" }}
            />
          ) : (
            "NaN"
          )}
        </span>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "approved",
      headerName: "Approved",
      width: 100,
      renderCell: (params) => (
        <RecommendIcon
          onClick={() => handleApproved(params.row.id)}
          style={{ cursor: "pointer", color: "green" }}
        />
      ),
    },
    {
      field: "rejected",
      headerName: "Rejected",
      width: 100,
      renderCell: (params) => (
        <ThumbDownAltIcon
          onClick={() => handleClickOpen(params.row.id)}
          style={{ cursor: "pointer", color: "red" }}
        />
      ),
    },
  ];


  const handleClickOpen = (id) => {
    const rowToEdit = kycpending.find((row) => row.id === id);
    setRowData(rowToEdit);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApproved = (id) => {
    let params = { id: id };
    ApprovekycApi(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setKYCPending((data) => data.filter((row) => row.id !== id));
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => { }
    );
  };
  const handleRejected = (id) => {
    let params = {
      id: rowData.id,
      reason: selectedReason || customReason
    };

    if (params.reason === '') {
      setalertData({
        show: true,
        message: 'Rejected reason is required',
        variant: "error",
      });
    }
    else {
      RejectkycApi(
        params,
        (response) => {
          if (response?.data?.status === "error") {
            setOpen(false);
            setReason('');
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "error",
            });
          } else if (response?.data?.status === "success") {
            setOpen(false);
            setReason('');
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "success",
            });
            setRejectok(true)
          } else {
            setOpen(false);
            setReason('');
            setalertData({
              show: true,
              message: "Something went wrong please try again later",
              variant: "error",
            });
          }
        },
        (error) => { }
      );
    }

  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        // color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  return (
    <>
      <CssBaseline />
      <Grid
        container
        fullWidth
        sm={12}
        xs={12}
        p={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item sm={12} xs={12}>
          <JumboDemoCard
            title={t("pages.title.kycpending")}
            wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
          >
            {alertData.show && (
              <SweetAlert alertData={alertData} setalertData={setalertData} />
            )}
            {/* {isLoading ? (
              <Div
              sx={{
                mt:"20%",
                ml:"45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ):( */}

            <Box sx={{ height: 500, width: 1 }}>
              <DataGrid
                initialState={{
                  pagination: { paginationModel: { pageSize: 6 } },
                }}
                rows={kycpending}
                getRowId={(row) => row.id}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                // slots={{
                //     toolbar: (props) => (
                //         <GridToolbar
                //             {...props}
                //             sx={{
                //                 backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#fff',
                //                 '& .MuiIconButton-root': {
                //                     color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                //                 },
                //             }}
                //         />
                //     ),
                // }}
                sx={gridDesign}
                pageSizeOptions={[6, 12, 18, 24, 30]}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
            {/* )} */}
          </JumboDemoCard>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Rejected Reason"}
        </DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" style={{ marginTop: 5, width: 400 }}>
            <InputLabel id="reason-select-label">Predefined Reason</InputLabel>
            <Select
              labelId="reason-select-label"
              id="reason-select"
              value={selectedReason}
              onChange={handleReasonChange}
              label="Predefined Reason"
            >
              {reasonData?.map((item, index) => (
                <MenuItem key={index} value={item?.reason}>
                  {item.reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="custom-reason"
            label="Custom Reason"
            variant="outlined"
            style={{ marginTop: 5, width: 400 }}
            value={customReason}
            onChange={handleCustomReasonChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={handleRejected}>
            Submit
          </Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default KYCPending;