import React, { useContext, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import {
  Checkbox,
  Grid,
  ListItemText,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { createadmin } from "backendServices/ApiCalls";
import { routesForAuthenticatedOnly } from "../../../routes";
import { useTranslation } from "react-i18next";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateAdmin = () => {
  const validationSchema = yup.object({
    username: yup.string().required("User Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    firstname: yup.string().required("First Name is required"),
    lastname: yup.string().required("Last Name is required"),
    password: yup.string().required("Password is required"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    mini_admin_transaction_password: yup
      .string()
      .required("Password is required"),
    mini_admin_transaction_confirm_password: yup
      .string()
      .oneOf(
        [yup.ref("mini_admin_transaction_password"), null],
        "Passwords must match"
      )
      .required("Confirm Password is required"),
    admin_transaction_password: yup
      .string()
      .required("Admin Transaction Password is required"),
    routenames: yup
      .array()
      .min(2, "At least one more role must be selected.")
      .test(
        "check-routenames",
        "At least one more role must be selected.",
        function (value) {
          // Ensure that routenames contain more than just the default roles
          return (
            value &&
            value.length > 2 // Since there are two default roles
          );
        }
      )
      .required("User roles are required"),
  });

  const { t } = useTranslation();
  const filteredRoutes = routesForAuthenticatedOnly.filter(
    (route) => route.path !== "/logout" && route.path !== "/404"
  );
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const { loginUserData } = useContext(CustomProvider);
  // const [routenames, setRoutenames] = useState(["/logout", "/404"]);
  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setRoutenames(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value?.split(",") : value
  //   );
  // };

  const handleCustomChange = (event, setFieldValue, values) => {
    const { value } = event.target;
    let newValues = [...value];
  
    // Handle '/' selection
    if (newValues.includes('/') && !values.routenames.includes('/dashboard')) {
      newValues = Array.from(new Set([...newValues, '/dashboard']));
    } else if (!newValues.includes('/') && values.routenames.includes('/dashboard')) {
      newValues = newValues.filter((val) => val !== '/dashboard');
    }
  
    // Handle '/dashboard' selection
    if (newValues.includes('/dashboard') && !values.routenames.includes('/')) {
      newValues = Array.from(new Set([...newValues, '/']));
    } else if (!newValues.includes('/dashboard') && values.routenames.includes('/')) {
      newValues = newValues.filter((val) => val !== '/');
    }
  
    setFieldValue('routenames', newValues);
  };

  

  const handleSubmit = (data, setSubmitting, resetForm) => {
    console.log('hii')
    setSubmitting(true);
    // if (routenames.length < 1) {
    //   setalertData({
    //     show: true,
    //     message: "User roles are required",
    //     variant: "warning",
    //   });
    //   setSubmitting(false);
    // } else {
    // , '/create-admin', '/manage-admin'

    var routesJSONString = "";
    if (loginUserData?.usertype === "reseller") {
      var resellerAdminRoutes = [
        "/logout",
        "/manage",
        "/users-list",
        "/update-user-password",
        "/update-transaction-password",
        "/update-login-password",
        "/managetransactionpassword",
        "/update-user-balance",
        "/listofAlluser",
        "/updateuserlist",
        "/404",
        "/",
        "/dashboard",
        "/active-users",
        "/inactive-users",
        "/profile",
        "/update-user-personal-info",
      ];

      routesJSONString = JSON.stringify(resellerAdminRoutes);
    } else {
      routesJSONString = JSON.stringify(data.routenames);
    }

    let params = {
      username: data.username,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      password: data.password,
      mini_admin_transaction_password: data.mini_admin_transaction_password,
      admin_transaction_password: data.admin_transaction_password,
      allowedroutes: routesJSONString,
    };

    // return false;
    createadmin(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          // setRoutenames([["/logout", "/404"]]);
          resetForm();
        } else {
          setalertData({
            show: true,
            message: "Something went wrong, please try again later.",
            variant: "error",
          });
          // setRoutenames([["/logout", "/404"]]);
        }
        setSubmitting(false);
      },
      (error) => {
        setSubmitting(false);
      }
    );
    // }
  };

  return (
    <Grid
      container
      spacing={2}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <Grid item sm={7}>
        <JumboDemoCard
          title={t("pages.title.createadmin")}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          <Formik
            initialValues={{
              username: "",
              email: "",
              firstname: "",
              lastname: "",
              password: "",
              confirm_password: "",
              mini_admin_transaction_password: "",
              mini_admin_transaction_confirm_password: "",
              admin_transaction_password: "",
              routenames: ["/logout", "/404"],
              // ... other initial values ...
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
              // setSubmitting(true);
              console.log('hi')
              handleSubmit(data, setSubmitting, resetForm);
            }}
            validateOnChange={true}  // Validate fields on each change
            validateOnBlur={true}    // Validate fields on blur
            validateOnMount={true}   // Validate fields as soon as the form mounts
          >
            {({ errors, touched, isSubmitting, handleChange,setFieldValue, values }) => (
              <Form style={{ textAlign: "left", width: "100%" }} noValidate autoComplete="off">
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="username"
                        label="User Name"
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="email"
                        label="Email"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="firstname"
                        label="First Name"
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="lastname"
                        label="Last Name"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="confirm_password"
                        label="Confirm Password"
                        type="password"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="mini_admin_transaction_password"
                        label="Transaction Password"
                        type="password"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <JumboTextField
                        fullWidth
                        name="mini_admin_transaction_confirm_password"
                        label="Confirm Transaction Password"
                        type="password"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <Grid item xs={12}>
                    <JumboTextField
                      fullWidth
                      name="admin_transaction_password"
                      label="Admin Transaction Password"
                      type="password"
                    />
                  </Grid>
                </Box>

                {/* Role Selection */}
                <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  {loginUserData?.usertype !== "reseller" && (
                    <Grid item xs={12}>
                      <FormControl sx={{ mb: 1, width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          {t("pages.title.selectroles")}
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          fullWidth
                          value={values.routenames}  // Bind value to Formik
                          name="routenames"           // Ensure name matches initialValues
                          onChange={(event) => handleCustomChange(event, setFieldValue, values)}     // Handle change with Formik
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {filteredRoutes.map((routes) => (
                            <MenuItem key={routes.path} value={routes.path}>
                              <Checkbox
                                checked={values.routenames.indexOf(routes.path) > -1}
                              />
                              <ListItemText primary={routes.path} />
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.routenames && touched.routenames && (
                          <Typography
                            variant="body2"
                            color="error"
                            sx={{
                              fontFamily: 'NoirPro, Arial',
                              fontWeight: 400,
                              fontSize: '0.75rem',
                              lineHeight: 1.66,
                              textAlign: 'left',
                              mt: '3px',
                              mr: '14px',
                              mb: 0,
                              ml: '14px',
                            }}
                          >
                            {errors.routenames}
                          </Typography>

                        )}
                      </FormControl>
                    </Grid>
                  )}

                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    {t("pages.title.submit")}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default CreateAdmin;
