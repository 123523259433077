import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { UserBinaryDetails } from "backendServices/ApiCalls";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid } from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
} from "@mui/material";

const BinaryDetails = () => {
  const { t } = useTranslation();
  const [getUsersList, setGetUsersList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [getmonth, setGetMonth] = useState("");

  const GetUsersList = () => {
    setLoader(true);
    let params = {
      month: "09",
      month1: "10",
    };
    UserBinaryDetails(
      params,
      (response) => {
        setGetUsersList(response?.data?.userdata);
        setGetMonth(response?.data?.month);
        setLoader(false);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  const [open, setOpen] = React.useState(false);
  const [month, setMonth] = React.useState("");

  const handleChange = (event) => {
    setMonth(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleSubmit = () => {
    const result = month.split("*");
    setLoader(true);
    let params = {
      month: result[0],
      month1: result[1],
    };
    UserBinaryDetails(
      params,
      (response) => {
        setGetUsersList(response?.data?.userdata);
        setGetMonth(response?.data?.month);
        setLoader(false);
        setOpen(false);
      },
      (error) => {
        setOpen(false);
        setLoader(false);
      }
    );
  };

  useEffect(() => {
    GetUsersList();
  }, []);

  const columns = [
    {
      field: "username",
      headerName: "User Name",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 200,
      editable: true,
    },

    {
      field: "lastname",
      headerName: "Last Name",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
      groupable: false,
    },

    {
      field: "sponsor_name",
      headerName: "Sponsor Name",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "sponsor_email",
      headerName: "Sponsor Email",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "has_distributor_package",
      headerName: "Affiliate",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "mobile",
      headerName: "Mobile ",
      width: 200,
      editable: true,
      groupable: false,
    },

    {
      field: "binary_bonus_sum",
      headerName: "Income",
      width: 200,
    },
    {
      field: "rank_name",
      headerName: "Payout Rank",
      width: 200,
    },
    {
      field: "novafree_rank_name",
      headerName: "NovaFree Rank",
      width: 200,
    },
    {
      field: "left_binary_points",
      headerName: "Left Binary Points",
      width: 200,
    },
    {
      field: "right_binary_points",
      headerName: "RIght Binary Points",
      width: 200,
    },
    {
      field: "team_active_left_members",
      headerName: "Left Team Members",
      width: 200,
    },
    {
      field: "team_active_right_members",
      headerName: "Right Team Members",
      width: 200,
    },
    {
      field: "left_referral_points",
      headerName: "Left Referral Points",
      width: 200,
    },
    {
      field: "right_referral_points",
      headerName: "RIght Referral Points",
      width: 200,
    },
    {
      field: "direct_active_left_members",
      headerName: "Left Active Referral Members",
      width: 200,
    },
    {
      field: "direct_active_right_members",
      headerName: "Right Active Referral Members",
      width: 200,
    },
    
  ];

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
        // color: '#074682',
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  if (loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <>
      <Grid container p={2}>
        <Grid item lg={4} md={4} sm={12}>
          <Button variant="contained" onClick={handleClickOpen}>
            Filter By Month
          </Button>
          <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>Filter By Month</DialogTitle>
            <DialogContent>
              <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel htmlFor="demo-dialog-native">
                    Select Month
                  </InputLabel>
                  <Select
                    native
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        label="Select Month"
                        id="demo-dialog-native"
                      />
                    }
                  >
                    <option aria-label="None" value="" />
                    <option value={"11*12"}>November</option>
                    <option value={"10*11"}>October</option>
                    <option value={"09*10"}>September</option>
                    <option value={"08*09"}>August</option>
                    <option value={"07*08"}>July</option>
                    <option value={"06*07"}>Jun</option>
                    <option value={"05*06"}>May</option>
                  </Select>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSubmit}>Submit</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>

      <Grid
        container
        fullWidth
        sm={12}
        xs={12}
        p={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item sm={12} xs={12}>
          <JumboDemoCard
            title={t("pages.title.userlist") + " of " + getmonth}
            wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
          >
            <Box sx={{ height: 500, width: 1 }}>
              <DataGrid
                initialState={{
                  pagination: { paginationModel: { pageSize: 6 } },
                }}
                rows={getUsersList}
                getRowId={(row) => row.id}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                sx={gridDesign}
                pageSizeOptions={[6, 12, 18, 24, 30]}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
          </JumboDemoCard>
        </Grid>
      </Grid>
    </>
  );
};

export default BinaryDetails;
