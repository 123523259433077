import React, { useContext } from "react";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import CardIconText from "@jumbo/shared/CardIconText";
import { useTranslation } from "react-i18next";
import PersonIcon from "@mui/icons-material/Person";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

const Documents = ({ dashboardApiData }) => {
  const { t } = useTranslation();
  const { loginUserData } = useContext(CustomProvider);

  if (
    loginUserData &&
    loginUserData.usertype !== "reseller" &&
    !dashboardApiData?.reseller_mini_admin
  ) {
    return (
      <CardIconText
        icon={<PersonIcon fontSize="large" />}
        onHoverIcon={<FolderOpenOutlinedIcon fontSize="large" />}
        title={
          dashboardApiData?.users?.normal === 0
            ? "0"
            : dashboardApiData?.users?.normal
        }
        subTitle={t("widgets.subheader.documents")}
        color={"secondary.main"}
      />
    );
  } else {
    return null;
  }
};

export default Documents;
