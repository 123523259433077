import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { GetCurrentMonthPay } from "backendServices/ApiCalls";
import { Grid, Typography, Card, CircularProgress, CardContent } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div"; // Assuming Div is a custom styled div component

const CurrentMonthPay = () => {
  const { t } = useTranslation();
  const [payoutDetailReport, setPayoutDetailReport] = useState([]);
  const [totalAmountUSD, setTotalAmountUSD] = useState(0);
  const [totalAmountEUR, setTotalAmountEUR] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [alertData, setAlertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  // Fetch payout details from the API
  const GetPayoutDetailsReport = (signal) => {
    GetCurrentMonthPay(
      (response) => {
        if (!signal.aborted) {
          const { users, totalAmountUSD, totalAmountEUR } = response?.data?.data;

          const processedData = users.map((row, index) => ({
            ...row,
            index: index + 1,
          }));

          // Set user data and totals into state
          setPayoutDetailReport(processedData);
          setTotalAmountUSD(totalAmountUSD);
          setTotalAmountEUR(totalAmountEUR);
          setIsLoading(false);
        }
      },
      (error) => {
        if (!signal.aborted) {
          setAlertData({
            show: true,
            message: "Failed to fetch data",
            variant: "error",
          });
          setIsLoading(false);
        }
      },
      signal // Pass the abort signal to the API call
    );
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    GetPayoutDetailsReport(signal);

    return () => {
      // Cancel the API request immediately when the component unmounts
      abortController.abort();
      setIsLoading(false); // Stop the loading state immediately
    };
  }, []);

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 150,
      editable: false,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 150,
      editable: false,
    },
    {
      field: "username",
      headerName: "Username",
      width: 150,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: false,
    },
    {
      field: "amountUSD",
      headerName: "Amount USD",
      width: 150,
      editable: false,
      valueFormatter: (params) => params.value.toFixed(2), // Format as 2 decimal places
    },
    {
      field: "amountEUR",
      headerName: "Amount EUR",
      width: 150,
      editable: false,
      valueFormatter: (params) => params.value.toFixed(2), // Format as 2 decimal places
    },
  ];

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },

      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  return (
    <>
      {isLoading ? (
        <Card
          sx={{
            maxWidth: 345,
            margin: "20% auto",
            padding: "20px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CardContent>
            <CircularProgress size={40} sx={{ mb: 2 }} />
            <Typography variant="h5" component="div">
              ⏳ Waiting!
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Resource will be loading soon.
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <Grid container fullWidth sm={12} xs={12} p={2} alignItems="center" justifyContent="center">
          <Grid item sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Card
                sx={{
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  padding: "16px",
                  width: "300px",
                  textAlign: "start",
                }}
              >
                <Typography variant="h6" sx={{ color: "#074682", fontWeight: "bold" }}>
                  {t("Total Amounts")}
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  <span style={{ fontWeight: 600 }}>{t("Total Amount USD")}: </span>
                  {totalAmountUSD.toFixed(2)} USD
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  <span style={{ fontWeight: 600 }}>{t("Total Amount EUR")}: </span>
                  {totalAmountEUR.toFixed(2)} EUR
                </Typography>
              </Card>
            </Box>

            <JumboDemoCard title={t("pages.title.CurrentMonthPay")} wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}>
              {alertData.show && <SweetAlert alertData={alertData} setalertData={setAlertData} />}
              <Box sx={{ height: 500, width: "100%" }}>
                <DataGrid
                  initialState={{
                    pagination: { paginationModel: { pageSize: 6 } },
                  }}
                  rows={payoutDetailReport}
                  getRowId={(row) => row.userId} // Use `userId` as the unique identifier
                  columns={columns}
                  slots={{ toolbar: GridToolbar }}
                  sx={gridDesign}
                  pageSizeOptions={[6, 12, 18, 24, 30]}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                />
              </Box>
            </JumboDemoCard>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CurrentMonthPay;
