import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  postRequest,
  singleUserApi,
  trialusers,
} from "backendServices/ApiCalls";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
} from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import Modals from "./Modals";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

function TrialUsers() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rankReport, setRankReport] = useState([]);
  const theme = useTheme();
  const [dialoguserdata, setDialogUserData] = useState("");
  const [open, setOpen] = React.useState(false);
  const { loginUserData } = useContext(CustomProvider);
  const [updatepayoutinformationopen, setUpdatePayoutInformationOpen] =
    useState(false);
  const [currentPayoutDetails, setCurrentPayoutDetails] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const RankReport = () => {
    trialusers(
      (response) => {
        const processedData = response?.data?.data.map((row, index) => ({
          ...row,
          index: index + 1,
        }));
        setRankReport(processedData);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    RankReport();
  }, []);
  const rowsWithIndex = rankReport?.map((row, index) => ({
    ...row,
    id: index + 1,
  }));

  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
      // valueGetter: (params) => params.row.id,
    },
    {
      field: "username",
      headerName: "User Name",
      width: 200,
      editable: true,
      groupable: false,
      renderCell: (params) => {
        let value = params?.value;
        let originalString = value;
        if (originalString?.includes("‰")) {
          const replacedString = originalString?.replace(/‰/g, "¨");
          const decodedString = decodeURIComponent(escape(replacedString));
          value = decodedString;
        }
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDialogUserData(params);
              setOpen(true);
            }}
          >
            {value?.includes("é") ||
            value?.includes("è") ||
            value?.includes("É") ||
            value?.includes("ë") ||
            value?.includes("ç") ||
            value?.includes("î") ||
            value?.includes(" ") ||
            value?.includes("ü")
              ? value
              : decodeURIComponent(escape(value))}
          </div>
        );
      },
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "trial_start",
      headerName: "Trial Start",
      width: 200,
      editable: true,
      groupable: false,
      valueFormatter: (params) => convertTimestampToDate(params.value),
    },
    {
      field: "trial_end",
      headerName: "Trial End",
      width: 200,
      editable: true,
      groupable: false,
      valueFormatter: (params) => convertTimestampToDate(params.value),
    },
  ];
  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const DialogloginClick = () => {
    // let username = params?.row?.username;
    let data = {
      userId: dialoguserdata?.row?.id,
    };

    postRequest(
      "/createusersession",
      data,
      (response) => {
        if (response?.data?.status === "success") {
          window.open(response?.data?.accessurl, "_blank");
        }
      },
      (error) => {}
    );
  };

  const handleOpen = (id) => {
    const rowToEdit = rankReport.find((row) => row.id === id);
    let params = {
      userid: rowToEdit.id,
    };
    singleUserApi(params, (response) => {
      if (
        response?.data?.data[0].bank_account_title === null &&
        response?.data?.data[0].wallet_address === null &&
        response?.data?.data[0].outside_bank_account_title === null
      ) {
        setUpdatePayoutInformationOpen(true);
      } else {
        setCurrentPayoutDetails(true);
      }
    });
    setRowData(rowToEdit);
    setOpen(false);
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={t("pages.title.trialusers")}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}

          <Box sx={{ height: 500, width: 1 }}>
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={rankReport}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              // slots={{
              //     toolbar: (props) => (
              //         <GridToolbar
              //             {...props}
              //             sx={{
              //                 backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#fff',
              //                 '& .MuiIconButton-root': {
              //                     color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              //                 },
              //             }}
              //         />
              //     ),
              // }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}
        </JumboDemoCard>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpen(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            Manage Account Of: {dialoguserdata?.row?.username}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {t("pages.title.dialogcontent")}
            </DialogContentText>
          </DialogContent>
          <Grid
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingBottom: 1,
              textAlign: "center",
            }}
            spacing={1}
            container
          >
            <Grid item sm={6} md={6} lg={6}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                onClick={DialogloginClick}
              >
                {t("pages.title.login")}
              </Button>
            </Grid>

            {loginUserData.usertype !== "reseller" &&
              !loginUserData.parent_id && (
                <Grid item sm={12} md={6} lg={6}>
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    onClick={() =>
                      navigate("/update-user-balance", {
                        state: {
                          username: dialoguserdata?.row?.username,
                          id: dialoguserdata?.row?.id,
                          current_balance: dialoguserdata?.row?.current_balance,
                        },
                      })
                    }
                  >
                    Update Balance
                  </Button>
                </Grid>
              )}

            {loginUserData.usertype !== "reseller" &&
              !loginUserData.parent_id && (
                <Grid item sm={6} md={6} lg={6}>
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    onClick={() =>
                      navigate("/manage-user-unilevel", {
                        state: { user_id: dialoguserdata?.row?.id },
                      })
                    }
                  >
                    Level Bonus
                  </Button>
                </Grid>
              )}

            <Grid item sm={12} md={6} lg={6}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() =>
                  navigate("/manage-column", {
                    state: {
                      id: dialoguserdata?.row?.id,
                      birthday_status: dialoguserdata?.row?.birthday_status,
                      connect_status: dialoguserdata?.row?.connect_status,
                      crm_status: dialoguserdata?.row?.crm_status,
                      unfollow_status: dialoguserdata?.row?.unfollow_status,
                    },
                  })
                }
              >
                {t("pages.title.manage_columns")}
              </Button>
            </Grid>

            {/* <Grid item sm={6} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={BlockUserdata}
                >
                  {dialoguserdata?.row?.login_status === "Block"
                    ? "Unblock"
                    : "Block"}
                </Button>
              </Grid> */}
            {/* <Grid item sm={6} md={4} lg={4}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/binary-tree", {
                      state: { randomcode: dialoguserdata?.row?.randomcode },
                    })
                  }
                >
                  {t("pages.title.view_Tree")}
                </Button>
              </Grid> */}
          </Grid>

          <Grid
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingBottom: 1,
              textAlign: "center",
            }}
            spacing={1}
            container
          >
            {/* <Grid item sm={12} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/affiliate-list-details", {
                      state: {
                        username: dialoguserdata?.row?.username,
                        id: dialoguserdata?.row?.id,
                      },
                    })
                  }
                >
                  {t("pages.title.AffiliateListDetails")}
                </Button>
              </Grid> */}
            {/* <Grid item sm={12} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/add-deduct-binarypoints", {
                      state: {
                        username: dialoguserdata?.row?.username,
                        id: dialoguserdata?.row?.id,
                      },
                    })
                  }
                >
                  Manage Points
                </Button>
              </Grid> */}
          </Grid>

          <Grid
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingBottom: 1,
              textAlign: "center",
            }}
            spacing={1}
            container
          >
            {/* <Grid item sm={12} md={6} lg={6}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/monthly-report", {
                      state: { id: dialoguserdata?.row?.id },
                    })
                  }
                >
                  {t("pages.title.monthly_report")}
                </Button>
              </Grid> */}

            {/*<Grid item sm={6} md={6} lg={6}>*/}
            {/*  <Button*/}
            {/*    sx={{ width: "100%" }}*/}
            {/*    variant="contained"*/}
            {/*    onClick={() =>*/}
            {/*      navigate("/manage-user-unilevel", {*/}
            {/*        state: { user_id: dialoguserdata?.row?.id },*/}
            {/*      })*/}
            {/*    }*/}
            {/*  >*/}
            {/*    Level Bonus*/}
            {/*  </Button>*/}
            {/*</Grid>*/}

            {/*<Grid item sm={12} md={6} lg={6}>*/}
            {/*  <Button*/}
            {/*    sx={{ width: "100%" }}*/}
            {/*    variant="contained"*/}
            {/*    onClick={() =>*/}
            {/*      navigate("/manage-column", {*/}
            {/*        state: {*/}
            {/*          id: dialoguserdata?.row?.id,*/}
            {/*          birthday_status: dialoguserdata?.row?.birthday_status,*/}
            {/*          connect_status: dialoguserdata?.row?.connect_status,*/}
            {/*          crm_status: dialoguserdata?.row?.crm_status,*/}
            {/*          unfollow_status: dialoguserdata?.row?.unfollow_status,*/}
            {/*        },*/}
            {/*      })*/}
            {/*    }*/}
            {/*  >*/}
            {/*    {t("pages.title.manage_columns")}*/}
            {/*  </Button>*/}
            {/*</Grid>*/}
          </Grid>

          <Grid
            spacing={1}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingBottom: 1,
              textAlign: "center",
            }}
            container
          >
            <Grid item sm={12} md={6} lg={6}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() =>
                  navigate("/update-user-password", {
                    state: {
                      username: dialoguserdata?.row?.username,
                      id: dialoguserdata?.row?.id,
                    },
                  })
                }
              >
                Update Password
              </Button>
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() =>
                  navigate("/update-user-personal-info", {
                    state: { user_id: dialoguserdata?.row?.id },
                  })
                }
              >
                Personal Info
              </Button>
            </Grid>
          </Grid>

          <Grid
            spacing={1}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingBottom: 1,
              textAlign: "center",
            }}
            container
          >
            <Grid item sm={12} md={6} lg={6}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() =>
                  navigate("/update-user-plan-limits", {
                    state: { user_id: dialoguserdata?.row?.id },
                  })
                }
              >
                Plan Limits
              </Button>
            </Grid>

            {loginUserData.usertype !== "reseller" &&
              !loginUserData.parent_id && (
                <Grid item sm={12} md={6} lg={6}>
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    onClick={() =>
                      navigate("/change-sponsor", {
                        state: { user_id: dialoguserdata?.row?.id },
                      })
                    }
                  >
                    Change Sponsor
                  </Button>
                </Grid>
              )}
          </Grid>

          <Grid
            spacing={1}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingBottom: 1,
              textAlign: "center",
            }}
            container
          >
            <Grid item sm={12} md={12} lg={12}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() =>
                  navigate("/unlock-user", {
                    state: { user_id: dialoguserdata?.row?.id },
                  })
                }
              >
                Unlock User
              </Button>
            </Grid>

            <Grid item sm={12} md={12} lg={12}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  handleOpen(dialoguserdata?.row?.id);
                }}
              >
                Update Payout Detail
              </Button>
            </Grid>

            {/* <Grid item sm={12} md={12} lg={12}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() =>
                    navigate("/change-sponsor", {
                      state: { user_id: dialoguserdata?.row?.id },
                    })
                  }
                >
                  Change Sponsor
                </Button>
              </Grid> */}
          </Grid>

          {/* <Grid sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 1, textAlign: 'center' }} spacing={1} container>
                            
                        </Grid> */}

          <DialogActions>
            {/* <Button onClick={() => setOpen(false)}>Disagree</Button> */}
            <Button
              onClick={() => {
                setOpen(false);
                RankReport();
              }}
            >
              {t("pages.title.close")}
            </Button>
          </DialogActions>
        </Dialog>

        <Modals
          open={openModal}
          setOpen={setOpenModal}
          rowData={rowData}
          currentPayoutDetails={currentPayoutDetails}
          setCurrentPayoutDetails={setCurrentPayoutDetails}
          updatepayoutinformationopen={updatepayoutinformationopen}
          setUpdatePayoutInformationOpen={setUpdatePayoutInformationOpen}
        />
      </Grid>
    </Grid>
  );
}

export default TrialUsers;
