import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { getpool, updatepool } from "backendServices/ApiCalls";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, List, Modal, Typography } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object({
  pool_name: yup.string().required("Pool is required"),
  percentage: yup.number().required("Percentage is required"),
  amount: yup.number().required("Amount is required"),
  number_of_user: yup.number().required("Number of Users is required"),
});

function ManagePool() {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const [poolData, setPoolData] = useState([]);
  const [rowData, setRowData] = useState(null);

  const GetUnilevels = () => {
    getpool(
      (response) => {
        if (response?.data?.status === "success") {
          const processedData = response?.data?.data?.map((row, index) => ({
            ...row,
            index: index + 1,
          }));
          setPoolData(processedData);
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    GetUnilevels();
  }, []);

  const generateRowId = () => {
    // Generate a random string or use any other logic to create a unique ID
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };

  const rowsWithIndex = poolData?.map((row) => ({ ...row, id: row.id }));

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 100,
    },

    {
      field: "pool_name",
      headerName: "Pool Name",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "percentage",
      headerName: "Percentage",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "number_of_users",
      headerName: "Number of Users",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "update",
      headerName: "Update",
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => (
        <>
          <EditIcon
            color="warning"
            style={{ cursor: "pointer" }}
            onClick={() => handleOpen(params.row.id)}
          />
        </>
      ),
    },
  ];

  const handleOpen = (id) => {
    const rowToEdit = poolData.find((row) => row.id === id);
    setRowData(rowToEdit);
    setOpen(true);
  };

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const handleUpdate = (data, setSubmitting, resetForm) => {
    const additionalData = {
      id: rowData.id,
    };

    const newData = { ...data, ...additionalData };

    updatepool(
      newData,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setTimeout(() => {
            setSubmitting(false);
          }, 2000);
          GetUnilevels();
        }
      },
      (error) => {}
    );
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const handleClose = () => setOpen(false);

  return (
    <JumboDemoCard
      title={"Manage Pools"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Div sx={style}>
            <Typography id="modal-modal-title" variant="h3" component="h2">
              Update Pool
            </Typography>
            <List disablePadding sx={{ mb: 2, mt: 5 }}>
              {/* Display the image if it's available in rowData */}
              <Formik
                validateOnChange={true}
                initialValues={{
                  pool_name: rowData?.pool_name || "",
                  percentage: rowData?.percentage || "0",
                  amount: rowData?.amount || "0",
                  number_of_user: rowData?.number_of_users || "0",
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  handleUpdate(data, setSubmitting, resetForm);
                  handleClose();
                }}
              >
                {({ isSubmitting, setFieldValue }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                          <JumboTextField
                            fullWidth
                            name="pool_name"
                            label="Pool Name"
                            type="text"
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </Div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                          <JumboTextField
                            fullWidth
                            name="percentage"
                            label="Percentage (%)"
                            type="number"
                          />
                        </Div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                          <JumboTextField
                            fullWidth
                            name="amount"
                            label="Pool amount"
                            type="number"
                          />
                        </Div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                          <JumboTextField
                            fullWidth
                            name="number_of_user"
                            label="Number of Users"
                            type="number"
                          />
                        </Div>
                      </Grid>
                    </Grid>
                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          </Div>
        </Modal>
      </Div>

      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rowsWithIndex}
          getRowId={(row) => generateRowId()}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
}

export default ManagePool;
